import { useParams } from 'react-router-dom'

import PageContainer from 'components/atoms/page-container'
import RelationsEditForm from 'components/organisms/relations/relations-edit-form'
import useGetOneRelationVersion from 'hooks/queries/relation/useGetOneRelationVersion'

const RelationsHistoricShow = (): JSX.Element => {
  const { id, version } = useParams()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { isLoading, response } = useGetOneRelationVersion({ id, version })

  return (
    <PageContainer>
      <RelationsEditForm isLoading={isLoading} response={response} relationId={id} disabled historic />
    </PageContainer>
  )
}

export default RelationsHistoricShow
