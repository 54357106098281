import React, { useState, useCallback, useMemo } from 'react'

import { Control, FieldErrors, useFieldArray, useWatch } from 'react-hook-form'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { GroupFormFields } from 'validations/groups'
import AddButton from 'components/atoms/add-button'
import RemoveIconButton from 'components/atoms/remove-icon-button'
import AddIconButton from 'components/atoms/add-icon-button'
import ModalConfirm from 'components/molecules/modal-confirm'
import { Metadata } from 'models/metadata'
import { FontFamily, FontSizes } from 'config/constants'

import ControllerInputText from '../controller-input-text'

import { MetadataActionContainer, MetadataInputContainer } from './styled'
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  error?: FieldErrors<GroupFormFields>
  disabled?: boolean
}
const ControllerCustomInputMetadata: React.FC<Props> = ({ control, error, disabled }) => {
  const { fields, append, remove } = useFieldArray({ control, name: 'metadata' })
  const fieldsWatch = useWatch({
    control,
    name: 'metadata',
  })
  const { t } = useTranslation()

  const [removeIndex, setRemoveIndex] = useState<number>(-1)
  const removeIndexMessage = useMemo(() => {
    const keyData = fieldsWatch?.[removeIndex] as unknown as Metadata
    const key = keyData?.key || ''
    const value = keyData?.value || ''

    return t('¿Eliminar metadato {{key}} - {{value}}?', {
      key,
      value,
      interpolation: { escapeValue: false },
    })
  }, [fieldsWatch, removeIndex, t])

  const onRemoveConfirm = useCallback(() => {
    remove(removeIndex)
    setRemoveIndex(-1)
  }, [remove, removeIndex])

  const onRemove = useCallback(
    (index: number) => {
      const keyData = fieldsWatch[index] as unknown as Metadata
      if (keyData?.key !== '') {
        setRemoveIndex(index)
      } else {
        remove(index)
      }
    },
    [fieldsWatch, remove]
  )

  return (
    <>
      {fields.map(({ id }, index) => {
        const metadataData = `metadata[${index}]`
        const errorData = error?.metadata?.[index]
        return (
          <MetadataInputContainer key={`groups-metadata-${id}-controller`}>
            <ControllerInputText
              mandatory
              control={control}
              name={`${metadataData}.key`}
              label={t('Clave')}
              error={errorData?.key}
              size='full'
              disabled={disabled}
            />
            <ControllerInputText
              mandatory
              name={`${metadataData}.value`}
              control={control}
              label={t('Valor')}
              error={errorData?.value}
              size='full'
              disabled={disabled}
            />

            {!disabled && (
              <MetadataActionContainer>
                {fields.length > 0 && <RemoveIconButton handleClick={() => onRemove(index)} />}
                {fields.length === index + 1 && <AddIconButton handleClick={() => append({ key: '', value: '' })} />}
              </MetadataActionContainer>
            )}
          </MetadataInputContainer>
        )
      })}
      {fields.length === 0 && !disabled && (
        <AddButton text={t('Añadir metadatos')} handleClick={() => append({ key: '', value: '' })} />
      )}
      {fields.length === 0 && disabled && (
        <Typography variant='h4' fontSize={FontSizes.base} fontFamily={FontFamily.light}>
          {t('No existen metadatos asociados')}
        </Typography>
      )}
      <ModalConfirm
        open={removeIndex > -1}
        onCancel={() => setRemoveIndex(-1)}
        onConfirm={onRemoveConfirm}
        title={t('Eliminar')}
        message={removeIndexMessage}
        confirmLabel={t('confirmar')}
        cancelLabel={t('cancelar')}
      />
    </>
  )
}

export default ControllerCustomInputMetadata
