import { useQuery } from 'react-query'

import { getOneGroupActiveIdentities } from 'services/groups'
import { UseGetOneGroupActiveIdentities } from 'models/groups'
import { Identity } from 'models/identities'
import { GET_ONE_GROUP_ACTIVE_IDENTITIES } from 'config/queries'
import { GroupActiveIdentitiesFilterFormFileds } from 'validations/groups'

interface Args {
  id: string
  limit: number
  offset: number
  filters: GroupActiveIdentitiesFilterFormFileds
  sort: string
}

const useGetOneGroupActiveIdentities = ({ id, limit, offset, filters, sort }: Args): UseGetOneGroupActiveIdentities => {
  const queryKey = [GET_ONE_GROUP_ACTIVE_IDENTITIES, id, limit, offset, filters, sort]
  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getOneGroupActiveIdentities({ id, limit, offset, filters, sort }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Array<Identity> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData }
}

export default useGetOneGroupActiveIdentities
