import dayjs from 'dayjs'
import * as yup from 'yup'

import { DateFormats } from 'config/constants'
import i18n from 'config/i18n'
import { Metadata } from 'models/metadata'
import { Keys } from 'models/keys'
import { ALPHANUMERIC_REGEX, METADATA_REGEX } from 'config/regex'
import { dateFormatter } from 'utils/dateFormatter'

import { handleKeysValidation } from './utils'

export const identitySchema = yup
  .object({
    version: yup.number(),
    keys: yup.array().of(
      yup.object({
        keyType: yup.string().required(),
        key: yup.string().when('keyType', handleKeysValidation),
      })
    ),
    name: yup.string().max(255).nullable(),
    domain: yup.string().required(),
    active: yup.boolean().required(),
    metadata: yup.array().of(
      yup.object({
        key: yup.string().required().max(255).matches(ALPHANUMERIC_REGEX),
        value: yup.string().required().max(4000, i18n.t('Ha superado los 4000 caracteres')).matches(METADATA_REGEX),
      })
    ),
    createDate: yup.string(),
    updateDate: yup.string(),
  })
  .required()

export type IdentityFormSchema = yup.InferType<typeof identitySchema>

export interface IdentityFormFields {
  id?: string
  version?: number
  keys: Keys[]
  name: string
  domain: string
  username: string
  active: boolean
  metadata: Metadata[]
  createDate?: string
  updateDate?: string
}

export const identitiesFilterSchema = yup.object({
  keyType: yup.string(),
  keyValue: yup.string(),
  name: yup.string(),
  domain: yup.string(),
  metadata: yup.string(),
  showActiveOnly: yup.boolean().default(true),
})

export type IdentitiesFilterFormSchema = yup.InferType<typeof identitiesFilterSchema>

export interface IdentitiesFilterFormFileds {
  keyType?: string
  keyValue?: string
  name?: string
  domain?: string
  metadata?: string
  showInactive?: boolean
  showActiveOnly?: boolean
  ids?: Array<number>
  q?: string
}

export const identitiesFilterDefaultValues: IdentitiesFilterFormFileds = {
  keyValue: '',
  keyType: '',
  name: '',
  domain: '',
  metadata: '',
  showActiveOnly: true,
}

export const identitiesActiveGroupsFilterSchema = yup.object({
  locationType: yup.string(),
  date: yup.string(),
})

export type IdentityActiveGroupsFilterFormSchema = yup.InferType<typeof identitiesActiveGroupsFilterSchema>

export interface IdentityActiveGroupsFilterFormFields {
  locationType?: string
  date?: string
}

export const identityActiveGroupsFilterDefaultValues: IdentityActiveGroupsFilterFormFields = {
  locationType: '',
  date: dateFormatter({ date: dayjs(), pattern: DateFormats.iso8601 }),
}
