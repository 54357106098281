import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import EventsTable from 'components/organisms/events/events-table'
import CountChip from 'components/atoms/count-chip'
import ResourceHeader from 'components/atoms/resource-header'
import ResourceFilter from 'components/molecules/resource-filter'
import EventsFilter from 'components/organisms/events/events-filter'
import useGetEvents from 'hooks/queries/event/useGetEvents'
import usePagination from 'hooks/usePagination'
import useFilters from 'hooks/useFilters'
import { eventsFilterDefaultValues } from 'validations/events'

const EventsList = (): JSX.Element => {
  const { t } = useTranslation()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()

  const { sortModel, badge, openFilter, filters, setSortModel, onSearchQ, onSearchFilter, onToggleFilter } =
    useFilters(eventsFilterDefaultValues)

  const { response, isLoading, count, queryKey } = useGetEvents({
    offset,
    limit: pageSize,
    filters: filters,
    sort: sortModel || 'id',
  })

  return (
    <Box>
      <ResourceHeader>
        <CountChip label={t('Registros')} labelSingular={t('Registro')} count={count} isLoading={isLoading} />
        <ResourceFilter
          badge={badge}
          label={t`Nombre, acción, tipo, clave ...`}
          openFilter={openFilter}
          onSearchQ={onSearchQ}
          onToggleFilter={onToggleFilter}
        />
      </ResourceHeader>
      {openFilter && <EventsFilter onSearch={onSearchFilter} />}
      <EventsTable
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        queryKey={queryKey}
      />
    </Box>
  )
}

export default EventsList
