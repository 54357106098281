import { Metadata } from 'models/metadata'

export const metadataDuplicatesDetector = (arr: Metadata[]): { duplicates: boolean; key: string; value: string } => {
  const keys = new Set<string>()
  let duplicates = false
  let key = ''
  let value = ''

  return arr.reduce(
    (acc: { duplicates: boolean; key: string; value: string }, item: Metadata) => {
      const uniqueKey = `${item.key}-${item.value}`

      if (keys.has(uniqueKey)) {
        duplicates = true
        key = item.key
        value = item.value
        return { duplicates, key, value }
      }

      keys.add(uniqueKey)
      return { duplicates, key, value }
    },
    { duplicates, key, value }
  )
}
