import React, { ReactNode, useCallback, useState, useMemo, useEffect } from 'react'

import { Control, FieldError, FieldErrors, useFieldArray, useWatch } from 'react-hook-form'
import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { FontSizes, KEY_TYPES, KeyType, LOCATION_TYPES } from 'config/constants'
import { GroupFormFields } from 'validations/groups'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerInputText from 'components/molecules/controller-input-text'
import RemoveIconButton from 'components/atoms/remove-icon-button'
import AddIconButton from 'components/atoms/add-icon-button'
import RefreshIconButton from 'components/atoms/refresh-icon-button'
import ModalConfirm from 'components/molecules/modal-confirm'
import { Keys } from 'models/keys'
import { keysFormatter, locationKeysFormatter } from 'utils/keysFormatter'
import useKeycloakIdKey from 'hooks/useKeycloakIdKey'

import { KeysActionContainer, KeysInputContainer } from './styled'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  error?: FieldErrors<GroupFormFields>
  disabled?: boolean
  locationKey?: boolean
}
const ControllerCustomInputKeys: React.FC<Props> = ({ control, error, disabled, locationKey }) => {
  const { fields, append, remove, update, replace } = useFieldArray({ control, name: 'keys' })
  const [isDefaultKeysAppend, setIsDefaultKeysAppend] = useState<boolean>(false)
  const fieldsWatch: Keys[] = useWatch({
    control,
    name: 'keys',
  })

  const { t } = useTranslation()
  const [removeIndex, setRemoveIndex] = useState<number>(-1)
  const { refreshKeycloakId, toggleAccountIdField, isInvalidId } = useKeycloakIdKey({
    error,
    keys: fieldsWatch,
    replace,
    update,
  })

  const removeIndexMessage = useMemo(() => {
    const keyData = fieldsWatch?.[removeIndex] as unknown as Keys
    const keyType = keyData?.keyType || ''
    const key = keyData?.key || ''

    return t('¿Eliminar ID {{keyType}} - {{key}}?', {
      keyType,
      key,
      interpolation: { escapeValue: false },
    })
  }, [fieldsWatch, removeIndex, t])

  const onRemoveConfirm = useCallback(() => {
    const indexes = [removeIndex]
    if (fieldsWatch[removeIndex + 1].keyType === KeyType.ACCOUNT_ID) {
      indexes.push(removeIndex + 1)
    }

    remove(indexes)
    setRemoveIndex(-1)
  }, [remove, removeIndex, fieldsWatch])

  const createKeysRow = useCallback(() => {
    append({ keyType: '', key: '' })
  }, [append])

  const onRemove = useCallback(
    (index: number) => {
      const keyData = fieldsWatch[index] as unknown as Keys
      if (keyData?.keyType !== '') {
        setRemoveIndex(index)
      } else {
        remove(index)
      }
    },
    [fieldsWatch, remove]
  )

  const handleDomainRenderValue = useCallback(
    (selection: string[] | string): ReactNode => {
      const value = !locationKey ? keysFormatter(selection as string) : locationKeysFormatter(selection as string)
      return <Chip sx={{ fontSize: FontSizes.sm }} size='small' label={value} />
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (!fieldsWatch?.length && !isDefaultKeysAppend) {
      setIsDefaultKeysAppend(true)
      createKeysRow()
    }

    toggleAccountIdField()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsWatch])

  const getKeyValueError = (index: number): FieldError | undefined => {
    if (fieldsWatch[index]?.keyType === KeyType.ACCOUNT_ID) {
      return isInvalidId(index)
        ? { message: t`No se ha encontrado un id para el email introducido`, type: 'invalidID' }
        : undefined
    }

    return error?.keys?.[index]?.key
  }

  return (
    <>
      {fields.map(({ id }, index) => {
        const keyData = `keys[${index}]`
        const keyType = fieldsWatch[index]?.keyType
        const errorData = error?.keys?.[index]
        const keyTypesOptions =
          keyType === KeyType.ACCOUNT_ID ? KEY_TYPES : KEY_TYPES.filter(({ value }) => value !== KeyType.ACCOUNT_ID)

        return (
          <KeysInputContainer key={`groups-keys-${id}-controller`}>
            <ControllerInputSelect
              mandatory
              control={control}
              name={`${keyData}.keyType`}
              label={t('Clave')}
              options={!locationKey ? keyTypesOptions : LOCATION_TYPES}
              size={disabled ? 'full' : 'small'}
              error={errorData?.keyType}
              disabled={disabled || keyType === KeyType.ACCOUNT_ID}
              renderValue={handleDomainRenderValue}
            />
            <ControllerInputText
              name={`${keyData}.key`}
              control={control}
              mandatory
              label={t('Valor')}
              error={getKeyValueError(index)}
              size={disabled ? 'full' : 'medium'}
              disabled={disabled || keyType === KeyType.ACCOUNT_ID}
              onBlur={refreshKeycloakId(index)}
            />

            {!disabled && (
              <KeysActionContainer>
                {fieldsWatch[index]?.keyType === KeyType.ACCOUNT_ID ? (
                  <RefreshIconButton handleClick={refreshKeycloakId(index - 1)} />
                ) : (
                  fields.length > 1 && <RemoveIconButton handleClick={() => onRemove(index)} />
                )}
                {fields.length === index + 1 && <AddIconButton handleClick={createKeysRow} />}
              </KeysActionContainer>
            )}
          </KeysInputContainer>
        )
      })}
      <ModalConfirm
        open={removeIndex > -1}
        onCancel={() => setRemoveIndex(-1)}
        onConfirm={onRemoveConfirm}
        title={t('Eliminar')}
        message={removeIndexMessage}
        confirmLabel={t('confirmar')}
        cancelLabel={t('cancelar')}
      />
    </>
  )
}

export default ControllerCustomInputKeys
