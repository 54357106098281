import { useQuery } from 'react-query'

import { getOneGroupVersion } from 'services/groups'
import { Group, UseGetOneGroupVersion } from 'models/groups'
import { GET_ONE_GROUP_VERSION } from 'config/queries'

interface Args {
  id?: string
  version?: string
}

const useGetOneGroupVersion = ({ id, version }: Args): UseGetOneGroupVersion => {
  const queryKey = [GET_ONE_GROUP_VERSION, id, version]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneGroupVersion(id, version),
    refetchOnMount: true,
    staleTime: 0,
  })

  const result: Group = response?.data

  return { response: result, isLoading }
}

export default useGetOneGroupVersion
