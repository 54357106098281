import { useEffect } from 'react'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'

import { useAuth } from 'providers/auth'
import { ReactComponent as Logo } from 'assets/images/svg/logo.svg'

import { DescriptionText, MainBox, MainContainer, LoginLoadingBox } from './styled'

const LoginForm = (): JSX.Element => {
  const { redirectToCognito } = useAuth()
  const { t } = useTranslation()

  useEffect(() => {
    redirectToCognito()
  }, [redirectToCognito])

  return (
    <MainContainer component='main' maxWidth='xs' data-testid='login-form'>
      <MainBox>
        <Box sx={{ my: 2 }}>
          <Logo width='200' />
        </Box>
        <DescriptionText component='p'>{t('Accede con tu usuario corporativo')}</DescriptionText>

        <LoginLoadingBox sx={{ my: 2 }}>
          <CircularProgress />
        </LoginLoadingBox>
      </MainBox>
    </MainContainer>
  )
}

export default LoginForm
