import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import useGetDomains from 'hooks/queries/domain/useGetDomains'
import { Domains } from 'models/domains'
import { IdentityFormFields, identitySchema } from 'validations/identities'
import routes from 'config/routes'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerCustomInputKeys from 'components/molecules/controller-custominput-keys'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerCustomInputMetadata from 'components/molecules/controller-custominput-metadata'
import SubmitButton from 'components/atoms/submit-button'
import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import PapperGridContainer from 'components/atoms/papper-grid-container-form'
import GridItem from 'components/atoms/grid-item'
import useCreateIdentity from 'hooks/queries/identity/useCreateIdentity'
import { useAuth } from 'providers/auth'
import { CreateIdentities } from 'models/identities'
import FormTitle from 'components/atoms/form-title'
import useFormErrorScroll from 'hooks/useFormErrorScroll'
import { metadataDuplicatesDetector } from 'utils/metadataDuplicatesDetector'

const defaultValues: IdentityFormFields = {
  keys: [{ keyType: '', key: '' }],
  name: '',
  domain: '',
  username: '',
  active: true,
  metadata: [],
}

const IdentitiesCreateForm = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useAuth()

  const { response } = useGetDomains()
  const createIdentity = useCreateIdentity()

  const domains = response.map(({ name: domain }: Domains) => ({ value: domain, text: t(domain) }))

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IdentityFormFields>({
    defaultValues,
    resolver: yupResolver(identitySchema),
  })

  useFormErrorScroll(errors)

  const onSubmit = (identity: IdentityFormFields) => {
    const { duplicates, key } = metadataDuplicatesDetector(identity.metadata)

    if (duplicates) {
      toast(t(t('Clave de metadato duplicada {{key}}', { key })), { type: 'error' })
    } else {
      const result: CreateIdentities = {
        ...identity,
        username: user?.email,
        name: identity.name?.trim().length ? identity.name : undefined,
      }
      createIdentity.mutate(result)
      navigate(routes.identitiesList)
    }
  }

  return (
    <Grid container component='form' onSubmit={handleSubmit(onSubmit)} data-testid='identities-create-form'>
      <PapperGridContainer>
        <GridItem item xs={12}>
          <FormTitle text={t('Información')} />
        </GridItem>
        <GridItem item md={6} xs={12}>
          <ControllerInputSelect
            mandatory
            control={control}
            name='domain'
            error={errors.domain}
            label={t('Dominio')}
            size='full'
            options={domains}
          />
          <ControllerCustomInputKeys control={control} error={errors} />
          <ControllerInputText
            control={control}
            name='name'
            error={errors.name}
            label={t('Nombre/Descripción')}
            size='full'
          />
        </GridItem>
        <GridItem container item xs={12}>
          <FormTitle text={t('Metadatos')} />
          <ControllerCustomInputMetadata control={control} error={errors} />
        </GridItem>
      </PapperGridContainer>
      <FormButtonsContainer>
        <CancelButton to={routes.identitiesList} />
        <SubmitButton />
      </FormButtonsContainer>
    </Grid>
  )
}

export default IdentitiesCreateForm
