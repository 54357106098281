import PageContainer from 'components/atoms/page-container'
import LocationsEditForm from 'components/organisms/locations/locations-edit-form'

const LocationsEdit = (): JSX.Element => {
  return (
    <PageContainer>
      <LocationsEditForm />
    </PageContainer>
  )
}

export default LocationsEdit
