import { useEffect, useState } from 'react'

import { Link, useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/useAdministrationPermissions'
import routes from 'config/routes'

enum TabValue {
  users,
  applications,
  audit,
  locations,
}

const AdministrationTabs = (): JSX.Element => {
  const { t } = useTranslation()

  const matchesUsers = useMatch(`${routes.usersList}/*`)
  const matchesApplications = useMatch(`${routes.applicationsList}/*`)
  const matchesAudit = useMatch(`${routes.eventsList}/*`)
  const matchesLocations = useMatch(`${routes.locationsList}/*`)

  const [value, setValue] = useState<TabValue>(TabValue.users)

  useEffect(() => {
    let match: TabValue = TabValue.users
    if (matchesApplications) {
      match = TabValue.applications
    } else if (matchesAudit) {
      match = TabValue.audit
    } else if (matchesLocations) {
      match = TabValue.locations
    }
    setValue(match)
  }, [matchesApplications, matchesAudit, matchesLocations, matchesUsers])

  const { canRead: usersCanRead } = useAdministrationPermissions(AdministrationSections.users)
  const { canRead: applicationsCanRead } = useAdministrationPermissions(AdministrationSections.applications)
  const { canRead: eventsCanRead } = useAdministrationPermissions(AdministrationSections.events)
  const { canRead: locationsCanRead } = useAdministrationPermissions(AdministrationSections.locations)

  return (
    <Tabs value={value} textColor='secondary' indicatorColor='secondary' variant='fullWidth'>
      {usersCanRead && (
        <Tab
          component={Link}
          value={TabValue.users}
          label={t('Usuarios')}
          to={routes.usersList}
          data-testid='administration-users-tab'
        />
      )}
      {applicationsCanRead && (
        <Tab
          component={Link}
          value={TabValue.applications}
          label={t('Aplicaciones')}
          to={routes.applicationsList}
          data-testid='administration-applications-tab'
        />
      )}
      {eventsCanRead && (
        <Tab
          component={Link}
          value={TabValue.audit}
          label={t('Auditoría')}
          to={routes.eventsList}
          data-testid='administration-audit-tab'
        />
      )}
      {locationsCanRead && (
        <Tab
          component={Link}
          value={TabValue.locations}
          label={t('Localizaciones')}
          to={routes.locationsList}
          data-testid='administration-locations-tab'
        />
      )}
    </Tabs>
  )
}

export default AdministrationTabs
