import Box from '@mui/material/Box/Box'
import { styled } from '@mui/material/styles'

import { INPUT_BASE_HEIGHT } from 'config/constants'

export const LocationsActionContainer = styled(Box)`
  min-width: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: ${INPUT_BASE_HEIGHT};
`
export const LocationsInputContainer = styled(Box)`
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  justify-content: center;
`

export const LocationsMainContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
`
