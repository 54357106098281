import { useQuery } from 'react-query'

import { getOneRelationIdentities } from 'services/relations'
import { UseGetRelationIdentitiesCount } from 'models/relations'
import { GET_ONE_RELATION_IDENTITIES } from 'config/queries'

interface Args {
  id?: string
}

const useGetRelationIdentitiesCount = ({ id }: Args): UseGetRelationIdentitiesCount => {
  const queryKey = [GET_ONE_RELATION_IDENTITIES, id]

  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneRelationIdentities({ id, limit: 1 }),
    refetchOnWindowFocus: false,
  })

  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { count, isLoading }
}

export default useGetRelationIdentitiesCount
