import { useParams } from 'react-router-dom'

import PageContainer from 'components/atoms/page-container'
import GroupsEditForm from 'components/organisms/groups/groups-edit-form'
import useGetOneGroup from 'hooks/queries/group/useGetOneGroup'

const GroupsEdit = (): JSX.Element => {
  const { id } = useParams()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { isLoading, response } = useGetOneGroup({ id: id! })
  return (
    <PageContainer>
      <GroupsEditForm isLoading={isLoading} response={response} groupId={id} />
    </PageContainer>
  )
}

export default GroupsEdit
