import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/react'

import routes from 'config/routes'
import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import PapperGridContainer from 'components/atoms/papper-grid-container-form'
import GridItem from 'components/atoms/grid-item'
import { useAuth } from 'providers/auth'
import { RelationFormFileds, relationSchema } from 'validations/relations'
import RelationTabs from 'components/molecules/relation-tabs'
import { DAY_LIST, FontSizes, RelationTabsValue } from 'config/constants'
import RelationTabConatiner from 'components/molecules/relation-tab-container'
import ControllerIdentityList from 'components/molecules/controller-identity-list'
import ControllerGroupList from 'components/molecules/controller-group-list'
import ControllerRelationConfig from 'components/molecules/controller-relation-config'
import RelationFormResume from 'components/molecules/relation-form-resume'
import BasicButton from 'components/atoms/basic-button'
import { LocationsGroups } from 'validations/groups'
import { Location } from 'models/locations'
import { Relation, UpdateRelations } from 'models/relations'
import { periodTimeHandler } from 'utils/periodTimeHandler'
import FormTitle from 'components/atoms/form-title'
import SpinnerLoading from 'components/atoms/spinner-loading'
import useUpdateRelation from 'hooks/queries/relation/useUpdateRelation'
import { DayList } from 'models/dates'
import { PeriodTime } from 'models/groups'
import useFormErrorScroll from 'hooks/useFormErrorScroll'
import FormSubtitle from 'components/atoms/form-subtitle'
import useUpdateOneRelationIdentities from 'hooks/queries/relation/useUpdateOneRelationIdentites'
import useDeleteOneRelationIdentities from 'hooks/queries/relation/useDeleteOneRelationIdentities'
import { metadataDuplicatesDetector } from 'utils/metadataDuplicatesDetector'
import { isBeforeNow } from 'utils/dateUtils'
import { periodDateHandler } from 'utils/periodDateHandler'

import RelationsSelectedIdentities from '../relations-selected-identities'
import RelationsIdentitiesList from '../relations-identities-list'

interface Props {
  response: Relation
  isLoading: boolean
  relationId?: string
  disabled?: boolean
  historic?: boolean
}

const defaultValues: RelationFormFileds = {
  identityIds: [],
  groupIds: [],
  locationRestriction: true,
  keys: [{ keyType: '', key: '' }],
  locations: [],
  name: '',
  domain: '',
  username: '',
  active: true,
  periodDate: { from: '', to: '' },
  periodTime: [],
  metadata: [],
}

const RelationsEditForm: React.FC<Props> = ({ isLoading, response, disabled, historic, relationId }) => {
  const [tabValue, setTabValue] = useState<RelationTabsValue>(
    historic ? RelationTabsValue.config : RelationTabsValue.identities
  )
  const [responseLoaded, setResponseLoaded] = useState(false)

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { user } = useAuth()

  const updateRelation = useUpdateRelation()
  const updateRelationIdentities = useUpdateOneRelationIdentities({ id: relationId })
  const deleteRelationIdentities = useDeleteOneRelationIdentities({ id: relationId })

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    reset,
    trigger,
  } = useForm<RelationFormFileds>({
    defaultValues,
    resolver: yupResolver(relationSchema),
    context: { periodDateFromDisabled: disabled || !isBeforeNow(response?.periodDate?.from) },
  })

  useFormErrorScroll(errors)

  useEffect(() => {
    if (!response) return

    reset({
      active: response.active,
      createDate: response.createDate,
      domain: response.domain,
      groupIds: response.groupIds,
      identityIds: response.identityIds,
      keys: response.keys ?? [],
      locationRestriction: response.locationRestriction,
      locations: response?.locations?.map(({ type, id, ...rest }) => ({ ...rest, keyType: type, id: `${id}` })),
      metadata: response.metadata,
      name: response.name,
      periodDate: response.periodDate,
      version: response.version,
      updateDate: response.updateDate,
      username: response.username,
      periodTime: response.periodTime?.map(({ dayOfWeek, ...rest }: PeriodTime) => ({
        ...rest,
        dayOfWeek: dayOfWeek.length === 0 ? DAY_LIST.map((day: DayList) => day.value) : dayOfWeek,
      })),
    })

    setResponseLoaded(true)
  }, [response, reset])

  const { locationRestriction: isLocationActive, identityIds, groupIds } = watch()

  const handleOnTabChange = async (tab: RelationTabsValue) => {
    if (disabled) {
      return setTabValue(tab)
    }
    if (!identityIds.length) {
      return toast(t('Seleccione al menos una identidad'), { type: 'error' })
    }

    if (!groupIds.length && tab === RelationTabsValue.config) {
      return toast(t('Seleccione al menos un grupo'), { type: 'error' })
    }

    const isConfigFullfilled = tab === RelationTabsValue.resume && (await trigger())

    if (!isConfigFullfilled && tab === RelationTabsValue.resume) {
      return toast(t('Rellene todos los campos requeridos'), { type: 'error' })
    }

    setTabValue(tab)
  }

  const onSubmit = async ({
    locationRestriction,
    locations,
    periodTime,
    version,
    periodDate,
    identityIds: identities,
    ...relations
  }: RelationFormFileds) => {
    const { duplicates, key } = metadataDuplicatesDetector(relations.metadata)

    if (duplicates) {
      toast(t(t('Clave de metadato duplicada {{key}}', { key })), { type: 'error' })
    } else {
      const payload: UpdateRelations = {
        ...relations,
        id: relationId,
        username: user?.email as string,
        locationRestriction,
        locations: locationRestriction
          ? locations.map(({ id }: LocationsGroups) => ({ id: parseInt(id) } as Location))
          : [],
        periodTime: periodTimeHandler(periodTime),
        periodDate: periodDateHandler(periodDate),
      }

      const prevIdentities = response.identityIds
      const identitiesToDelete = prevIdentities.filter(ids => !identities.includes(ids))
      const identitiesToAdd = identities.filter(ids => !prevIdentities.includes(ids))

      try {
        navigate(routes.relationsList)
        await updateRelation.mutateAsync(payload)
        if (identitiesToAdd.length)
          await updateRelationIdentities.mutateAsync({ id: relationId, identityIds: identitiesToAdd })
        if (identitiesToDelete.length)
          await deleteRelationIdentities.mutateAsync({ id: relationId, identityIds: identitiesToDelete })
        toast(t('Relación actualizada'), { type: 'success' })
      } catch (error) {
        Sentry.captureException(error)
        toast(t('Ha ocurrido un error'), { type: 'error' })
      }
    }
  }

  if (isLoading || !responseLoaded) {
    return <SpinnerLoading fullHeight />
  }

  return (
    <Grid container component='form' onSubmit={handleSubmit(onSubmit)} data-testid='relations-edit-form'>
      <PapperGridContainer>
        <GridItem item xs={12}>
          <RelationTabs onTabsChange={handleOnTabChange} value={tabValue} historic={historic} />
          {!historic && (
            <RelationTabConatiner value={tabValue} index={RelationTabsValue.identities}>
              <FormTitle text={t('Identidades')} />
              {disabled ? (
                identityIds?.length ? (
                  <RelationsIdentitiesList relationId={relationId} identitiesIds={identityIds} />
                ) : (
                  <FormSubtitle text={t('No existen identidades asociadas')} fontSize={FontSizes.lg} />
                )
              ) : (
                <>
                  <ControllerIdentityList control={control} />
                  {identityIds?.length > 0 && (
                    <Box width='100%'>
                      <Typography
                        variant='h3'
                        fontSize={FontSizes.xl}
                        marginBottom={2}
                      >{t`Identidades seleccionadas`}</Typography>
                      <RelationsSelectedIdentities identitiesIds={identityIds} />
                    </Box>
                  )}
                </>
              )}
            </RelationTabConatiner>
          )}
          {!historic && (
            <RelationTabConatiner value={tabValue} index={RelationTabsValue.groups}>
              <FormTitle text={t('Grupos')} />
              {disabled && !groupIds?.length ? (
                <FormSubtitle text={t('No existen grupos asociados')} fontSize={FontSizes.lg} />
              ) : (
                <>
                  <ControllerGroupList control={control} disabled={disabled} />
                  {!disabled && groupIds?.length > 0 && (
                    <Box width='100%'>
                      <Typography
                        variant='h3'
                        fontSize={FontSizes.xl}
                        marginBottom={2}
                      >{t`Grupos seleccionados`}</Typography>
                      <ControllerGroupList control={control} disabled={true} />
                    </Box>
                  )}
                </>
              )}
            </RelationTabConatiner>
          )}
          <RelationTabConatiner value={tabValue} index={RelationTabsValue.config}>
            <ControllerRelationConfig
              control={control}
              errors={errors}
              isLocationsActive={isLocationActive}
              disabled={disabled}
              relationId={relationId}
              historic={historic}
              response={response}
            />
          </RelationTabConatiner>
          <RelationTabConatiner value={tabValue} index={RelationTabsValue.resume}>
            <RelationFormResume getValues={getValues} />
          </RelationTabConatiner>
          <Box display='flex' gap={2}>
            {((!historic && tabValue !== RelationTabsValue.identities) ||
              (historic && tabValue !== RelationTabsValue.config)) && (
              <BasicButton
                text={t('Atrás')}
                type='button'
                variant='text'
                size='small'
                handleClick={() => handleOnTabChange(tabValue - 1)}
              />
            )}
            {tabValue !== RelationTabsValue.resume && (
              <BasicButton
                text={t('Siguiente')}
                type='button'
                variant='text'
                size='small'
                handleClick={() => handleOnTabChange(tabValue + 1)}
              />
            )}
          </Box>
        </GridItem>
      </PapperGridContainer>
      <FormButtonsContainer>
        <CancelButton />
        {tabValue === 3 && !disabled && (
          <BasicButton handleClick={handleSubmit(onSubmit)} text={t('Guardar cambios')} />
        )}
      </FormButtonsContainer>
    </Grid>
  )
}

export default RelationsEditForm
