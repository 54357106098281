import { useQuery } from 'react-query'

import { getOneGroup } from 'services/groups'
import { Group, UseGetOneGroup } from 'models/groups'
import { GET_ONE_GROUP } from 'config/queries'

interface Args {
  id: string
}

const useGetOneGroup = ({ id }: Args): UseGetOneGroup => {
  const queryKey = [GET_ONE_GROUP, id]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneGroup(id),
    refetchOnMount: true,
    staleTime: 0,
  })

  const result: Group = response?.data

  return { response: result, isLoading }
}

export default useGetOneGroup
