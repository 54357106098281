import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const KeysContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex;
  max-width: 10rem;
  overflow: hidden;
` as typeof Box

export const KeysMasterContainer = styled(Box)`
  min-width: 10rem;
  display: grid;
  grid-template-columns: 6fr 1fr;
  gap: 0.2rem;
` as typeof Box
