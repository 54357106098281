import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import oldstyled from 'styled-components'

interface Props {
  direction?: 'row' | 'column'
  gap?: string
}

export const StyledContainer = oldstyled('div')`
  width: 100%;
  display: flex;
  flex-direction: ${(props: Props) => props?.direction || 'row'};
  gap: ${(props: Props) => props?.gap || '0.5rem'};
  justify-content: center;
  align-items: flex-start;
`

export const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
`
