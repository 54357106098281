import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import SyncIcon from '@mui/icons-material/Sync'
import { CircularProgress } from '@mui/material'

import { colors } from 'config/theme'

interface Props {
  label: string
  handleClick: () => void
  loading: boolean
}

const AddCarparksButton: React.FC<Props> = ({ label, handleClick, loading }) => (
  <Box>
    <Button variant='contained' color={loading ? 'warning' : 'primary'} startIcon={<SyncIcon />} onClick={handleClick}>
      {label}
      {loading && <CircularProgress size={20} sx={{ marginLeft: 1, color: colors.darkGrey }} />}
    </Button>
  </Box>
)

export default AddCarparksButton
