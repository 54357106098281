import { useQuery } from 'react-query'

import { getOneIdentity } from 'services/identities'
import { Identity, UseGetOneIdentity } from 'models/identities'
import { GET_ONE_IDENTITY } from 'config/queries'

interface Args {
  id?: string
}

const useGetOneIdentity = ({ id }: Args): UseGetOneIdentity => {
  const queryKey = [GET_ONE_IDENTITY, id]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneIdentity(id),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    staleTime: 0,
  })

  const result: Identity = response?.data

  return { response: result, isLoading }
}

export default useGetOneIdentity
