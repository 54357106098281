import { BrowserRouter, Routes, Route } from 'react-router-dom'

import routes from 'config/routes'
import NoMatchPage from 'components/pages/no-match'
import LoginPage from 'components/pages/auth/login'
import IdentitiesListPage from 'components/pages/identities/identities-list'
import IdentitiesHistoricPage from 'components/pages/identities/identities-historic'
import IdentitiesEditPage from 'components/pages/identities/identities-edit'
import IdentitiesShowPage from 'components/pages/identities/identities-show'
import IdentitiesCreatePage from 'components/pages/identities/identities-create'
import IdentitiesHistoricShowPage from 'components/pages/identities/identities-historic-show'
import GroupsListPage from 'components/pages/groups/groups-list'
import GroupsHistoricPage from 'components/pages/groups/groups-historic'
import GroupsEditPage from 'components/pages/groups/groups-edit'
import GroupsShowPage from 'components/pages/groups/groups-show'
import GroupsCreatePage from 'components/pages/groups/groups-create'
import GroupsHistoricShowPage from 'components/pages/groups/groups-historic-show'
import RelationsListPage from 'components/pages/relations/relations-list'
import RelationsHistoricPage from 'components/pages/relations/relations-historic'
import RelationsEditPage from 'components/pages/relations/relations-edit'
import RelationsCreatePage from 'components/pages/relations/relations-create'
import RelationsShowPage from 'components/pages/relations/relations-show'
import RelationsHistoricShowPage from 'components/pages/relations/relations-historic-show'
import ApplicationsListPage from 'components/pages/applications/applications-list'
import ApplicationsHistoricPage from 'components/pages/applications/applications-historic'
import ApplicationsEditPage from 'components/pages/applications/applications-edit'
import ApplicationsCreatePage from 'components/pages/applications/applications-create'
import ApplicationsShowPage from 'components/pages/applications/applications-show'
import UsersListPage from 'components/pages/users/users-list'
import UsersHistoricPage from 'components/pages/users/users-historic'
import UsersEditPage from 'components/pages/users/users-edit'
import CognitoRedirect from 'components/pages/auth/cognito-redirect'
import UsersCreatePage from 'components/pages/users/users-create'
import UsersShowPage from 'components/pages/users/users-show'
import EventsListPage from 'components/pages/events/events-list'
import EventsShowPage from 'components/pages/events/events-show'
import LocationsListPage from 'components/pages/locations/locations-list'
import LocationsHistoricPage from 'components/pages/locations/locations-historic'
import LocationsEditPage from 'components/pages/locations/locations-edit'
import LocationsCreatePage from 'components/pages/locations/locations-create'
import LocationsShowPage from 'components/pages/locations/locations-show'
import AdministrationLayout from 'components/layouts/administration-layout'
import IdentitiesLayout from 'components/layouts/indentities-layout'
import GroupsLayout from 'components/layouts/groups-layout'
import RelationsLayout from 'components/layouts/relations-layout'
import AuthLayout from 'components/layouts/auth-layout'

import ScrollToTop from './scroll-top'
import {
  RequireIdentitiesRolesPermissions,
  RequireGroupsRolesPermissions,
  RequireRelationsRolesPermissions,
  RequireAdministrationRolesPermissions,
} from './routes-permissions'

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<AuthLayout />}>
        <Route
          element={
            <RequireIdentitiesRolesPermissions>
              <IdentitiesLayout />
            </RequireIdentitiesRolesPermissions>
          }
        >
          <Route path={routes.root} element={<IdentitiesListPage />} />
          <Route path={routes.identitiesList} element={<IdentitiesListPage />} />
          <Route path={routes.identitiesHistoric} element={<IdentitiesHistoricPage />} />
          <Route path={routes.identitiesHistoricShow} element={<IdentitiesHistoricShowPage />} />
          <Route path={routes.identitiesEdit} element={<IdentitiesEditPage />} />
          <Route path={routes.identitiesCreate} element={<IdentitiesCreatePage />} />
          <Route path={routes.identitiesShow} element={<IdentitiesShowPage />} />
        </Route>

        <Route
          element={
            <RequireGroupsRolesPermissions>
              <GroupsLayout />
            </RequireGroupsRolesPermissions>
          }
        >
          <Route path={routes.groupsList} element={<GroupsListPage />} />
          <Route path={routes.groupsHistoric} element={<GroupsHistoricPage />} />
          <Route path={routes.groupsHistoricShow} element={<GroupsHistoricShowPage />} />
          <Route path={routes.groupsEdit} element={<GroupsEditPage />} />
          <Route path={routes.groupsCreate} element={<GroupsCreatePage />} />
          <Route path={routes.groupsShow} element={<GroupsShowPage />} />
        </Route>

        <Route
          element={
            <RequireRelationsRolesPermissions>
              <RelationsLayout />
            </RequireRelationsRolesPermissions>
          }
        >
          <Route path={routes.relationsList} element={<RelationsListPage />} />
          <Route path={routes.relationsHistoric} element={<RelationsHistoricPage />} />
          <Route path={routes.relationsHistoricShow} element={<RelationsHistoricShowPage />} />
          <Route path={routes.relationsEdit} element={<RelationsEditPage />} />
          <Route path={routes.relationsCreate} element={<RelationsCreatePage />} />
          <Route path={routes.relationsShow} element={<RelationsShowPage />} />
        </Route>

        <Route
          element={
            <RequireAdministrationRolesPermissions>
              <AdministrationLayout />
            </RequireAdministrationRolesPermissions>
          }
        >
          <Route path={routes.administration} element={<UsersListPage />} />

          <Route path={routes.usersList} element={<UsersListPage />} />
          <Route path={routes.usersHistoric} element={<UsersHistoricPage />} />
          <Route path={routes.usersEdit} element={<UsersEditPage />} />
          <Route path={routes.usersCreate} element={<UsersCreatePage />} />
          <Route path={routes.usersShow} element={<UsersShowPage />} />

          <Route path={routes.applicationsList} element={<ApplicationsListPage />} />
          <Route path={routes.applicationsHistoric} element={<ApplicationsHistoricPage />} />
          <Route path={routes.applicationsEdit} element={<ApplicationsEditPage />} />
          <Route path={routes.applicationsCreate} element={<ApplicationsCreatePage />} />
          <Route path={routes.applicationsShow} element={<ApplicationsShowPage />} />

          <Route path={routes.eventsList} element={<EventsListPage />} />
          <Route path={routes.eventsShow} element={<EventsShowPage />} />

          <Route path={routes.locationsList} element={<LocationsListPage />} />
          <Route path={routes.locationsHistoric} element={<LocationsHistoricPage />} />
          <Route path={routes.locationsEdit} element={<LocationsEditPage />} />
          <Route path={routes.locationsCreate} element={<LocationsCreatePage />} />
          <Route path={routes.locationsShow} element={<LocationsShowPage />} />
        </Route>
      </Route>

      {/* public routes */}
      <Route path={routes.login} element={<LoginPage />} />
      <Route path={routes.cognitoRedirect} element={<CognitoRedirect />} />
      <Route path='*' element={<NoMatchPage />} />
    </Routes>

    <ScrollToTop />
  </BrowserRouter>
)

export default Router
