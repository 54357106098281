import { ChangeEvent, FC } from 'react'

import Box from '@mui/material/Box'

import FilterIconButton from 'components/atoms/filter-icon-button'
import SearchInput from 'components/atoms/search-input'

interface ResourceFilterProps {
  badge: number
  label: string
  openFilter: boolean
  onSearchQ: (event: ChangeEvent<HTMLInputElement>) => void
  onToggleFilter: () => void
}

const ResourceFilter: FC<ResourceFilterProps> = ({ badge, label, openFilter, onSearchQ, onToggleFilter }) => {
  return (
    <Box gap={1} flex={1} flexDirection='row' display='flex' ml={1}>
      <SearchInput disabled={openFilter} label={label} onChange={onSearchQ} />
      <FilterIconButton badge={badge} onClick={onToggleFilter} />
    </Box>
  )
}

export default ResourceFilter
