import React from 'react'

import { GridEnrichedColDef } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

import routes from 'config/routes'
import BasicTable from 'components/molecules/basic-table'
import ShowGridActionCellItem from 'components/atoms/show-grid-action-cell-item'
import { Group } from 'models/groups'
import { RowHeight, TableColumnWidth } from 'config/constants'
import { dateFormatter } from 'utils/dateFormatter'

interface Props {
  id?: string
  rows: Group[]
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageSizeChange: (pageSize: number) => void
  onPageChange: (page: number) => void
}

const GroupsHistoricTable: React.FC<Props> = ({ id, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToShow = (version: number) => {
    const route = routes.groupsHistoricShow.replace(':id', id ?? '').replace(':version', version.toString())
    navigate(route)
  }

  const columns: GridEnrichedColDef[] = [
    {
      field: 'version',
      headerName: `${t('Versión')}`,
      flex: TableColumnWidth.xs,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'createDate',
      headerName: `${t('Fecha de creación')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Group }) => <Typography>{dateFormatter({ date: row.createDate })}</Typography>,
    },
    {
      field: 'updateDate',
      headerName: `${t('Fecha de modificación')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Group }) => <Typography>{dateFormatter({ date: row.updateDate })}</Typography>,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: TableColumnWidth.xs,
      getActions: ({ row }: { row: Group }) => [
        <ShowGridActionCellItem key={`${row.id}-show`} onClick={() => navigateToShow(row?.version)} />,
      ],
    },
  ]

  return (
    <BasicTable
      {...props}
      rowHeight={RowHeight.small}
      columns={columns}
      onRowClick={({ row }: { row: Group }) => navigateToShow(row.version)}
    />
  )
}

export default GroupsHistoricTable
