import { useQuery } from 'react-query'

import { getLocations } from 'services/locations'
import { Location, UseGetLocation } from 'models/locations'
import { GET_LOCATIONS } from 'config/queries'
import { LocationsFilterFormFields } from 'validations/locations'

interface Args {
  pageSize: number
  offset: number
  filters: LocationsFilterFormFields
  sort: string
}

const useGetLocations = ({ pageSize, offset, filters, sort }: Args): UseGetLocation => {
  const queryKey = [GET_LOCATIONS, pageSize, offset, filters, sort]

  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () =>
      getLocations({
        limit: pageSize,
        offset,
        filters,
        sort,
      }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Array<Location> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData }
}

export default useGetLocations
