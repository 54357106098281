import { useQuery } from 'react-query'

import { GET_ONE_LOCATION } from 'config/queries'
import { getOneLocation } from 'services/locations'
import { Location } from 'models/locations'

interface Props {
  id: string
}

const useGetOneLocation = ({ id }: Props) => {
  const queryKey: Array<string> = [GET_ONE_LOCATION, id]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneLocation({ id }),
    refetchOnMount: true,
    staleTime: 0,
    cacheTime: 0,
  })
  const location: Location = response?.data

  return { isLoading, response: location }
}

export default useGetOneLocation
