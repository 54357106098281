import axios from 'services'
import { Domains } from 'models/domains'
export const getDomains = () => {
  return axios.get('/v1/domains')
}

export const getAllDomains = async (items: Array<Domains> = []): Promise<Array<Domains>> => {
  const res = await axios.get('/v1/domains', { params: { limit: 20, offset: items?.length } })

  const data: Array<Domains> = res?.data || []
  const count: number = parseInt(res?.headers?.['x-total-count'] || 0)
  const totalData = [...items, ...data]

  if (totalData.length === count) {
    return totalData
  } else {
    return getAllDomains(totalData)
  }
}
