import React from 'react'

import { Control, Controller, FieldError } from 'react-hook-form'

import { Sizes } from 'models/sizes'
import BasicTimePicker from 'components/atoms/basic-time-picker'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  error?: FieldError
  disabled?: boolean
  name: string
  label: string
  size?: Sizes
  mandatory?: boolean
}

const ControllerInputTimePicker: React.FC<Props> = ({
  control,
  disabled,
  name,
  label,
  error,
  mandatory = false,
  size = 'medium',
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <BasicTimePicker
          mandatory={mandatory}
          error={!!error}
          helperText={error && error.message}
          field={field}
          label={label}
          disabled={disabled}
          size={size}
        />
      )}
    />
  )
}

export default ControllerInputTimePicker
