import dayjs from 'dayjs'
import { t } from 'i18next'
import * as yup from 'yup'

import { DateFormats, INVALID_DATE } from 'config/constants'
import { PeriodDate, PeriodTime } from 'models/groups'

export const isMinorHour = {
  name: 'isMinorHour',
  message: `${t('La hora de fin no puede ser menor que la de inicio')}`,
  test: (value: string | undefined, { parent }: { parent: PeriodDate }) => {
    return dayjs(parent.from, DateFormats.hour) < dayjs(parent.to, DateFormats.hour)
  },
}

export const isMinorPeriodDate = {
  name: 'isMinorPeriodDate',
  message: `${t('La fecha de fin no puede ser menor que la de inicio')}`,
  test: (value: string | undefined, { parent }: { parent: PeriodTime }) => {
    if (!parent.to || parent.to === INVALID_DATE) return true
    return dayjs(parent.from, DateFormats.iso) < dayjs(parent.to, DateFormats.iso)
  },
}

export const currentDateIsMinor = {
  name: 'currentDateIsMinor',
  message: `${t('La fecha de comienzo no puede ser menor que la fecha actual')}`,
  test: (value: string | undefined, { parent }: { parent: PeriodTime }) => {
    if (!parent.from || parent.from === INVALID_DATE) return true
    return dayjs().second(59) < dayjs(parent.from)
  },
}

export const isSimilarHour = {
  name: 'isSimilarHour',
  message: `${t('La hora no puede ser la misma')}`,
  test: (value: string | undefined, { parent }: { parent: PeriodTime }) => parent.from !== parent.to,
}

export const handleKeysValidation = (value: string) => {
  if (value === 'EMAIL') return yup.string().required().email().max(255)
  else if (value === 'ACCOUNT_ID')
    return yup.string().required(t`Se necesita un ID válido asociado al email introducido`)
  return yup.string().required().trim().max(255)
}

export const locationChecker = (value: boolean) => {
  if (!value) return yup.array()
  return yup.array().min(1)
}

export const isCoordinateNumber = {
  name: 'coordinate',
  message: `${t('Debe ser un valor numérico')}`,
  test: (value?: string) => {
    if (!value) return false
    if (!isNaN(value as unknown as number)) return true
    return false
  },
}
