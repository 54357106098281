import React, { useEffect } from 'react'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import IdentitiesTable from 'components/organisms/identities/identities-table'
import CountChip from 'components/atoms/count-chip'
import ResourceFilter from 'components/molecules/resource-filter'
import { TableTypes } from 'config/constants'
import { identitiesFilterDefaultValues } from 'validations/identities'
import IdentitiesFilter from 'components/organisms/identities/identities-filter'
import { ListProps } from 'models/list'
import usePagination from 'hooks/usePagination'
import useFilters from 'hooks/useFilters'
import useGetOneRelationIdentitiesWithFilters from 'hooks/queries/relation/useGetOneRelationIdentitiesWithFilters'

import * as S from './styled'

const MAX_IDENTITIES_FILTER = 750

interface RelationsIdentitiesListProps extends ListProps {
  relationId?: string
  identitiesIds: number[]
}

const RelationsIdentitiesList: React.FC<RelationsIdentitiesListProps> = ({
  relationId,
  identitiesIds,
  showInactives,
  ...props
}) => {
  const { t } = useTranslation()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()
  const { sortModel, badge, openFilter, filters, setSortModel, onSearchQ, setBadge, onToggleFilter, onSearchFilter } =
    useFilters({ ...identitiesFilterDefaultValues, showActiveOnly: !showInactives })

  const { response, isLoading, count, queryKey } = useGetOneRelationIdentitiesWithFilters({
    relationId,
    identitiesIds,
    offset,
    limit: pageSize,
    filters: { ...filters, showInactive: !filters.showActiveOnly, showActiveOnly: undefined },
    sort: sortModel || 'id',
  })

  useEffect(() => {
    if (!showInactives) {
      return
    }

    onSearchFilter({ ...filters, showActiveOnly: false })
    setBadge(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInactives])

  return (
    <Box width='100%'>
      <S.SResourceHeader>
        {count > MAX_IDENTITIES_FILTER && (
          <S.FiltersOverlay>
            {t('No pueden aplicarse filtros a relaciones con más de {{count}} identidades.', {
              count: MAX_IDENTITIES_FILTER,
            })}
          </S.FiltersOverlay>
        )}
        <CountChip label={t('Registros')} labelSingular={t('Registro')} count={count} isLoading={isLoading} />
        <ResourceFilter
          badge={badge}
          label={t`Identificador, nombre, dominio, metadatos ...`}
          openFilter={openFilter}
          onSearchQ={onSearchQ}
          onToggleFilter={onToggleFilter}
        />
      </S.SResourceHeader>
      {openFilter && <IdentitiesFilter onSearch={onSearchFilter} showInactives={showInactives} />}
      <IdentitiesTable
        {...props}
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        queryKey={queryKey}
        type={TableTypes.disabled}
      />
    </Box>
  )
}

export default RelationsIdentitiesList
