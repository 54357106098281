import { QueryKey, useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Identity, UpdateIdentities } from 'models/identities'
import { GET_IDENTITIES, GET_IDENTITIES_VERSIONS } from 'config/queries'
import { updateOneIdentity } from 'services/identities'

const useUpdateIdentity = (queryKey?: QueryKey) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const updateIdentity = useMutation({
    mutationFn: ({ id, ...payload }: UpdateIdentities) => updateOneIdentity(id, payload),
    onSuccess: () => {
      toast(t('Identidad actualizada'), { type: 'success' })
    },
    onMutate: async newValue => {
      await queryClient.cancelQueries({ queryKey: GET_IDENTITIES })
      const previous = queryClient.getQueryData<{ data: Identity[] }>(queryKey ?? [GET_IDENTITIES])
      const identity = previous?.data.find(o => o.id === newValue.id)

      if (identity) {
        identity.active = newValue.active
      }
      return { previous }
    },
    onError: (error, newValue, context) => {
      queryClient.setQueryData([GET_IDENTITIES], context?.previous)
      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [GET_IDENTITIES] })
      queryClient.invalidateQueries({ queryKey: [GET_IDENTITIES_VERSIONS] })
    },
  })

  return updateIdentity
}

export default useUpdateIdentity
