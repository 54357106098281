import { AxiosError } from 'axios'
import { useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { getCarparks } from 'services/locations'
import { GET_CARPARKS, GET_LOCATIONS } from 'config/queries'

interface UseGetcarparks {
  isFetching: boolean
  getCarparks: () => void
}

const useGetCarparks = (): UseGetcarparks => {
  const { t } = useTranslation()
  const queryKey = [GET_CARPARKS]
  const queryClient = useQueryClient()

  const { isFetching, refetch } = useQuery({
    queryKey,
    queryFn: () => getCarparks(),
    refetchOnMount: false,
    enabled: false,
    refetchInterval: 2,
    onError: (e: AxiosError<Record<string, string>>) => {
      if (e.response?.data?.description?.includes('Error calling mfl api')) {
        toast(t('Error al obtener los aparcamientos desde MFL'), { type: 'error' })
      } else {
        toast(t('No se han podido actualizar los aparcamientos'), { type: 'error' })
      }
    },
    onSuccess: () => {
      toast(t('Aparcamientos actualizados'), { type: 'success' })
      queryClient.invalidateQueries({ queryKey: [GET_LOCATIONS] })
    },
  })

  return { isFetching, getCarparks: refetch }
}

export default useGetCarparks
