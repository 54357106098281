import React from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'

import { LocationFilterFormData, locationFilterSchema, LocationsFilterFormFields } from 'validations/locations'
import BasicButton from 'components/atoms/basic-button'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import { LOCATION_TYPES } from 'config/constants'

import { FilterGridContainer } from './styled'

interface Props {
  onSearch: (search: LocationsFilterFormFields) => void
}

const defaultValues: LocationsFilterFormFields = {
  keyType: '',
  keys: '',
  name: '',
}

const LocationsFilter: React.FC<Props> = ({ onSearch }) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<LocationFilterFormData>({
    defaultValues,
    resolver: yupResolver(locationFilterSchema),
  })

  const onSubmit = (payload: LocationsFilterFormFields) => {
    onSearch(payload)
  }

  const handleReset = () => {
    onSearch(defaultValues)
    reset(defaultValues)
  }

  return (
    <FilterGridContainer container pr={1}>
      <Grid item md={4} pr={1}>
        <ControllerInputSelect
          control={control}
          name='keyType'
          error={errors.keyType}
          label={t('Tipo')}
          size='full'
          options={LOCATION_TYPES}
        />
      </Grid>
      <Grid item md={4} pr={1}>
        <ControllerInputText control={control} name='keys' error={errors.keys} label={t('Clave')} size='full' />
      </Grid>
      <Grid item md={4} pr={1}>
        {' '}
        <ControllerInputText control={control} name='name' error={errors.name} label={t('Nombre')} size='full' />
      </Grid>
      <Grid item md={12} display='flex' justifyContent='flex-end' flexDirection='row' gap={3}>
        <BasicButton handleClick={handleReset} text={t('Restablecer')} size='small' color='warning' />
        <BasicButton handleClick={handleSubmit(onSubmit)} text={t('Buscar')} size='small' />
      </Grid>
    </FilterGridContainer>
  )
}

export default LocationsFilter
