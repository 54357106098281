import PageContainer from 'components/atoms/page-container'
import IdentitiesCreateForm from 'components/organisms/identities/identities-create-form'

const IdentitiesCreate = (): JSX.Element => (
  <PageContainer>
    <IdentitiesCreateForm />
  </PageContainer>
)

export default IdentitiesCreate
