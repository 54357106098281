import React from 'react'

import { Control, Controller } from 'react-hook-form'
import { GridSelectionModel } from '@mui/x-data-grid'

import IdentitiesList from 'components/pages/identities/identities-list'
import { TableTypes } from 'config/constants'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
}

const ControllerIdentityList: React.FC<Props> = ({ control }) => {
  return (
    <Controller
      name='identityIds'
      control={control}
      render={({ field }) => (
        <IdentitiesList
          type={TableTypes.selection}
          showInactives
          selectionModel={field.value as GridSelectionModel}
          onSelectionModelChange={field.onChange}
        />
      )}
    />
  )
}

export default ControllerIdentityList
