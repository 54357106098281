import { useMutation, useQueryClient } from 'react-query'

import { UploadRelationIdentitiesCsv } from 'models/relations'
import { GET_RELATIONS, GET_LOCATIONS, GET_IDENTITIES } from 'config/queries'
import { uploadRelationIdentitiesCsv } from 'services/relations'

const useUploadRelationIdentitiesCsv = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ formData }: UploadRelationIdentitiesCsv) => uploadRelationIdentitiesCsv(formData),
    onSettled: () => {
      queryClient.removeQueries(GET_IDENTITIES)
      queryClient.removeQueries(GET_RELATIONS)
      queryClient.removeQueries(GET_LOCATIONS)
    },
  })
}

export default useUploadRelationIdentitiesCsv
