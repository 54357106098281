import dayjs, { Dayjs } from 'dayjs'

import { DateFormats } from 'config/constants'

interface Args {
  date?: string | Dayjs | null
  pattern?: string
}
export const dateFormatter = ({ date, pattern }: Args): string => {
  if (!date) return ''

  let template = pattern

  if (pattern === DateFormats.iso8601) {
    template = undefined
  } else if (!pattern) {
    template = DateFormats.full
  }

  return dayjs(date).format(template)
}
