import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'

import GroupsHistoricTable from 'components/organisms/groups/groups-historic-table'
import useGetGroupsVersions from 'hooks/queries/group/useGetGroupsVersions'
import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import usePagination from 'hooks/usePagination'
import useSort from 'hooks/useSort'

const GroupHistoric = (): JSX.Element => {
  const { id } = useParams()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()

  const { sortModel, setSortModel } = useSort()

  const { response, isLoading, count } = useGetGroupsVersions({ id, offset, limit: pageSize, sort: sortModel || 'id' })

  return (
    <Box>
      <GroupsHistoricTable
        id={id}
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
      />
      <FormButtonsContainer>
        <CancelButton />
      </FormButtonsContainer>
    </Box>
  )
}

export default GroupHistoric
