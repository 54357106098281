import React from 'react'

import Box from '@mui/material/Box'

import IdentitiesTable from 'components/organisms/identities/identities-table'
import { TableTypes } from 'config/constants'
import usePagination from 'hooks/usePagination'
import useGetIdentities from 'hooks/queries/identity/useGetIdentities'

interface RelationsSelectedIdentitiesProps {
  identitiesIds: number[]
}

const RelationsSelectedIdentities: React.FC<RelationsSelectedIdentitiesProps> = ({ identitiesIds }) => {
  const { pageSize, offset, page, setPageSize, setPage } = usePagination()
  const slicedIds = identitiesIds?.sort((a, b) => a - b).slice(offset, offset + pageSize)

  const { response, isLoading, queryKey } = useGetIdentities({
    offset: 0,
    limit: pageSize,
    filters: { ids: slicedIds, showInactive: true, showActiveOnly: undefined },
    sort: 'id',
  })

  return (
    <Box width='100%'>
      <IdentitiesTable
        rows={response}
        count={identitiesIds.length}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={() => undefined}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        queryKey={queryKey}
        type={TableTypes.disabled}
        isSortable={false}
      />
    </Box>
  )
}

export default RelationsSelectedIdentities
