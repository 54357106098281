import { useQuery } from 'react-query'

import { getIdentities } from 'services/identities'
import { Identity, UseGetIdentities } from 'models/identities'
import { GET_IDENTITIES } from 'config/queries'
import { IdentitiesFilterFormFileds } from 'validations/identities'

interface Args {
  limit: number
  offset: number
  filters: IdentitiesFilterFormFileds
  sort: string
}

const useGetIdentities = ({ limit, offset, filters, sort }: Args): UseGetIdentities => {
  const queryKey = [GET_IDENTITIES, limit, offset, filters, sort]
  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getIdentities({ limit, offset, filters, sort }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Array<Identity> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetIdentities
