import React, { useEffect, useMemo, useRef, useState } from 'react'

import { FieldErrors } from 'react-hook-form'
import _debounce from 'lodash.debounce'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { t } from 'i18next'

import useGetLocations from 'hooks/queries/locations/useGetLocations'
import { GroupFormFields } from 'validations/groups'
import { Location } from 'models/locations'
import LocationSearchCell from 'components/atoms/location-search-cell'

import { LocationCellContainer } from './styled'

interface State {
  pageSize: number
  offset: number
  page: number
  search: string
  preloading: boolean
}

interface Args {
  onChange: (location: Location | null) => void
  name: string
  error?: FieldErrors<GroupFormFields>
  label?: string
  defaultValue?: Location
  disabled?: boolean
  autoCompleteProps?: Record<string, unknown>
  renderValue?: (option: Location) => string
}

const LocationsSearcher: React.FC<Args> = ({
  onChange,
  error,
  name,
  label,
  defaultValue,
  disabled,
  autoCompleteProps,
  renderValue,
}) => {
  const [search, setSearch] = useState<State['search']>('')
  const [preloading, setPreloading] = useState<State['preloading']>(false)

  const { isLoading, response } = useGetLocations({ pageSize: 25, offset: 0, filters: { name: search }, sort: 'id' })
  const options = useMemo(() => {
    if (!defaultValue) {
      return response
    }

    if (!response.length) {
      return [defaultValue]
    }

    return response.some(({ id }) => id === defaultValue.id) ? response : [...response, defaultValue]
  }, [response, defaultValue])

  const debouncer = useRef(
    _debounce((data: string) => {
      setSearch(data)
      setPreloading(false)
    }, 300)
  ).current

  useEffect(() => {
    return () => {
      debouncer.cancel()
    }
  }, [debouncer])

  const handleTextInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreloading(true)
    debouncer(event.target.value)
  }

  const handleSelectionOnChange = (e: React.SyntheticEvent<Element, Event>, data: Location | null) => {
    onChange(data)
  }

  return (
    <Autocomplete
      {...autoCompleteProps}
      id='locations-autocomplete'
      sx={{ width: '100%' }}
      options={options}
      loading={isLoading || preloading}
      getOptionLabel={renderValue ?? (option => option.name)}
      disabled={disabled}
      onChange={handleSelectionOnChange}
      isOptionEqualToValue={(option, value) => option.id === value.id && option.key === value.key}
      defaultValue={defaultValue}
      renderOption={(props, location) => (
        <LocationCellContainer {...props} key={`location-li-cell-${location.id}`}>
          <LocationSearchCell data={location} />
        </LocationCellContainer>
      )}
      renderInput={params => (
        <TextField
          {...params}
          onChange={handleTextInputOnChange}
          error={!!error?.locations}
          helperText={error?.locations?.message}
          label={label ?? t('Inserte una localización') + ' *'}
          name={name}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading || preloading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default LocationsSearcher
