import React, { useCallback } from 'react'

import { GridEnrichedColDef, GridSelectionModel } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import routes from 'config/routes'
import BasicTable from 'components/molecules/basic-table'
import ToggleGridActionCellItem from 'components/atoms/toggle-grid-action-cell-item'
import HistoricGridActionCellItem from 'components/atoms/historic-grid-action-cell-item'
import EditGridActionCellItem from 'components/atoms/edit-grid-action-cell-item'
import { Group, GroupsQueryKey } from 'models/groups'
import { RowHeight, TableColumnWidth, TableTypes } from 'config/constants'
import LocationGridActionCellItem from 'components/atoms/location-grid-action-cell-item'
import useUpdateGroup from 'hooks/queries/group/useUpdateGroup'
import useGroupsPermissions from 'hooks/permissions/useGroupsPermissions'
import PeriodDateCell from 'components/atoms/period-date-cell'
import ShowGridActionCellItem from 'components/atoms/show-grid-action-cell-item'
import KeysCell from 'components/atoms/keys-cell'
import TextCell from 'components/atoms/text-cell'
import ChipCell from 'components/atoms/chip-cell'
import { periodDateHandler } from 'utils/periodDateHandler'

interface Props {
  rows: Group[]
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageSizeChange: (pageSize: number) => void
  onPageChange: (page: number) => void
  queryKey?: GroupsQueryKey
  type?: TableTypes
  onSelectionModelChange?: (selectionModel: GridSelectionModel) => void
  selectionModel?: GridSelectionModel
}

const GroupsTable: React.FC<Props> = ({ queryKey, type, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { canWrite } = useGroupsPermissions()
  const updateGroup = useUpdateGroup(queryKey)

  const navigateToEdit = useCallback((id: string) => navigate(routes.groupsEdit.replace(':id', id)), [navigate])
  const navigateToShow = useCallback(
    (id: string) => !type && navigate(routes.groupsShow.replace(':id', id)),
    [navigate, type]
  )
  const navigateToHistoric = useCallback((id: string) => navigate(routes.groupsHistoric.replace(':id', id)), [navigate])

  const handleToggleGroupActive = useCallback(
    ({ active, domain, periodDate, ...rows }: Group) => {
      updateGroup.mutate({
        ...rows,
        active: !active,
        domain: domain.name,
        periodDate: periodDateHandler(periodDate),
      })
    },
    [updateGroup]
  )

  const getTableActions = useCallback(
    ({ row }: { row: Group }) => {
      const actions = [
        <ShowGridActionCellItem key={`${row.id}-show`} onClick={() => navigateToShow(row.id)} />,
        <HistoricGridActionCellItem key={`${row.id}-historic`} onClick={() => navigateToHistoric(row.id)} />,
      ]

      if (canWrite) {
        actions.unshift(
          <ToggleGridActionCellItem
            checked={row.active}
            key={`${row.id}-toggle`}
            onClick={() => handleToggleGroupActive(row)}
          />,
          <EditGridActionCellItem key={`${row.id}-edit`} onClick={() => navigateToEdit(row.id)} />
        )
      }

      return actions
    },
    [canWrite, handleToggleGroupActive, navigateToEdit, navigateToHistoric, navigateToShow]
  )

  const columns: GridEnrichedColDef[] = [
    { field: 'id', headerName: '', flex: TableColumnWidth.xs, headerAlign: 'center', align: 'center' },
    {
      field: 'keys',
      headerName: `${t('Claves')}`,
      flex: TableColumnWidth.large,
      sortable: false,
      renderCell: ({ row }: { row: Group }) => <KeysCell keys={row.keys} />,
    },
    {
      field: 'name',
      headerName: `${t('Nombre')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: Group }) => <TextCell text={row.name} />,
    },
    {
      field: 'domain',
      headerName: `${t('Dominio')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: Group }) => <ChipCell label={row?.domain?.name} />,
    },
    {
      field: 'date',
      headerName: `${t('Validez')}`,
      flex: TableColumnWidth.large,
      sortable: false,
      renderCell: ({ row }: { row: Group }) => <PeriodDateCell periodDate={row.periodDate} />,
    },
    {
      field: 'locations',
      type: 'actions',
      headerName: '',
      flex: TableColumnWidth.xs,
      sortable: false,
      getActions: ({ row }: { row: Group }) => [
        <LocationGridActionCellItem key={`${row.id}-locations`} locations={row.locations} />,
      ],
    },
    {
      field: 'actions',
      type: 'actions',
      flex: TableColumnWidth.large,
      getActions: getTableActions,
    },
  ]

  const selectionActions: GridEnrichedColDef = {
    field: 'actions',
    type: 'actions',
    flex: TableColumnWidth.small,
    getActions: ({ row }: { row: Group }) => [
      <ToggleGridActionCellItem checked={row.active} key={`${row.id}-toggle`} onClick={() => {}} />,
    ],
  }

  const selectionColums: GridEnrichedColDef[] = columns.map((column: GridEnrichedColDef) =>
    column.field !== 'actions' ? column : selectionActions
  )

  return (
    <BasicTable
      {...props}
      rowHeight={RowHeight.large}
      type={type}
      columns={type ? selectionColums : columns}
      onRowClick={({ row }) => navigateToShow(row.id)}
    />
  )
}

export default GroupsTable
