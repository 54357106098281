import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'

import IdentitiesHistoricTable from 'components/organisms/identities/identities-historic-table'
import useGetIdentitiesVersions from 'hooks/queries/identity/useGetIdentitiesVersions'
import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import usePagination from 'hooks/usePagination'
import useSort from 'hooks/useSort'

const IdentitiesHistoric = (): JSX.Element => {
  const { id } = useParams()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()

  const { sortModel, setSortModel } = useSort()

  const { response, isLoading, count } = useGetIdentitiesVersions({
    id,
    offset,
    limit: pageSize,
    sort: sortModel || 'id',
  })

  return (
    <Box>
      <IdentitiesHistoricTable
        id={id}
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
      />
      <FormButtonsContainer>
        <CancelButton />
      </FormButtonsContainer>
    </Box>
  )
}

export default IdentitiesHistoric
