import 'react-toastify/dist/ReactToastify.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { ToastContainer } from 'react-toastify'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import * as Sentry from '@sentry/react'

import { SENTRY_DSN } from 'config/constants'
import queryClient from 'config/react-query'
import 'config/i18n'
import { GlobalStyle, getTheme } from 'config/theme'
import AuthProvider, { useAuth } from 'providers/auth'
import 'assets/css/overrides.css'
import 'assets/css/fonts.css'
import 'assets/css/toastify.css'

import Routes from './routes'

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 0.2,
})

const AppContainer = () => {
  const { language } = useAuth()

  return (
    <ThemeProvider theme={getTheme(language)}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
        <CssBaseline />
        <GlobalStyle />
        <Routes />
        <ToastContainer hideProgressBar position='top-right' theme='colored' />
      </LocalizationProvider>
    </ThemeProvider>
  )
}

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <AppContainer />
    </AuthProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)

export default App
