import { useState, useCallback } from 'react'

import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ModalConfirm from 'components/molecules/modal-confirm'
import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import SubmitButton from 'components/atoms/submit-button'
import routes from 'config/routes'
import { ApplicationFormFields, ApplicationsFormSchema, applicationsSchema } from 'validations/applications'
import useCreateApplication from 'hooks/queries/application/useCreateApplication'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import { APPLICATIONS_ROLES } from 'config/constants'
import useFormErrorScroll from 'hooks/useFormErrorScroll'

import { ApplicationGridItem, ApplicationGridContainer } from './styled'

const defaultValues: ApplicationFormFields = {
  name: '',
  role: '',
  active: true,
}

const ApplicationsCreateForm = (): JSX.Element => {
  const { t } = useTranslation()
  const [showTokenModal, setShowTokenModal] = useState<string>()

  const createApplication = useCreateApplication()
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ApplicationsFormSchema>({
    defaultValues,
    resolver: yupResolver(applicationsSchema),
  })

  useFormErrorScroll(errors)

  const onConfirmTokenModal = useCallback(() => {
    navigate(routes.applicationsList)
  }, [navigate])

  const onSubmit = useCallback(
    async (payload: ApplicationFormFields) => {
      const result = await createApplication.mutateAsync(payload)
      const token = `${result?.data?.uniqueTokenId}:${result?.data?.token}`
      navigator.clipboard.writeText(token)
      setShowTokenModal(token)
    },
    [createApplication]
  )

  return (
    <>
      <Grid component='form' onSubmit={handleSubmit(onSubmit)} data-testid='applications-create-form'>
        <ApplicationGridContainer container spacing={2}>
          <ApplicationGridItem item md={6} xs={12}>
            <ControllerInputSelect
              mandatory
              control={control}
              name='role'
              error={errors.role}
              label={t('Rol')}
              size='full'
              options={APPLICATIONS_ROLES}
            />
            <ControllerInputText
              mandatory
              control={control}
              name='name'
              error={errors.name}
              label={t('Nombre')}
              size='full'
            />
          </ApplicationGridItem>
        </ApplicationGridContainer>
        <FormButtonsContainer>
          <CancelButton to={routes.applicationsList} />
          <SubmitButton />
        </FormButtonsContainer>
      </Grid>
      <ModalConfirm
        open={!!showTokenModal}
        onCancel={onConfirmTokenModal}
        onConfirm={onConfirmTokenModal}
        title={t('Aplicación creada')}
        message={t(
          // eslint-disable-next-line max-len
          'Se ha generado un token para esta aplicación y se ha copiado a su portapapeles. Guárdelo ya que no se volverá a mostrar. Puede volver a generarlo desde la edición de la aplicación.'
        )}
        confirmLabel={t('confirmar')}
        hideCancelButton
      />
    </>
  )
}

export default ApplicationsCreateForm
