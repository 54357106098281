import { Roles } from 'models/auth.d'
import { useAuth } from 'providers/auth'

export enum AdministrationSections {
  users = 'users',
  applications = 'applications',
  events = 'events',
  locations = 'locations',
}

const useAdministrationPermissions = (section: AdministrationSections) => {
  const { user } = useAuth()
  const role = user?.role || ''

  if (section === AdministrationSections.users) {
    const canWrite = role !== Roles['SUPER-ADMIN'] && role !== Roles.ADMIN ? false : true
    const canRead = role !== Roles['SUPER-ADMIN'] && role !== Roles.ADMIN ? false : true
    return { canWrite, canRead }
  } else if (section === AdministrationSections.applications) {
    const canWrite = role !== Roles['SUPER-ADMIN'] ? false : true
    const canRead = role !== Roles['SUPER-ADMIN'] && role !== Roles.ADMIN ? false : true
    return { canWrite, canRead }
  } else if (section === AdministrationSections.events) {
    const canWrite = false
    const canRead = true
    return { canWrite, canRead }
  } else if (section === AdministrationSections.locations) {
    const canWrite =
      role !== Roles['SUPER-ADMIN'] && role !== Roles.ADMIN && role !== Roles['ADMIN-LOCATION'] ? false : true
    const canRead = true
    return { canWrite, canRead }
  } else {
    return { canWrite: false, canRead: false }
  }
}

export default useAdministrationPermissions
