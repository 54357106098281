import { Typography } from '@mui/material'

import { FontFamily, FontSizes, FontStyles } from 'config/constants'

import * as S from './styled'

const AppBarAppName = (): JSX.Element => (
  <S.AppBarAppNameContainer>
    <Typography fontSize={FontSizes.xl} fontStyle={FontStyles.italic} fontFamily={FontFamily.bold}>
      API Groups
    </Typography>
    <Typography fontSize={FontSizes.base} fontStyle={FontStyles.italic} fontFamily={FontFamily.bold}>
      Console
    </Typography>
  </S.AppBarAppNameContainer>
)

export default AppBarAppName
