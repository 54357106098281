import React from 'react'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, IconButton } from '@mui/material'

import { Keys } from 'models/keys'

import KeysChip from '../keys-chip'
import BasicTooltip from '../basic-tooltip'

interface Args {
  keys: Keys[]
}

const KeysTooltip: React.FC<Args> = ({ keys }) => {
  return (
    <BasicTooltip
      title={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          {keys.map((location, index) => (
            <KeysChip
              key={`keys-tooltip-index-${index}-keyType-${location.keyType}-key-${location.key}`}
              keys={location}
            />
          ))}
        </Box>
      }
    >
      <IconButton onClick={event => event.stopPropagation()}>
        <MoreHorizIcon />
      </IconButton>
    </BasicTooltip>
  )
}

export default KeysTooltip
