import React from 'react'

import { useTranslation } from 'react-i18next'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { RelationTabsValue } from 'config/constants'

import { TabsContainer } from './styled'
interface Props {
  onTabsChange: (tab: RelationTabsValue) => void
  value: number
  historic?: boolean
}

const RelationTabs: React.FC<Props> = ({ onTabsChange, value, historic }) => {
  const { t } = useTranslation()

  const tabsProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleChange = (event: React.SyntheticEvent, tab: RelationTabsValue) => {
    onTabsChange(tab)
  }

  return (
    <TabsContainer>
      <Tabs value={value} onChange={handleChange} aria-label='relations-tabs'>
        {!historic && (
          <Tab
            iconPosition='end'
            label={`1. ${t('Identidades')}`}
            value={RelationTabsValue.identities}
            {...tabsProps(RelationTabsValue.identities)}
          />
        )}
        {!historic && (
          <Tab
            iconPosition='end'
            label={`2. ${t('Grupos')}`}
            value={RelationTabsValue.groups}
            {...tabsProps(RelationTabsValue.groups)}
          />
        )}
        <Tab
          iconPosition='end'
          label={`${historic ? '1' : '3'}. ${t('Configuración')}`}
          value={RelationTabsValue.config}
          {...tabsProps(RelationTabsValue.config)}
        />
        <Tab
          label={`${historic ? '2' : '4'}. ${t('Resumen')}`}
          value={RelationTabsValue.resume}
          {...tabsProps(RelationTabsValue.resume)}
        />
      </Tabs>
    </TabsContainer>
  )
}

export default RelationTabs
