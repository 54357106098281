import React from 'react'

import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Keys } from 'models/keys'
import { FontFamily, FontSizes, FontStyles } from 'config/constants'
import { keysFormatter, locationKeysFormatter } from 'utils/keysFormatter'

import KeysTooltip from '../keys-tooltip'

import { KeysContainer, KeysMasterContainer } from './styled'

interface Props {
  keys: Keys[]
  locationKey?: boolean
}

const KeysCell: React.FC<Props> = ({ keys, locationKey }) => {
  const { t } = useTranslation()

  const keyType = keys?.[0]?.keyType
  const key = keys?.[0]?.key

  return (
    <KeysMasterContainer>
      {!keys?.length ? (
        <Typography fontSize={FontSizes.base} noWrap fontStyle={FontStyles.italic} fontFamily={FontFamily.light}>
          {t('Sin resultados')}
        </Typography>
      ) : (
        <KeysContainer>
          <Typography fontSize={FontSizes.base} noWrap fontStyle={FontStyles.italic} fontFamily={FontFamily.light}>
            {!locationKey ? keysFormatter(keyType) : locationKeysFormatter(keyType)}
          </Typography>
          <Typography fontSize={FontSizes.base} noWrap>
            {key}
          </Typography>
        </KeysContainer>
      )}

      {keys?.length > 1 && <KeysTooltip keys={keys} />}
    </KeysMasterContainer>
  )
}

export default KeysCell
