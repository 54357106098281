import * as React from 'react'

import FormHelperText from '@mui/material/FormHelperText/FormHelperText'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import Select from '@mui/material/Select/Select'
import { FormControl, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { inputsWidth } from 'config/constants'
import { Sizes } from 'models/sizes'

import { StyledBox } from './styled'

interface Option {
  value: string | number
  text: string
}
interface Props {
  field: object
  multiple?: boolean
  label: string
  options: Array<Option>
  helperText?: string
  error?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
  mandatory?: boolean
  size: Sizes
  disabled?: boolean
  renderValue?: (selection: string | string[]) => React.ReactNode
}

const BasicInputSelect: React.FC<Props> = ({
  field,
  label,
  options,
  helperText,
  variant,
  mandatory,
  size,
  disabled,
  multiple,
  renderValue,
  error,
}) => {
  const { t } = useTranslation()
  const labelText = !mandatory ? label : `${label} *`
  return (
    <StyledBox width={inputsWidth[size]}>
      <FormControl error={error}>
        <InputLabel id={`select-${label}`} disabled={disabled}>
          {labelText}
        </InputLabel>
        <Select
          {...field}
          multiple={multiple}
          renderValue={renderValue}
          variant={variant}
          disabled={disabled}
          labelId={`select-${label}`}
          label={labelText}
        >
          {options?.map?.(({ value, text }) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
          {!multiple && (
            <MenuItem key={'no-selection-value'} value={''}>
              <Typography fontStyle='italic' color='gray'>
                {t('Sin selección')}
              </Typography>
            </MenuItem>
          )}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </StyledBox>
  )
}

export default BasicInputSelect
