import { Navigate, useLocation } from 'react-router-dom'

import useIdentitiesPermissions from 'hooks/permissions/useIdentitiesPermissions'
import useGroupsPermissions from 'hooks/permissions/useGroupsPermissions'
import useRelationsPermissions from 'hooks/permissions/useRelationsPermissions'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/useAdministrationPermissions'
import routes from 'config/routes'

export const RequireIdentitiesRolesPermissions = ({ children }: { children: JSX.Element }) => {
  const { canRead, canWrite } = useIdentitiesPermissions()
  const location = useLocation()

  const isWriteRoute = location?.pathname.includes('/new') || location?.pathname.includes('/edit')
  if (!canRead || (!canWrite && isWriteRoute)) {
    return <Navigate to={routes.root} state={{ from: location }} replace />
  }

  return children
}

export const RequireGroupsRolesPermissions = ({ children }: { children: JSX.Element }) => {
  const { canRead, canWrite } = useGroupsPermissions()
  const location = useLocation()

  const isWriteRoute = location?.pathname.includes('/new') || location?.pathname.includes('/edit')
  if (!canRead || (!canWrite && isWriteRoute)) {
    return <Navigate to={routes.root} state={{ from: location }} replace />
  }

  return children
}

export const RequireRelationsRolesPermissions = ({ children }: { children: JSX.Element }) => {
  const { canRead, canWrite } = useRelationsPermissions()
  const location = useLocation()

  const isWriteRoute = location?.pathname.includes('/new') || location?.pathname.includes('/edit')
  if (!canRead || (!canWrite && isWriteRoute)) {
    return <Navigate to={routes.root} state={{ from: location }} replace />
  }

  return children
}

export const RequireAdministrationRolesPermissions = ({ children }: { children: JSX.Element }) => {
  const { canRead: usersCanRead, canWrite: usersCanWrite } = useAdministrationPermissions(AdministrationSections.users)
  const { canRead: applicationsCanRead, canWrite: applicationsCanWrite } = useAdministrationPermissions(
    AdministrationSections.applications
  )
  const { canRead: eventsCanRead, canWrite: eventsCanWrite } = useAdministrationPermissions(
    AdministrationSections.events
  )
  const { canRead: locationsCanRead, canWrite: locationsCanWrite } = useAdministrationPermissions(
    AdministrationSections.locations
  )
  const location = useLocation()

  // CHECK ROLE CAN READ DEFAULT SECTION
  if (location?.pathname === routes.administration) {
    if (usersCanRead) {
      return children
    } else if (eventsCanRead) {
      return <Navigate to={routes.eventsList} state={{ from: location }} replace />
    } else {
      return <Navigate to={routes.root} state={{ from: location }} replace />
    }
  }

  // CHECK ROLE CAN READ SPECIFIC SECTION
  let canRead = false
  let canWrite = false
  if (location?.pathname.includes(routes.usersList)) {
    canRead = usersCanRead
    canWrite = usersCanWrite
  } else if (location?.pathname.includes(routes.applicationsList)) {
    canRead = applicationsCanRead
    canWrite = applicationsCanWrite
  } else if (location?.pathname.includes(routes.eventsList)) {
    canRead = eventsCanRead
    canWrite = eventsCanWrite
  } else if (location?.pathname.includes(routes.locationsList)) {
    canRead = locationsCanRead
    canWrite = locationsCanWrite
  }

  const isWriteRoute = location?.pathname.includes('/new') || location?.pathname.includes('/edit')
  if (!canRead || (!canWrite && isWriteRoute)) {
    return <Navigate to={routes.login} state={{ from: location }} replace />
  }

  return children
}
