import { QueryKey, useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { updateOneUser } from 'services/user'
import { UpdateUser, User } from 'models/users'
import { GET_USERS } from 'config/queries'

const useUpdateUser = (queryKey?: QueryKey) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const updateUser = useMutation({
    mutationFn: ({ id, ...payload }: UpdateUser) => updateOneUser(id, payload),
    onSuccess: () => {
      toast(t('Usuario actualizado'), { type: 'success' })
    },
    onMutate: async newValue => {
      await queryClient.cancelQueries({ queryKey: GET_USERS })
      const previous = queryClient.getQueryData<{ data: User[] }>(queryKey ?? [GET_USERS])
      const user = previous?.data.find(o => o.id === newValue.id)

      if (user) {
        user.active = newValue.active
      }
      return { previous }
    },
    onError: (error, newValue, context) => {
      queryClient.setQueryData([GET_USERS], context?.previous)
      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [GET_USERS] })
    },
  })

  return updateUser
}

export default useUpdateUser
