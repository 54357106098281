import { Box } from '@mui/system'
import { t } from 'i18next'

import CountChip from 'components/atoms/count-chip'
import NewResourceButton from 'components/atoms/new-resource-button'
import ResourceHeader from 'components/atoms/resource-header'
import routes from 'config/routes'
import ApplicationsTable from 'components/organisms/applications/applications-table'
import useGetApplications from 'hooks/queries/application/useGetApplications'
import { applicationFilterDefaultValues } from 'validations/applications'
import ApplicationsFilter from 'components/organisms/applications/applications-filter'
import ResourceFilter from 'components/molecules/resource-filter'
import usePagination from 'hooks/usePagination'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/useAdministrationPermissions'
import useFilters from 'hooks/useFilters'

const ApplicationsList = (): JSX.Element => {
  const { pageSize, offset, page, setPageSize, setPage } = usePagination()
  const { canWrite } = useAdministrationPermissions(AdministrationSections.applications)
  const { sortModel, badge, openFilter, filters, setSortModel, onSearchQ, onSearchFilter, onToggleFilter } =
    useFilters(applicationFilterDefaultValues)

  const { response, isLoading, count, queryKey } = useGetApplications({
    pageSize,
    offset,
    filters: { ...filters, showInactive: !filters.showActiveOnly, showActiveOnly: undefined },
    sort: sortModel || 'id',
  })

  const handleOnSortModelChange = (sortingString: string) => {
    const transformedSortingString = sortingString.includes('seniority')
      ? sortingString.replace('seniority', 'createDate')
      : sortingString
    setSortModel(transformedSortingString)
  }

  return (
    <Box>
      <ResourceHeader>
        <CountChip label={t('aplicaciones')} labelSingular={t('localización')} count={count} isLoading={isLoading} />
        <ResourceFilter
          badge={badge}
          label={t`Nombre, fecha de creación ...`}
          openFilter={openFilter}
          onSearchQ={onSearchQ}
          onToggleFilter={onToggleFilter}
        />
        {canWrite && <NewResourceButton label={t('Nueva aplicación')} to={routes.applicationsCreate} />}
      </ResourceHeader>
      {openFilter && <ApplicationsFilter onSearch={onSearchFilter} />}
      <ApplicationsTable
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={handleOnSortModelChange}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        queryKey={queryKey}
      />
    </Box>
  )
}

export default ApplicationsList
