import { useMemo } from 'react'

import { useQuery } from 'react-query'

import { GET_ONE_RELATION_IDENTITIES } from 'config/queries'
import { Identity } from 'models/identities'
import { UseGetOneRelationIdentitiesWithFilters } from 'models/relations'
import { getIdentities } from 'services/identities'
import { getOneRelationIdentities } from 'services/relations'
import { IdentitiesFilterFormFileds } from 'validations/identities'

interface UseGetOneRelationIdentitiesWithFiltersProps {
  relationId?: string
  identitiesIds: number[]
  limit: number
  offset: number
  filters: IdentitiesFilterFormFileds
  sort: string
}

const useGetOneRelationIdentitiesWithFilters = ({
  relationId,
  identitiesIds,
  limit,
  offset,
  filters,
  sort,
}: UseGetOneRelationIdentitiesWithFiltersProps): UseGetOneRelationIdentitiesWithFilters => {
  const queryKey = [GET_ONE_RELATION_IDENTITIES, relationId, limit, offset, filters, sort]

  const areFiltersActive = useMemo(
    () =>
      Object.entries(filters)
        .filter(([key]) => key !== 'showActiveOnly' && key !== 'showInactive')
        .some(([, value]) => value !== ''),
    [filters]
  )

  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () =>
      areFiltersActive
        ? getIdentities({ limit, offset, filters: { ...filters, ids: identitiesIds }, sort })
        : getOneRelationIdentities({ id: relationId, limit, offset, sort }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Array<Identity> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetOneRelationIdentitiesWithFilters
