import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'

const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  margin: 2rem 0;
` as typeof Grid

export default GridItem
/**
 * @todo traer aqui el componente
 */
