import React, { useMemo } from 'react'

import dayjs from 'dayjs'
import { Control, FieldErrors } from 'react-hook-form'
import { CircularProgress, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerCustomInputKeys from 'components/molecules/controller-custominput-keys'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import ControllerCustomInputLocations from 'components/molecules/controller-custominput-locations'
import ControllerCustomInputMetadata from 'components/molecules/controller-custominput-metadata'
import ControllerCustomInputPeriodTime from 'components/molecules/controller-custominput-periodtime'
import ControllerSwitch from 'components/molecules/controller-switch'
import BasicButton from 'components/atoms/basic-button'
import FormTitle from 'components/atoms/form-title'
import FormSubtitle from 'components/atoms/form-subtitle'
import { MIN_DATE_ADD } from 'config/constants'
import routes from 'config/routes'
import useGetDomains from 'hooks/queries/domain/useGetDomains'
import { Domains } from 'models/domains'
import { Relation } from 'models/relations'
import { isBeforeNow } from 'utils/dateUtils'
import { RelationFormFileds } from 'validations/relations'

import { StyledContainer, GridItem } from './styled'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  isLoading?: boolean
  disabled?: boolean
  isLocationsActive: boolean
  errors: FieldErrors<RelationFormFileds>
  historic?: boolean
  relationId?: string
  created?: boolean
  response?: Relation
}

const ControllerRelationConfig: React.FC<Props> = ({
  isLoading,
  control,
  disabled,
  isLocationsActive,
  errors,
  historic,
  relationId,
  created,
  response,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { response: domainsResponse } = useGetDomains()

  const domains = useMemo(
    () => domainsResponse?.map?.(({ name: domain }: Domains) => ({ value: domain, text: t(domain) })),
    [domainsResponse, t]
  )
  const navigateToShow = () => relationId && navigate(routes.relationsHistoric.replace(':id', relationId))

  if (isLoading) {
    return (
      <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Grid>
    )
  }

  return (
    <Grid container spacing={1}>
      <GridItem item xs={12}>
        <FormTitle text={t('Información')} />
      </GridItem>
      <GridItem item md={6} xs={12}>
        <ControllerInputSelect
          mandatory
          control={control}
          name='domain'
          error={errors.domain}
          label={t('Dominio')}
          size='full'
          options={domains}
          disabled={disabled}
        />
        <ControllerCustomInputKeys control={control} error={errors} disabled={disabled} />
        <ControllerInputText
          mandatory
          control={control}
          name='name'
          error={errors.name}
          label={t('Nombre/Descripción')}
          size='full'
          disabled={disabled}
        />
      </GridItem>

      {!created && (
        <GridItem item md={6} xs={12}>
          {!historic && (
            <ControllerSwitch label={t('Activo')} name='active' variant='full' control={control} disabled={disabled} />
          )}
          <StyledContainer direction='row' gap='2rem'>
            <ControllerInputText
              mandatory
              control={control}
              name='version'
              error={errors.version}
              label={t('Versión')}
              size={!historic ? 'medium' : 'full'}
              disabled
            />
            {!historic && (
              <BasicButton type='button' text={t('Histórico')} size='medium' handleClick={navigateToShow} />
            )}
          </StyledContainer>
          <ControllerInputDateTimePicker
            mandatory
            label={t('Fecha de creación')}
            name='createDate'
            control={control}
            error={errors.createDate}
            size='full'
            disabled
          />
          <ControllerInputDateTimePicker
            mandatory
            label={t('Fecha de modificación')}
            name='updateDate'
            control={control}
            error={errors.updateDate}
            size='full'
            disabled
          />
        </GridItem>
      )}
      <GridItem item xs={12}>
        <FormTitle text={t('Fechas de vigencia')} />
        <StyledContainer direction='row'>
          <ControllerInputDateTimePicker
            mandatory
            label={t('Desde')}
            name='periodDate.from'
            control={control}
            error={errors.periodDate?.from}
            size='full'
            disabled={disabled || !isBeforeNow(response?.periodDate?.from)}
            minDate={dayjs().add(MIN_DATE_ADD[0], MIN_DATE_ADD[1])}
          />
          <ControllerInputDateTimePicker
            label={t('Hasta')}
            name='periodDate.to'
            control={control}
            error={errors.periodDate?.to}
            size='full'
            disabled={disabled || !isBeforeNow(response?.periodDate?.to)}
            minDate={dayjs(response?.periodDate?.from).add(1, 'minute')}
          />
        </StyledContainer>
      </GridItem>
      <GridItem container item xs={12}>
        <FormTitle text={t('Localizaciones')} />
        <ControllerSwitch
          label={t('Activar localizaciones')}
          name='locationRestriction'
          variant='full'
          control={control}
          disabled={disabled}
          autoHeight
        />
        {isLocationsActive && <ControllerCustomInputLocations control={control} error={errors} disabled={disabled} />}
      </GridItem>
      <GridItem container item xs={12}>
        <FormTitle text={t('Metadatos')} />
        <ControllerCustomInputMetadata control={control} error={errors} disabled={disabled} />
      </GridItem>
      <GridItem container item xs={12}>
        <FormTitle text={t('Periodos de tiempo')} />
        <FormSubtitle text={t('Si no especifica valor alguno aplica las 24h')} />
        <ControllerCustomInputPeriodTime control={control} error={errors} disabled={disabled} />
      </GridItem>
    </Grid>
  )
}

export default ControllerRelationConfig
