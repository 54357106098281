import React from 'react'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import AddCircle from '@mui/icons-material/AddCircle'

import { FontSizes } from 'config/constants'

interface Props {
  type?: 'button' | 'submit' | 'reset'
  variant?: 'text' | 'contained' | 'outlined'
  handleClick?: React.MouseEventHandler<HTMLButtonElement>
  text: string
  color?: 'primary' | 'error' | 'secondary' | 'success' | 'info' | 'warning' | 'inherit'
  disabled?: boolean
  loading?: boolean
}

const AddButton: React.FC<Props> = ({
  type = 'button',
  variant = 'contained',
  text,
  color = 'primary',
  handleClick,
  disabled,
}) => {
  return (
    <Button
      onClick={handleClick}
      color={color}
      type={type}
      variant={variant}
      startIcon={<AddCircle />}
      disabled={disabled}
    >
      <Typography color='white' fontSize={FontSizes.base}>
        {text}
      </Typography>
    </Button>
  )
}

export default AddButton
