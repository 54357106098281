import { useQuery } from 'react-query'

import { getEvents } from 'services/events'
import { Event, UseGetEvents } from 'models/events'
import { GET_EVENTS } from 'config/queries'
import { EventsFilterFormFields } from 'validations/events'

interface Args {
  limit: number
  offset: number
  filters: EventsFilterFormFields
  sort: string
}

const useGetEvents = ({ limit, offset, filters, sort }: Args): UseGetEvents => {
  const queryKey = [GET_EVENTS, limit, offset, filters, sort]
  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getEvents({ limit, offset, filters, sort }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Array<Event> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetEvents
