import React from 'react'

import { Chip } from '@mui/material'

import { DayList } from 'models/dates'

interface Props {
  data: DayList
}

const DaysChip: React.FC<Props> = ({ data }) => {
  return (
    <Chip
      sx={{ width: 30, height: 20, fontSize: 12, margin: 0.1 }}
      variant='outlined'
      label={data?.iso}
      className={data.value}
      size='small'
    />
  )
}

export default DaysChip
