import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_GROUPS, GET_LOCATIONS } from 'config/queries'
import { createGroup } from 'services/groups'
import { CreateGroups } from 'models/groups'

const useCreateGroup = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const createGroupMutation = useMutation({
    mutationFn: (payload: CreateGroups) => createGroup(payload),
    onSuccess: () => {
      toast(t('Grupo creado'), { type: 'success' })
      queryClient.invalidateQueries({ queryKey: [GET_GROUPS] })
      queryClient.invalidateQueries({ queryKey: [GET_LOCATIONS] })
    },
    onError: () => {
      toast(t('Ha ocurrido un error'), { type: 'error' })
      queryClient.invalidateQueries({ queryKey: [GET_GROUPS] })
    },
  })

  return createGroupMutation
}

export default useCreateGroup
