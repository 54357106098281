import { CreateLocation, GetLocations, GetOneLocation, UpdateOneLocation } from 'models/locations'
import axios from 'services'
import { coordinateFormatter } from 'utils/coordinateFormatter'
import { removeEmpty } from 'utils/removeEmpty'

export const getLocations = ({ filters, ...payload }: GetLocations) => {
  return axios.get('/v1/locations', { params: removeEmpty({ ...payload, ...filters }) })
}

export const getOneLocation = ({ id }: GetOneLocation) => {
  return axios.get(`/v1/locations/${id}`)
}

export const createLocation = (payload: CreateLocation) => {
  return axios.post('/v1/locations', { ...payload, coordinate: coordinateFormatter(payload.coordinate) })
}

export const updateOneLocation = async ({ id, payload }: UpdateOneLocation) => {
  return axios.put(`/v1/locations/${id}`, { ...payload, coordinate: coordinateFormatter(payload.coordinate) })
}

export const getCarparks = () => {
  return axios.get('/v1/locations/mfl')
}
