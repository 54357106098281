import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { GET_RELATIONS, GET_LOCATIONS } from 'config/queries'
import { createRelation } from 'services/relations'
import { CreateRelation } from 'models/relations'

const useCreateRelation = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const createRelationMutation = useMutation({
    mutationFn: (payload: CreateRelation) => createRelation(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_RELATIONS] })
      queryClient.invalidateQueries({ queryKey: [GET_LOCATIONS] })
      toast(t('Relación actualizada'), { type: 'success' })
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: [GET_RELATIONS] })
      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
  })

  return createRelationMutation
}

export default useCreateRelation
