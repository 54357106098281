import { useParams } from 'react-router-dom'

import PageContainer from 'components/atoms/page-container'
import GroupsEditForm from 'components/organisms/groups/groups-edit-form'
import useGetOneGroupVersion from 'hooks/queries/group/useGetOneGroupVersion'

const GroupsShow = (): JSX.Element => {
  const { id, version } = useParams()
  const { isLoading, response } = useGetOneGroupVersion({ id, version })
  return (
    <PageContainer>
      <GroupsEditForm isLoading={isLoading} response={response} groupId={id} disabled historic />
    </PageContainer>
  )
}

export default GroupsShow
