import React from 'react'

import { Control, Controller } from 'react-hook-form'
import { GridSelectionModel } from '@mui/x-data-grid'

import GroupsList from 'components/pages/groups/groups-list'
import { TableTypes } from 'config/constants'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  disabled?: boolean
}

const ControllerGroupList: React.FC<Props> = ({ control, disabled }) => {
  return (
    <Controller
      name='groupIds'
      control={control}
      render={({ field }) => (
        <GroupsList
          type={disabled ? TableTypes.disabled : TableTypes.selection}
          ids={disabled && field.value}
          showInactives
          selectionModel={!disabled ? (field.value as GridSelectionModel) : []}
          onSelectionModelChange={field.onChange}
        />
      )}
    />
  )
}

export default ControllerGroupList
