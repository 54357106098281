import React from 'react'

import { Typography } from '@mui/material'

import { Location } from 'models/locations'
import { FontFamily, FontStyles } from 'config/constants'

interface Props {
  data: Location
}

const LocationSearchCell: React.FC<Props> = ({ data: { key, type, name } }) => {
  return (
    <>
      <Typography fontFamily={FontFamily.bold}>{key}</Typography>
      <Typography fontStyle={FontStyles.italic} fontFamily={FontFamily.light}>
        {type}
      </Typography>
      <Typography>{name}</Typography>
    </>
  )
}

export default LocationSearchCell
