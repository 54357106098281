import { useQuery } from 'react-query'

import { getOneIdentityVersion } from 'services/identities'
import { Identity, UseGetOneIdentityVersion } from 'models/identities'
import { GET_ONE_IDENTITY_VERSION } from 'config/queries'

interface Args {
  id?: string
  version?: string
}

const useGetOneIdentityVersion = ({ id, version }: Args): UseGetOneIdentityVersion => {
  const queryKey = [GET_ONE_IDENTITY_VERSION, id, version]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneIdentityVersion(id, version),
    refetchOnMount: true,
    staleTime: 0,
  })

  const result: Identity = response?.data

  return { response: result, isLoading }
}

export default useGetOneIdentityVersion
