import { Box } from '@mui/system'
import { t } from 'i18next'

import LocationsTable from 'components/organisms/locations/locations-table'
import CountChip from 'components/atoms/count-chip'
import NewResourceButton from 'components/atoms/new-resource-button'
import ResourceHeader from 'components/atoms/resource-header'
import ResourceFilter from 'components/molecules/resource-filter'
import routes from 'config/routes'
import useGetLocations from 'hooks/queries/locations/useGetLocations'
import LocationsFilter from 'components/organisms/locations/locations-filter'
import usePagination from 'hooks/usePagination'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/useAdministrationPermissions'
import useFilters from 'hooks/useFilters'
import AddCarparksButton from 'components/atoms/add-carparks-button'
import useGetCarparks from 'hooks/queries/locations/useGetCarparks'

const LocationsList = (): JSX.Element => {
  const { pageSize, offset, page, setPageSize, setPage } = usePagination()
  const { canWrite } = useAdministrationPermissions(AdministrationSections.locations)
  const { sortModel, badge, openFilter, filters, setSortModel, onSearchQ, onToggleFilter, onSearchFilter } = useFilters(
    {}
  )

  const { isFetching: isLoadingCarparks, getCarparks } = useGetCarparks()

  const handleUpdateCarparks = () => {
    getCarparks()
  }

  const { response, isLoading, count } = useGetLocations({ pageSize, offset, filters, sort: sortModel || 'id' })

  return (
    <Box>
      <ResourceHeader>
        <CountChip label={t('localizaciones')} labelSingular={t('localización')} count={count} isLoading={isLoading} />
        <ResourceFilter
          badge={badge}
          label={t`Identificador, nombre, tipo, dependencia...`}
          openFilter={openFilter}
          onSearchQ={onSearchQ}
          onToggleFilter={onToggleFilter}
        />
        {canWrite && <NewResourceButton label={t('Nueva localización')} to={routes.locationsCreate} />}
      </ResourceHeader>
      <Box display='flex' justifyContent='flex-end' marginBottom='1em'>
        {canWrite && (
          <AddCarparksButton
            label={t('Actualizar aparcamientos')}
            handleClick={handleUpdateCarparks}
            loading={isLoadingCarparks}
          />
        )}
      </Box>
      {openFilter && <LocationsFilter onSearch={onSearchFilter} />}
      <LocationsTable
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
      />
    </Box>
  )
}

export default LocationsList
