import React from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'

import BasicButton from 'components/atoms/basic-button'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import ControllerSwitch from 'components/molecules/controller-switch'
import { DateFormats } from 'config/constants'
import {
  applicationFilterDefaultValues,
  ApplicationsFilterFormFileds,
  ApplicationsFilterFormSchema,
  applicationsFilterSchema,
} from 'validations/applications'

import { FilterGridContainer } from './styled'

interface Props {
  onSearch: (search: ApplicationsFilterFormFileds) => void
}

const ApplicationsFilter: React.FC<Props> = ({ onSearch }) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ApplicationsFilterFormSchema>({
    defaultValues: applicationFilterDefaultValues,
    resolver: yupResolver(applicationsFilterSchema),
  })

  const onSubmit = (payload: ApplicationsFilterFormFileds) => {
    onSearch(payload)
  }

  const handleReset = () => {
    onSearch(applicationFilterDefaultValues)
    reset(applicationFilterDefaultValues)
  }

  return (
    <FilterGridContainer container pr={1}>
      <Grid item md={4} pr={1}>
        <ControllerInputText control={control} name='name' error={errors.name} label={t('Nombre')} size='full' />
      </Grid>
      <Grid item md={4} pr={1}>
        <ControllerInputDateTimePicker
          control={control}
          name='from'
          error={errors.from}
          label={t('Desde')}
          size='full'
          pattern={DateFormats.iso8601}
        />
      </Grid>
      <Grid item md={4} pr={1}>
        <ControllerInputDateTimePicker
          control={control}
          name='to'
          error={errors.to}
          label={t('Hasta')}
          size='full'
          pattern={DateFormats.iso8601}
        />
      </Grid>
      <Grid item md={12} display='flex' justifyContent='flex-end' flexDirection='row' gap={3}>
        <ControllerSwitch label={t('Solo activos')} name='showActiveOnly' variant='full' control={control} autoHeight />
        <BasicButton handleClick={handleReset} text={t('Restablecer')} size='small' color='warning' />
        <BasicButton handleClick={handleSubmit(onSubmit)} text={t('Buscar')} size='small' />
      </Grid>
    </FilterGridContainer>
  )
}

export default ApplicationsFilter
