import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import PageTitle from 'components/atoms/page-title'

const GroupsLayout = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <PageTitle title={t('grupos')} />
      <Outlet />
    </>
  )
}

export default GroupsLayout
