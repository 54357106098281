import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import { t } from 'i18next'
import * as Sentry from '@sentry/react'

import routes from 'config/routes'
import { userSchema, UserFormData, UserFormFields } from 'validations/user'
import CancelButton from 'components/atoms/cancel-button'
import SubmitButton from 'components/atoms/submit-button'
import GridItem from 'components/atoms/grid-item'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import PapperGridContainer from 'components/atoms/papper-grid-container-form'
import SpinnerLoading from 'components/atoms/spinner-loading'
import useUpdateUser from 'hooks/queries/user/useUpdateUser'
import useGetOneUser from 'hooks/queries/user/useGetOneUser'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerSwitch from 'components/molecules/controller-switch'
import { USER_ROLES } from 'config/constants'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import useFormErrorScroll from 'hooks/useFormErrorScroll'
import { useAuth } from 'providers/auth'
import ModalConfirm from 'components/molecules/modal-confirm'

interface Props {
  disabled?: boolean
}

const defaultValues: UserFormFields = {
  name: '',
  email: '',
  role: '',
  active: false,
  createDate: '',
  lastLoginAt: '',
}

const UsersEditForm: React.FC<Props> = ({ disabled }) => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const navigate = useNavigate()
  const updateUser = useUpdateUser()
  const { id } = useParams()
  const { user, signout } = useAuth()

  const { response, isLoading } = useGetOneUser({ id })

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<UserFormData>({
    defaultValues,
    resolver: yupResolver(userSchema),
  })

  useFormErrorScroll(errors)

  useEffect(() => {
    if (!response) return
    reset({
      name: response?.name ?? '',
      email: response?.email ?? '',
      role: response?.role ?? 'USER',
      active: response?.active ?? false,
      createDate: dayjs(response?.createDate).format('YYYY-MM-DD HH:mm'),
      lastLoginAt: dayjs(response?.lastLoginAt).format('YYYY-MM-DD HH:mm'),
    })
  }, [response, reset])

  const { name, email, active, role } = watch()

  const onSubmitLoggedUser = async () => {
    updateUser.mutate(
      { id, name, email, active, role },
      {
        onSuccess: () => {
          signout()
          navigate(routes.login)
        },
        onError: error => {
          Sentry.captureException(error)
        },
      }
    )
  }

  const onSubmit = () => {
    if (user?.email === response.email) {
      setShowConfirmModal(true)
    } else {
      updateUser.mutate({ id, name, email, active, role })
      navigate(routes.usersList)
    }
  }

  if (isLoading) {
    return <SpinnerLoading minHeight={500} />
  }

  return (
    <>
      <Grid container component='form' onSubmit={handleSubmit(onSubmit)} data-testid='users-edit-form'>
        <PapperGridContainer>
          <GridItem item md={6} xs={12}>
            <ControllerInputText
              mandatory
              control={control}
              name='name'
              error={errors.name}
              label={t('Nombre')}
              size='large'
              disabled={disabled}
            />
            <ControllerInputText
              mandatory
              control={control}
              name='email'
              error={errors.email}
              label={t('Email')}
              size='large'
              disabled={disabled}
            />
            <ControllerInputSelect
              mandatory
              control={control}
              name='role'
              error={errors.role}
              label={t('Rol')}
              size='large'
              options={USER_ROLES}
              disabled={disabled}
            />
          </GridItem>
          <GridItem item md={6} xs={12}>
            <ControllerSwitch label={t('Activo')} name='active' variant='full' control={control} disabled={disabled} />
            <ControllerInputDateTimePicker
              label={t('Fecha Alta')}
              name='createDate'
              control={control}
              size='large'
              disabled
            />
            <ControllerInputDateTimePicker
              label={t('Fecha último login')}
              name='lastLoginAt'
              control={control}
              size='large'
              disabled
            />
          </GridItem>
        </PapperGridContainer>
        <FormButtonsContainer>
          <CancelButton to={routes.usersList} />
          {!disabled && <SubmitButton />}
        </FormButtonsContainer>
      </Grid>
      <ModalConfirm
        open={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={onSubmitLoggedUser}
        title={t('Atención')}
        message={t(
          // eslint-disable-next-line max-len
          'Esta a punto de modificar el usuario con el cual ha entrado al sistema. Al confirmar los cambios, será redirigido a la pantalla de inicio. ¿Desea continuar?'
        )}
        confirmLabel={t('confirmar')}
        cancelLabel={t('cancelar')}
      />
    </>
  )
}

export default UsersEditForm
