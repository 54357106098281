import React, { useCallback, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'

import BasicButton from 'components/atoms/basic-button'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import { DateFormats, LOCATION_TYPES } from 'config/constants'
import {
  identityActiveGroupsFilterDefaultValues,
  IdentityActiveGroupsFilterFormFields,
  IdentityActiveGroupsFilterFormSchema,
  identitiesActiveGroupsFilterSchema,
} from 'validations/identities'

import { FilterGridContainer } from './styled'

interface Props {
  onSearch: (search: IdentityActiveGroupsFilterFormFields) => void
}

const IdentitiesFilter: React.FC<Props> = ({ onSearch }) => {
  const { t } = useTranslation()

  const defaultValues = identityActiveGroupsFilterDefaultValues

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<IdentityActiveGroupsFilterFormSchema>({
    defaultValues,
    resolver: yupResolver(identitiesActiveGroupsFilterSchema),
  })

  const dateWatch = watch('date')
  useEffect(() => {
    if (!dateWatch) {
      setValue('date', defaultValues.date)
    }
  }, [dateWatch, setValue, defaultValues.date])

  const handleReset = useCallback(() => {
    reset(defaultValues)
    onSearch(defaultValues)
  }, [defaultValues, onSearch, reset])

  const onSubmit = useCallback(
    (payload: IdentityActiveGroupsFilterFormFields) => {
      onSearch(payload)
    },
    [onSearch]
  )

  return (
    <FilterGridContainer container pr={1}>
      <Grid item md={6} pr={1}>
        <ControllerInputSelect
          control={control}
          name='locationType'
          error={errors.locationType}
          label={t('Tipo de localización')}
          size='full'
          options={LOCATION_TYPES}
        />
      </Grid>
      <Grid item md={6} pr={1}>
        <ControllerInputDateTimePicker
          control={control}
          name='date'
          error={errors.date}
          label={t('Fecha')}
          size='full'
          pattern={DateFormats.iso8601}
          mandatory
        />
      </Grid>

      <Grid item md={12} display='flex' justifyContent='flex-end' flexDirection='row' gap={3}>
        <BasicButton handleClick={handleReset} text={t('Restablecer')} size='small' color='warning' type='button' />
        <BasicButton handleClick={handleSubmit(onSubmit)} text={t('Buscar')} size='small' />
      </Grid>
    </FilterGridContainer>
  )
}

export default IdentitiesFilter
