import React, { ReactNode } from 'react'

import { Control, FieldError, FieldErrors, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DAY_LIST } from 'config/constants'
import { GroupFormFields } from 'validations/groups'
import { DayList } from 'models/dates'
import DaysChip from 'components/atoms/days-chip'
import AddButton from 'components/atoms/add-button'
import RemoveIconButton from 'components/atoms/remove-icon-button'
import AddIconButton from 'components/atoms/add-icon-button'

import ControllerInputSelect from '../controller-input-select'
import ControllerInputTimePicker from '../controller-input-timepicker'

import { PeriodTimeActionContainer, PeriodTimeInputContainer } from './styled'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  error?: FieldErrors<GroupFormFields>
  disabled?: boolean
}
const ControllerCustomInputPeriodTime: React.FC<Props> = ({ control, error, disabled }) => {
  const { fields, append, remove } = useFieldArray<GroupFormFields>({ control, name: 'periodTime' })
  const { t } = useTranslation()

  const handleSelectRenderValue = (selection: string[] | string): ReactNode => {
    const ordererDays = DAY_LIST.filter(({ value }: DayList) => selection.includes(value))
    return ordererDays.map(day => <DaysChip key={`${day.value}`} data={day} />)
  }

  return (
    <>
      {fields.map(({ id }, index) => {
        const periodTimeData = `periodTime[${index}]`
        const errorData = error?.periodTime?.[index]
        return (
          <PeriodTimeInputContainer key={`groups-periodTime-${id}-controller`}>
            <ControllerInputTimePicker
              mandatory
              name={`${periodTimeData}.from`}
              control={control}
              label={t('Desde')}
              disabled={disabled}
              size='full'
              error={errorData?.from}
            />
            <ControllerInputTimePicker
              mandatory
              name={`${periodTimeData}.to`}
              control={control}
              label={t('Hasta')}
              disabled={disabled}
              size='full'
              error={errorData?.to}
            />
            <ControllerInputSelect
              mandatory
              multiple
              name={`${periodTimeData}.dayOfWeek`}
              control={control}
              label={t('Días de la Semana')}
              options={DAY_LIST}
              size='full'
              disabled={disabled}
              renderValue={handleSelectRenderValue}
              error={errorData?.dayOfWeek as FieldError}
            />
            {!disabled && (
              <PeriodTimeActionContainer>
                {fields.length > 0 && <RemoveIconButton handleClick={() => remove(index)} />}
                {fields.length === index + 1 && (
                  <AddIconButton handleClick={() => append({ to: '', from: '', dayOfWeek: [] })} />
                )}
              </PeriodTimeActionContainer>
            )}
          </PeriodTimeInputContainer>
        )
      })}
      {fields.length === 0 && !disabled && (
        <AddButton text={t('Añadir horario')} handleClick={() => append({ to: '', from: '', dayOfWeek: [] })} />
      )}
    </>
  )
}

export default ControllerCustomInputPeriodTime
