import { useQuery } from 'react-query'

import { UseGetDomains } from 'models/domains'
import { getAllDomains } from 'services/domains'
import { GET_DOMAINS } from 'config/queries'

const useGetDomains = (): UseGetDomains => {
  const queryKey = [GET_DOMAINS]
  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => getAllDomains(),
    refetchOnMount: true,
  })

  return { response: data || [], isLoading }
}

export default useGetDomains
