import { useCallback, useState } from 'react'

import { INITIAL_PAGE_OPTION } from 'config/constants'

interface State {
  pageSize: number
  offset: number
  page: number
}

const usePagination = () => {
  const [pageSize, setPageSize] = useState<State['pageSize']>(INITIAL_PAGE_OPTION)
  const [offset, setOffset] = useState<State['offset']>(0)
  const [page, setPage] = useState<State['page']>(0)

  const updatePage = useCallback(
    (pageNumber: number) => {
      setPage(pageNumber)
      setOffset(pageNumber * pageSize)
    },
    [pageSize]
  )

  return { pageSize, offset, page, setPageSize, setPage: updatePage }
}

export default usePagination
