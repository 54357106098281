import { useQuery } from 'react-query'

import { Group } from 'models/groups'
import { UseGetOneIdentityActiveGroups } from 'models/identities'
import { GET_ONE_IDENTITY_ACTIVE_GROUPS } from 'config/queries'
import { IdentityActiveGroupsFilterFormFields } from 'validations/identities'
import { getOneIdentityActiveGroups } from 'services/identities'

interface Args {
  id: string
  limit: number
  offset: number
  filters: IdentityActiveGroupsFilterFormFields
  sort: string
}

const useGetOneIdentityActiveGroups = ({ id, limit, offset, filters, sort }: Args): UseGetOneIdentityActiveGroups => {
  const queryKey = [GET_ONE_IDENTITY_ACTIVE_GROUPS, id, limit, offset, filters, sort]
  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getOneIdentityActiveGroups({ id, limit, offset, filters, sort }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Array<Group> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData }
}

export default useGetOneIdentityActiveGroups
