import {
  CreateIdentities,
  GetIdentities,
  UpdateIdentities,
  GetIdentitiesVersions,
  GetOneIdentityActiveGroups,
} from 'models/identities'
import axios from 'services'
import { metadataToArray } from 'utils/metadataToArray'
import { removeEmpty } from 'utils/removeEmpty'

export const getIdentities = ({ filters, ...payload }: GetIdentities) => {
  return axios.get('/v1/identities', {
    params: removeEmpty({ ...payload, ...filters, metadata: metadataToArray(filters?.metadata) }),
  })
}

export const getIdentitiesVersions = ({ id, ...params }: GetIdentitiesVersions) => {
  return axios.get(`/v1/identities/${id}/versions`, { params })
}

export const createIdentity = (payload: CreateIdentities) => {
  return axios.post('/v1/identities', payload)
}

export const getOneIdentity = (id?: string) => {
  return axios.get(`/v1/identities/${id}`)
}

export const getOneIdentityVersion = (id?: string, version?: string) => {
  return axios.get(`/v1/identities/${id}/versions/${version}`)
}

export const updateOneIdentity = (id: string | undefined, payload: UpdateIdentities) => {
  return axios.put(`/v1/identities/${id}`, payload)
}

export const getOneIdentityActiveGroups = ({ id, filters, ...payload }: GetOneIdentityActiveGroups) => {
  return axios.get(`/v1/identities/${id}/active-groups`, {
    params: removeEmpty({ ...payload, ...filters, compact: false }),
  })
}
