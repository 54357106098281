import styled from 'styled-components'
import Box from '@mui/material/Box/Box'

import { INPUT_BASE_HEIGHT, INPUT_CONTAINER_HEIGHT } from 'config/constants'
import { Sizes } from 'models/sizes'

interface Props {
  $variant: Sizes
  $direction: 'column' | 'row'
}

export const Container = styled(Box)(({ $direction, $variant }: Props) => {
  let width: string
  switch ($variant) {
    case 'small':
      width = '140px'
      break
    case 'medium':
      width = '180px'
      break
    case 'large':
      width = '290px'
      break
    case 'full':
      width = '100%'
      break
    default:
      width = '180px'
      break
  }

  return {
    display: 'flex',
    flexDirection: $direction,
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '0.4rem',
    height: INPUT_BASE_HEIGHT,
    width,
  }
})

export const MainContainer = styled(Box)(({ $autoHeight }: { $autoHeight?: boolean }) => {
  return {
    height: $autoHeight ? 'auto' : INPUT_CONTAINER_HEIGHT,
  }
})
