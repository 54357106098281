import { useCallback, useState } from 'react'

import { FieldErrors, UseFieldArrayReplace, UseFieldArrayUpdate } from 'react-hook-form'
import _ from 'lodash'

import { KeyType } from 'config/constants'
import { Keys } from 'models/keys'
import { getKeycloakId } from 'services/eos'
import { GroupFormFields } from 'validations/groups'

/*  eslint-disable @typescript-eslint/no-explicit-any */
interface UseKeycloakIdKeyProps {
  keys: Keys[]
  error?: FieldErrors<GroupFormFields>
  replace: UseFieldArrayReplace<any, 'keys'>
  update: UseFieldArrayUpdate<any, 'keys'>
}
/*  eslint-enable @typescript-eslint/no-explicit-any */

const useKeycloakIdKey = ({ keys, error, replace, update }: UseKeycloakIdKeyProps) => {
  const [emailKeycloakMap] = useState<Map<string, string>>(new Map())
  const [invalidIds, setInvalidIds] = useState<Record<number, boolean>>({})

  const getKeycloakIdForEmail = useCallback(async (email: string) => {
    try {
      const { data } = await getKeycloakId(email)
      return data
    } catch (e) {
      return ''
    }
  }, [])

  const refreshKeycloakId = (index: number) => async () => {
    const field = keys?.[index]

    if (field?.keyType !== KeyType.EMAIL || error?.keys?.[index]?.key) {
      return
    }

    let keycloakId: string
    if (emailKeycloakMap.has(field.key)) {
      keycloakId = emailKeycloakMap.get(field.key) ?? ''
    } else {
      keycloakId = await getKeycloakIdForEmail(field.key)
      emailKeycloakMap.set(field.key, keycloakId)
    }

    setInvalidIds(prev => ({ ...prev, [index + 1]: !keycloakId }))
    update(index + 1, { keyType: KeyType.ACCOUNT_ID, key: keycloakId })
  }

  const toggleAccountIdField = useCallback(() => {
    if (!keys?.length) {
      return
    }

    const keysCopy = [...keys]

    for (let index = 0; index < keysCopy.length; index++) {
      const { keyType } = keysCopy[index]
      const nextField = keysCopy[index + 1]
      const prevField = keysCopy[index - 1]

      if (keyType !== KeyType.EMAIL && nextField?.keyType === KeyType.ACCOUNT_ID) {
        keysCopy.splice(index + 1, 1)
      } else if (keyType === KeyType.EMAIL && nextField?.keyType !== KeyType.ACCOUNT_ID) {
        keysCopy.splice(index + 1, 0, { keyType: KeyType.ACCOUNT_ID, key: '' })
      } else if (
        prevField?.keyType !== KeyType.EMAIL &&
        keyType === KeyType.ACCOUNT_ID &&
        nextField?.keyType === KeyType.EMAIL
      ) {
        const tmp = keysCopy[index + 1]
        keysCopy[index + 1] = keysCopy[index]
        keysCopy[index] = tmp
      }
    }

    if (!_.isEqual(keys, keysCopy)) {
      replace(keysCopy)
    }
  }, [keys, replace])

  const isInvalidId = useCallback((index: number) => invalidIds[index], [invalidIds])

  return { isInvalidId, refreshKeycloakId, toggleAccountIdField }
}

export default useKeycloakIdKey
