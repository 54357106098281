import styled from 'styled-components'
import Box from '@mui/system/Box'

import { INPUT_BASE_HEIGHT, INPUT_CONTAINER_HEIGHT } from 'config/constants'

interface Props {
  width: string
}

export const StyledBox = styled(Box)`
  width: ${(props: Props) => props.width};
  height: ${INPUT_CONTAINER_HEIGHT};
  display: flex;
  align-items: flex-start;

  & .MuiFormControl-root {
    width: 100%;
    height: ${INPUT_BASE_HEIGHT};

    input {
      line-height: 1.6rem;
    }
  }
`
