import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import routes from 'config/routes'
import useGetRelations from 'hooks/queries/relation/useGetRelations'
import RelationsTable from 'components/organisms/relations/relations-table'
import CountChip from 'components/atoms/count-chip'
import NewResourceButton from 'components/atoms/new-resource-button'
import ResourceHeader from 'components/atoms/resource-header'
import ResourceFilter from 'components/molecules/resource-filter'
import RelationsFilter from 'components/organisms/relations/relations-filter'
import { relationsFilterDefaultValues } from 'validations/relations'
import usePagination from 'hooks/usePagination'
import useRelationsPermissions from 'hooks/permissions/useRelationsPermissions'
import useFilters from 'hooks/useFilters'

const RelationsList = (): JSX.Element => {
  const { t } = useTranslation()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()
  const { canWrite } = useRelationsPermissions()
  const { sortModel, badge, openFilter, filters, setSortModel, onSearchQ, onToggleFilter, onSearchFilter } =
    useFilters(relationsFilterDefaultValues)

  const { response, isLoading, count, queryKey } = useGetRelations({
    offset,
    limit: pageSize,
    sort: sortModel || 'id',
    filters: { ...filters, showInactive: !filters.showActiveOnly, showActiveOnly: undefined },
  })

  const handleOnSortModelChange = (sortingString: string) => {
    setSortModel(sortingString)
  }

  return (
    <Box>
      <ResourceHeader>
        <CountChip label={t('Registros')} labelSingular={t('Registro')} count={count} isLoading={isLoading} />
        <ResourceFilter
          badge={badge}
          label={t`Identificador, nombre, dominio, metadatos ...`}
          openFilter={openFilter}
          onSearchQ={onSearchQ}
          onToggleFilter={onToggleFilter}
        />
        {canWrite && <NewResourceButton label={t('Nueva relación')} to={routes.relationsCreate} />}
      </ResourceHeader>
      {openFilter && <RelationsFilter onSearch={onSearchFilter} />}
      <RelationsTable
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={handleOnSortModelChange}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        queryKey={queryKey}
      />
    </Box>
  )
}

export default RelationsList
