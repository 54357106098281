import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_IDENTITIES } from 'config/queries'
import { createIdentity } from 'services/identities'
import { CreateIdentities } from 'models/identities'

const useCreateIdentity = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const createIdentityMutation = useMutation({
    mutationFn: (payload: CreateIdentities) => createIdentity(payload),
    onSuccess: () => {
      toast(t('Identidad creada'), { type: 'success' })
      queryClient.invalidateQueries({ queryKey: [GET_IDENTITIES] })
    },
    onError: () => {
      toast(t('Ha ocurrido un error'), { type: 'error' })
      queryClient.invalidateQueries({ queryKey: [GET_IDENTITIES] })
    },
  })

  return createIdentityMutation
}

export default useCreateIdentity
