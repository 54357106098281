import React, { useRef } from 'react'

import Box from '@mui/material/Box'
import { Button, Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { toast } from 'react-toastify'

import { CSV_FORMAT, buttonsWidth } from 'config/constants'
interface Props {
  onChangeFile: (file: Blob | null) => void
  file: File | null
}

const UploadCsv: React.FC<Props> = ({ onChangeFile, file }) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLInputElement>(null)

  const handleOnFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]?.type !== CSV_FORMAT) {
      toast(t('Solo se admiten archivos CSV'), { type: 'warning' })
      return
    }
    onChangeFile(event.target.files?.[0] as Blob)
  }
  const handleOnFileRemove = () => {
    if (ref.current) ref.current.value = ''
    onChangeFile(null)
  }
  return (
    <Box width='100%' alignItems='center' display='flex' flexDirection='column' gap={3} mt={4}>
      <Button variant='text' component='label' sx={{ width: buttonsWidth.large }} startIcon={<AttachFileIcon />}>
        {t('Seleccionar fichero')}
        <input type='file' hidden onChange={handleOnFileChange} ref={ref} />
      </Button>
      {file && <Chip label={file.name} onDelete={handleOnFileRemove} variant='outlined' />}
    </Box>
  )
}

export default UploadCsv
