import { useQuery } from 'react-query'

import { getRelations } from 'services/relations'
import { Relation, UseGetRelations } from 'models/relations'
import { GET_RELATIONS } from 'config/queries'
import { RelationsFilterFormFields } from 'validations/relations'

interface Args {
  limit: number
  offset: number
  name?: string
  sort: string
  filters: RelationsFilterFormFields
}

const useGetRelations = ({ limit, offset, sort, filters }: Args): UseGetRelations => {
  const queryKey = [GET_RELATIONS, limit, offset, sort, filters]
  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getRelations({ limit, offset, sort, filters }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Array<Relation> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetRelations
