import React, { useCallback } from 'react'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GridEnrichedColDef } from '@mui/x-data-grid'

import BasicTable from 'components/molecules/basic-table'
import routes from 'config/routes'
import { Location } from 'models/locations'
import ShowGridActionCellItem from 'components/atoms/show-grid-action-cell-item'
import EditGridActionCellItem from 'components/atoms/edit-grid-action-cell-item'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/useAdministrationPermissions'
import CoordinateCell from 'components/atoms/coordinate-cell'
import { FontSizes, LOCATION_TYPES, RowHeight, TableColumnWidth } from 'config/constants'
import ChipCell from 'components/atoms/chip-cell'
import TextCell from 'components/atoms/text-cell'

interface Props {
  rows: Array<Location>
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageSizeChange: (pageSize: number) => void
  onPageChange: (page: number) => void
}

const LocationsTable = ({
  rows,
  count,
  page,
  pageSize,
  isLoading,
  onSortModelChange,
  onPageChange,
  onPageSizeChange,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { canWrite } = useAdministrationPermissions(AdministrationSections.locations)

  const navigateToEdit = useCallback((id: string) => navigate(routes.locationsEdit.replace(':id', id)), [navigate])

  const navigateToShow = useCallback((id: string) => navigate(routes.locationsShow.replace(':id', id)), [navigate])

  const getTableActions = useCallback(
    ({ row }: { row: Location }) => {
      const actions = [<ShowGridActionCellItem key={`${row.id}-show`} onClick={() => navigateToShow(`${row.id}`)} />]

      if (canWrite && row?.editable) {
        actions.unshift(<EditGridActionCellItem key={`${row.id}-edit`} onClick={() => navigateToEdit(`${row.id}`)} />)
      }

      return actions
    },
    [canWrite, navigateToShow, navigateToEdit]
  )

  const typeFormatter = (type?: string) => {
    const result = LOCATION_TYPES.find(({ value }) => value === type)
    return result?.text ?? ''
  }

  const parentFormatter = (parent?: Partial<Location>) => (parent ? `${parent.name} (${parent.key})` : '-')

  const columns: GridEnrichedColDef[] = [
    { field: 'id', headerName: '', flex: TableColumnWidth.xs, headerAlign: 'center', align: 'center' },
    {
      field: 'type',
      headerName: `${t('Tipo')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Location }) => (
        <ChipCell label={typeFormatter(row.type)} fontSize={FontSizes.base} />
      ),
    },
    {
      field: 'key',
      headerName: `${t('Clave')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: Location }) => <TextCell text={row.key} />,
    },
    {
      field: 'name',
      headerName: `${t('Nombre')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Location }) => <TextCell text={row.name} />,
    },
    {
      field: 'parent',
      headerName: `${t('Dependencia')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Location }) => <TextCell text={parentFormatter(row.parent)} />,
    },
    {
      field: 'coordinate',
      headerName: `${t('Coordenadas')}`,
      flex: TableColumnWidth.large,
      renderCell: field => <CoordinateCell coordinate={field?.value} />,
      sortable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: TableColumnWidth.base,
      getActions: getTableActions,
    },
  ]

  return (
    <BasicTable
      count={count}
      isLoading={isLoading}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onSortModelChange={onSortModelChange}
      page={page}
      pageSize={pageSize}
      rows={rows}
      columns={columns}
      onRowClick={({ row }) => navigateToShow(row.id)}
      rowHeight={RowHeight.large}
    />
  )
}

export default LocationsTable
