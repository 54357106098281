import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'

import { colors } from 'config/theme'

export const StyledLink = styled(Link)`
  height: 100%;
`

export const HeaderLinkContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  margin: auto 10px;
  height: 100%;
  color: ${colors.deepBlue};

  &:hover {
    color: ${colors.orange};
  }
`
