import { ManipulateType } from 'dayjs'

import { DayList } from 'models/dates'
import { LocationTypes } from 'models/locations'

import i18n from './i18n'

export const BASE_URL: string | undefined = process.env.REACT_APP_API_URL
export const COGNITO_URL: string | undefined = process.env.REACT_APP_COGNITO_URL
export const COGNITO_CLIENT_ID: string | undefined = process.env.REACT_APP_COGNITO_CLIENT_ID
export const COGNITO_REDIRECT_URL: string | undefined = process.env.REACT_APP_COGNITO_REDIRECT_URL
export const SENTRY_DSN =
  process.env.REACT_APP_SENTRY_DSN ||
  'https://a77addcdd7714ed29a91da8fb1898dc6@o1045333.ingest.sentry.io/4505152843022336'
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT ?? 'DEV'

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 15]

export const INITIAL_PAGE_OPTION = 5

export const USER_ROLES = [
  {
    value: 'SUPER-ADMIN',
    text: i18n.t('SUPER-ADMIN'),
  },
  {
    value: 'ADMIN',
    text: i18n.t('ADMIN'),
  },

  {
    value: 'ADMIN-LOCATION',
    text: i18n.t('ADMIN-LOCATION'),
  },

  {
    value: 'OPERATOR',
    text: i18n.t('OPERATOR'),
  },

  {
    value: 'SEGMENTATION',
    text: i18n.t('SEGMENTATION'),
  },

  {
    value: 'READER',
    text: i18n.t('READER'),
  },
]

export enum KeyType {
  'ACCOUNT_ID' = 'ACCOUNT_ID',
  'DOCUMENT_ID' = 'DOCUMENT_ID',
  'PASSPORT' = 'PASSPORT',
  'PLATE_NUMBER' = 'PLATE_NUMBER',
  'EMAIL' = 'EMAIL',
  'UUID' = 'UUID',
  'GROUP' = 'GROUP',
  'RELATION' = 'RELATION',
  'OTHER' = 'OTHER',
}

export const KEY_TYPES = [
  {
    value: KeyType.ACCOUNT_ID,
    text: i18n.t('Id de la cuenta'),
  },
  {
    value: KeyType.DOCUMENT_ID,
    text: i18n.t('Id del documento'),
  },
  {
    value: KeyType.PASSPORT,
    text: i18n.t('Pasaporte'),
  },
  {
    value: KeyType.PLATE_NUMBER,
    text: i18n.t('Matrícula'),
  },
  {
    value: KeyType.EMAIL,
    text: i18n.t('Email'),
  },
  {
    value: KeyType.UUID,
    text: i18n.t('Uuid'),
  },
  {
    value: KeyType.GROUP,
    text: i18n.t('Grupo'),
  },
  {
    value: KeyType.RELATION,
    text: i18n.t('Relación'),
  },
  {
    value: KeyType.OTHER,
    text: i18n.t('Otros'),
  },
]

export const DAY_LIST: Array<DayList> = [
  { value: 'MONDAY', text: i18n.t('Lunes'), iso: i18n.t('L') },
  { value: 'TUESDAY', text: i18n.t('Martes'), iso: i18n.t('M') },
  { value: 'WEDNESDAY', text: i18n.t('Miércoles'), iso: i18n.t('X') },
  { value: 'THURSDAY', text: i18n.t('Jueves'), iso: i18n.t('J') },
  { value: 'FRIDAY', text: i18n.t('Viernes'), iso: i18n.t('V') },
  { value: 'SATURDAY', text: i18n.t('Sábado'), iso: i18n.t('S') },
  { value: 'SUNDAY', text: i18n.t('Domingo'), iso: i18n.t('D') },
]
export const LOCATION_TYPES: Array<LocationTypes> = [
  {
    id: 1,
    value: 'COUNTRY',
    text: i18n.t('País'),
  },
  {
    id: 2,
    value: 'PROVINCE',
    text: i18n.t('Provincia'),
  },
  {
    id: 3,
    value: 'MUNICIPALITY',
    text: i18n.t('Municipio'),
  },
  {
    id: 4,
    value: 'POSTAL_CODE',
    text: i18n.t('Código postal'),
  },
  {
    id: 5,
    value: 'CAR_PARK',
    text: i18n.t('Aparcamiento'),
  },
  {
    id: 6,
    value: 'COORDINATE',
    text: i18n.t('Coordenadas'),
  },
]

export const APPLICATIONS_ROLES = [
  {
    value: 'READER',
    text: i18n.t('READER'),
  },
  {
    value: 'OPERATOR',
    text: i18n.t('OPERATOR'),
  },
]

export const ACTION_OPTIONS = [
  {
    value: 'CREATE',
    text: i18n.t('Crear'),
  },
  {
    value: 'UPDATE',
    text: i18n.t('Actualizar'),
  },
  {
    value: 'DELETE',
    text: i18n.t('Eliminar'),
  },
]

export const RESOURCE_TYPE_OPTIONS = [
  {
    value: 'IDENTITY',
    text: i18n.t('Identidad'),
  },
  {
    value: 'RELATION',
    text: i18n.t('Relación'),
  },
  {
    value: 'GROUP',
    text: i18n.t('Grupo'),
  },
  {
    value: 'USER',
    text: i18n.t('Usuario'),
  },
  {
    value: 'LOCATION',
    text: i18n.t('Localización'),
  },
  {
    value: 'APPLICATION',
    text: i18n.t('Aplicación'),
  },
]

export const buttonsWidth = {
  small: '140px',
  medium: '200px',
  large: '280px',
  full: '100%',
}

export const inputsWidth = {
  small: '160px',
  medium: '220px',
  large: '290px',
  full: '100%',
}

export const INPUT_CONTAINER_HEIGHT = '90px'
export const INPUT_BASE_HEIGHT = '55px'

export enum Sort {
  asc = 'asc',
  desc = 'desc',
}

export enum RowHeight {
  small = 55,
  medium = 65,
  large = 80,
}

export enum DateFormats {
  full = 'DD/MM/YYYY HH:mm',
  day = 'DD/MM/YYYY',
  hour = 'HH:mm',
  iso = 'YYYY-MM-DDTHH:mm:ss',
  iso8601 = 'YYYY-MM-DDTHH:mm:ss.sssZ',
}

export enum RelationTabsValue {
  identities,
  groups,
  config,
  resume,
}

export enum TableTypes {
  default,
  selection = 'selection',
  disabled = 'disabled',
}

export enum FontSizes {
  sm = '0.7rem',
  md = '0.75rem',
  base = '0.85rem',
  lg = '1rem',
  xl = '1.2rem',
}

export enum FontStyles {
  italic = 'italic',
  bold = 'bold',
}

export enum FontFamily {
  bold = 'Poppins-Bold',
  light = 'Poppins-Light',
}

export enum TableColumnWidth {
  xs = 0.6,
  small = 0.75,
  base = 1,
  large = 1.25,
  xl = 1.5,
}

export const INVALID_DATE = 'Invalid Date'
export const INVALID_TOKEN_ERROR_MESSAGE = 'Invalid token specified'

export const LOCATION_RESOURCETYPE = 'LOCATION'

export const RELATIONS_IDENTITIES_LIMIT = 100

export const CSV_FORMAT = 'text/csv'

export const MIN_DATE_ADD: [number, ManipulateType] = [1, 'minute']
