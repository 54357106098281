import React from 'react'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import useGetOneGroupRelations from 'hooks/queries/group/useGetOneGroupRelations'
import CountChip from 'components/atoms/count-chip'
import ResourceHeader from 'components/atoms/resource-header'
import usePagination from 'hooks/usePagination'
import useSort from 'hooks/useSort'
import FormSubtitle from 'components/atoms/form-subtitle'

import GroupsRelationsTable from './table'

interface Props {
  id: string
}

const GroupsRelationsList: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()

  const { sortModel, setSortModel } = useSort()

  const { response, isLoading, count } = useGetOneGroupRelations({
    id,
    offset,
    limit: pageSize,
    sort: sortModel || 'id',
  })

  if (!count && !isLoading) {
    return <FormSubtitle text={t('Sin resultados')} />
  }

  return (
    <Box width='100%'>
      <ResourceHeader>
        <CountChip label={t('Registros')} labelSingular={t('Registro')} count={count} isLoading={isLoading} />
      </ResourceHeader>
      <GroupsRelationsTable
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
      />
    </Box>
  )
}

export default GroupsRelationsList
