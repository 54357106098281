import React, { useMemo } from 'react'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import CountChip from 'components/atoms/count-chip'
import ResourceHeader from 'components/atoms/resource-header'
import ResourceFilter from 'components/molecules/resource-filter'
import usePagination from 'hooks/usePagination'
import useFilters from 'hooks/useFilters'
import { IdentityActiveGroupsFilterFormFields, identityActiveGroupsFilterDefaultValues } from 'validations/identities'
import useGetOneIdentityActiveGroups from 'hooks/queries/identity/useGetOneIdentityActiveGroups'
import FormSubtitle from 'components/atoms/form-subtitle'

import IdentityActiveGroupsFilter from './filters'
import GroupsActiveIdentitiesTable from './table'

interface Props {
  id: string
}

const IdentitiesActiveGroupsList: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()

  const { sortModel, badge, openFilter, filters, setSortModel, onSearchQ, onToggleFilter, onSearchFilter } = useFilters(
    identityActiveGroupsFilterDefaultValues
  )

  const { response, isLoading, count } = useGetOneIdentityActiveGroups({
    id,
    offset,
    limit: pageSize,
    filters,
    sort: sortModel || 'id',
  })

  const handleOnSearchFilter = (search: IdentityActiveGroupsFilterFormFields) => {
    onSearchFilter(search)
  }

  const filtersEnabled = useMemo(
    () =>
      Object.keys(filters).reduce(
        (r, v) => (filters[v as keyof IdentityActiveGroupsFilterFormFields] || r ? true : false),
        false
      ),
    [filters]
  )
  if (!count && !isLoading && !filtersEnabled) {
    return <FormSubtitle text={t('Sin resultados')} />
  }

  return (
    <Box width='100%'>
      <ResourceHeader>
        <CountChip label={t('Registros')} labelSingular={t('Registro')} count={count} isLoading={isLoading} />
        <ResourceFilter
          badge={badge}
          label={t`Identificador, dominio, validez, localización ...`}
          openFilter={openFilter}
          onSearchQ={onSearchQ}
          onToggleFilter={onToggleFilter}
        />
      </ResourceHeader>

      {openFilter && <IdentityActiveGroupsFilter onSearch={handleOnSearchFilter} />}
      <GroupsActiveIdentitiesTable
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
      />
    </Box>
  )
}

export default IdentitiesActiveGroupsList
