import PageContainer from 'components/atoms/page-container'
import GroupsCreateForm from 'components/organisms/groups/groups-create-form'

const GroupsCreate = (): JSX.Element => (
  <PageContainer>
    <GroupsCreateForm />
  </PageContainer>
)

export default GroupsCreate
