import React, { useRef, useEffect, useState } from 'react'

import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

import { FontSizes } from 'config/constants'

interface Args {
  text: string
}
const TextCell: React.FC<Args> = ({ text }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const textRef = useRef<any>()
  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    if (textRef.current?.scrollWidth > textRef.current?.offsetWidth) {
      setShowTooltip(true)
    }
  }, [text])

  return (
    <Tooltip title={text} disableHoverListener={!showTooltip}>
      <Typography noWrap fontSize={FontSizes.base} ref={textRef}>
        {text}
      </Typography>
    </Tooltip>
  )
}

export default TextCell
