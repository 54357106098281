import React from 'react'

import Switch from '@mui/material/Switch/Switch'
import InputLabel from '@mui/material/InputLabel/InputLabel'

import { Sizes } from 'models/sizes'
import { SwitchControllerField } from 'models/form'

import { Container, MainContainer } from './styled'

interface Props {
  field: SwitchControllerField
  label: string | null
  boxDirection: 'column' | 'row'
  variant: Sizes
  disabled?: boolean
  autoHeight?: boolean
}

const BasicInputSwitch: React.FC<Props> = ({ field, label, boxDirection, variant, disabled, autoHeight }) => (
  <MainContainer $autoHeight={autoHeight}>
    <Container $direction={boxDirection} $variant={variant}>
      {label && <InputLabel disabled={disabled}>{label}</InputLabel>}
      <Switch {...field} checked={field.value} disabled={disabled} />
    </Container>
  </MainContainer>
)
export default BasicInputSwitch
