import PageContainer from 'components/atoms/page-container'
import RelationsCreateForm from 'components/organisms/relations/relations-create-form'

const RelationsCreate = (): JSX.Element => (
  <PageContainer>
    <RelationsCreateForm />
  </PageContainer>
)

export default RelationsCreate
