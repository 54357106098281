import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import csvTemplate from 'assets/templates/identities_template.csv'
import routes from 'config/routes'
import useGetIdentities from 'hooks/queries/identity/useGetIdentities'
import IdentitiesTable from 'components/organisms/identities/identities-table'
import CountChip from 'components/atoms/count-chip'
import NewResourceButton from 'components/atoms/new-resource-button'
import ResourceHeader from 'components/atoms/resource-header'
import ResourceFilter from 'components/molecules/resource-filter'
import { identitiesFilterDefaultValues } from 'validations/identities'
import IdentitiesFilter from 'components/organisms/identities/identities-filter'
import { ListProps } from 'models/list'
import usePagination from 'hooks/usePagination'
import useIdentitiesPermissions from 'hooks/permissions/useIdentitiesPermissions'
import useFilters from 'hooks/useFilters'
import ModalUploadCsv from 'components/molecules/modal-upload-csv'
import CsvButton from 'components/atoms/csv-button'
import CsvTemplateButton from 'components/atoms/csv-template-button'

const IdentitiesList: React.FC<ListProps> = ({ type, ids, showInactives, ...props }) => {
  const [showCsvModal, setShowCsvModal] = useState<boolean>(false)
  const { t } = useTranslation()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()
  const { canWrite } = useIdentitiesPermissions()
  const { sortModel, badge, openFilter, filters, setSortModel, onSearchQ, setBadge, onToggleFilter, onSearchFilter } =
    useFilters({ ...identitiesFilterDefaultValues, showActiveOnly: !showInactives })

  const { response, isLoading, count, queryKey } = useGetIdentities({
    offset,
    limit: pageSize,
    filters: { ...filters, ids, showInactive: !filters.showActiveOnly, showActiveOnly: undefined },
    sort: sortModel || 'id',
  })

  useEffect(() => {
    if (!showInactives) return
    onSearchFilter({ ...filters, showActiveOnly: false })
    setBadge(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInactives])

  return (
    <Box width='100%'>
      <ResourceHeader>
        <CountChip label={t('Registros')} labelSingular={t('Registro')} count={count} isLoading={isLoading} />
        <ResourceFilter
          badge={badge}
          label={t`Identificador, nombre, dominio, metadatos ...`}
          openFilter={openFilter}
          onSearchQ={onSearchQ}
          onToggleFilter={onToggleFilter}
        />
        {!type && canWrite && <NewResourceButton label={t('Nueva identidad')} to={routes.identitiesCreate} />}
      </ResourceHeader>
      {!type && canWrite && (
        <Box display='flex' justifyContent='flex-end' columnGap={2}>
          <CsvTemplateButton name='identities_template.csv' template={csvTemplate} />
          <CsvButton onClick={() => setShowCsvModal(true)} />
        </Box>
      )}
      {openFilter && <IdentitiesFilter onSearch={onSearchFilter} showInactives={showInactives} />}
      <IdentitiesTable
        {...props}
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        queryKey={queryKey}
        type={type}
      />
      <ModalUploadCsv onClose={() => setShowCsvModal(false)} open={showCsvModal} />
    </Box>
  )
}

export default IdentitiesList
