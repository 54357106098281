import React from 'react'

import { GridActionsCellItem } from '@mui/x-data-grid'

import { Location } from 'models/locations'

import LocationsTooltip from '../locations-tooltip'

interface Props {
  locations: Location[]
}

const LocationGridActionCellItem: React.FC<Props> = ({ locations }) => (
  <GridActionsCellItem icon={<LocationsTooltip locations={locations} />} label='toggle' />
)

export default LocationGridActionCellItem
