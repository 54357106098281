import React from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import BasicButton from 'components/atoms/basic-button'

interface Props {
  to?: string
  text?: string
}

const CancelButton: React.FC<Props> = ({ to, text }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = () => {
    if (to) {
      navigate(to, { replace: true })
    } else {
      navigate(-1)
    }
  }

  return (
    <BasicButton
      text={text || t('Volver')}
      handleClick={handleClick}
      variant='contained'
      size='small'
      type='button'
      color='warning'
    />
  )
}

export default CancelButton
