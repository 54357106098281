import React, { useMemo } from 'react'

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'

import { useAuth } from 'providers/auth'
import { formatNumber } from 'utils/numberFormatter'

interface Props {
  label: string
  labelSingular: string
  count: number
  isLoading?: boolean
}

const CountChip: React.FC<Props> = ({ label, labelSingular, count, isLoading }) => {
  const { language } = useAuth()
  const text = useMemo(
    () => `${formatNumber(count, language)} ${count === 1 ? labelSingular : label}`,
    [language, count, labelSingular, label]
  )
  return isLoading && count === 0 ? null : (
    <Box>
      <Chip label={text} color='warning' />
    </Box>
  )
}

export default CountChip
