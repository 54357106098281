import React from 'react'

import { Control, Controller, FieldError } from 'react-hook-form'

import { inputsWidth } from 'config/constants'
import { Location } from 'models/locations'
import { Sizes } from 'models/sizes'

import LocationsSearcher from '../locations-searcher'

import { StyledBox } from './styled'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  name: string
  size: Sizes
  label?: string
  disabled?: boolean
  defaultValue?: Partial<Location>
  error?: FieldError
}

const ControllerSelectLocation: React.FC<Props> = ({ control, name, label, defaultValue, error, size, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <StyledBox width={inputsWidth[size]}>
          <LocationsSearcher
            onChange={location => field.onChange(location?.id)}
            name={name}
            defaultValue={defaultValue as Location}
            label={label}
            error={error && { locations: { ...error } }}
            disabled={disabled}
            autoCompleteProps={{ componentsProps: { popper: { style: { width: 'fit-content', maxWidth: '50vw' } } } }}
            renderValue={({ key, type, name: locName }) => `${key} - ${type} - ${locName}`}
          />
        </StyledBox>
      )}
    />
  )
}

export default ControllerSelectLocation
