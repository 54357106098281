import GetRelations, {
  CreateRelation,
  UpdateRelations,
  GetRelationsVersions,
  EditOneRelationIdentities,
  GetRelationIdentities,
} from 'models/relations'
import axios from 'services'
import { metadataToArray } from 'utils/metadataToArray'
import { removeEmpty } from 'utils/removeEmpty'

export const getRelations = ({ filters, ...payload }: GetRelations) => {
  return axios.get('/v1/relations', {
    params: removeEmpty({ ...payload, ...filters, metadata: metadataToArray(filters?.metadata) }),
  })
}

export const getRelationsVersions = ({ id, ...params }: GetRelationsVersions) => {
  return axios.get(`/v1/relations/${id}/versions`, { params })
}

export const createRelation = (payload: CreateRelation) => {
  return axios.post('/v1/relations', payload)
}

export const getOneRelation = (id?: string) => {
  return axios.get(`/v1/relations/${id}`)
}

export const getOneRelationIdentities = ({ id, ...payload }: GetRelationIdentities) => {
  return axios.get(`/v1/relations/${id}/identities`, {
    params: removeEmpty({ ...payload }),
  })
}

export const getOneRelationVersion = (id?: string, version?: string) => {
  return axios.get(`/v1/relations/${id}/versions/${version}`)
}

export const updateOneRelation = (id: string | undefined, payload: UpdateRelations) => {
  return axios.put(`/v1/relations/${id}`, payload)
}

export const updateOneRelationIdentities = (id: string | undefined, payload: EditOneRelationIdentities) => {
  return axios.post(`/v1/relations/${id}/identities`, {}, { params: { ids: [...payload.identityIds] } })
}

export const deleteOneRelationIdentities = (id: string | undefined, payload: EditOneRelationIdentities) => {
  return axios.delete(`/v1/relations/${id}/identities`, { params: { ids: [...payload.identityIds] } })
}

export const uploadRelationIdentitiesCsv = (formData: FormData) => {
  return axios.post('/v1/relations/add-identities', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
