import { useRef, useState } from 'react'

import PersonIcon from '@mui/icons-material/Person'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ButtonBase, Typography } from '@mui/material'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'

import { useClickOutside } from 'hooks/useClickOutside'
import routes from 'config/routes'
import { useAuth } from 'providers/auth'

import * as S from './styled'

const AppBarUser = (): JSX.Element => {
  const { signout, user } = useAuth()
  const navigate = useNavigate()
  const contaienrRef = useRef()
  const { t } = useTranslation()

  const [showList, setShowList] = useState<boolean>(false)

  const hideProfileList = () => setShowList(false)
  const toggleProfileList = () => setShowList(prev => !prev)

  useClickOutside(contaienrRef, hideProfileList)

  const handleLogout = () => {
    signout()
    navigate(routes.login)
  }

  return (
    <S.AppBarUserContainer ref={contaienrRef}>
      <S.AppBarUserSelected onClick={toggleProfileList}>
        <ButtonBase centerRipple sx={{ height: '100%', width: '100%' }}>
          <PersonIcon color='info' />
          <Typography data-testid='username' title={user?.name} textOverflow='ellipsis' overflow='hidden'>
            {user?.name}
          </Typography>
        </ButtonBase>
      </S.AppBarUserSelected>

      {showList && (
        <S.AppBarUserList>
          <S.AppBarUserItem onClick={handleLogout}>
            <PowerSettingsNewIcon />
            {t('Cerrar sesión')}
          </S.AppBarUserItem>
        </S.AppBarUserList>
      )}
    </S.AppBarUserContainer>
  )
}

export default AppBarUser
