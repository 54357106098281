import { useParams } from 'react-router-dom'

import PageContainer from 'components/atoms/page-container'
import RelationsEditForm from 'components/organisms/relations/relations-edit-form'
import useGetOneRelation from 'hooks/queries/relation/useGetOneRelation'
import useGetOneRelationIdentities from 'hooks/queries/relation/useGetOneRelationIdentities'
import { Relation } from 'models/relations'
import useGetRelationIdentitiesCount from 'hooks/queries/relation/useGetRelationsIdentitiesCount'

const RelationsShow = (): JSX.Element => {
  const { id } = useParams()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { isLoading, response } = useGetOneRelation({ id: id! })

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { count, isLoading: isLoadingCount } = useGetRelationIdentitiesCount({ id: id! })

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { isLoading: isLoadingIdentities, identityIds } = useGetOneRelationIdentities({ id: id!, count })

  const relation: Relation = { ...response, identityIds }

  return (
    <PageContainer>
      <RelationsEditForm
        isLoading={isLoading || isLoadingCount || isLoadingIdentities}
        response={relation}
        relationId={id}
        disabled
      />
    </PageContainer>
  )
}

export default RelationsShow
