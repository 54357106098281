import React from 'react'

import Box from '@mui/material/Box/Box'
import { Control, FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import ControllerInputText from '../controller-input-text'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  size?: 'large' | 'medium' | 'small'
  errorLatitude?: FieldError
  errorLongitude?: FieldError
  disabled?: boolean
}

const ControllerCoordinates: React.FC<Props> = ({
  control,
  size = 'large',
  errorLatitude,
  errorLongitude,
  disabled = false,
}) => {
  const { t } = useTranslation()

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <ControllerInputText
        mandatory
        control={control}
        name='coordinate.latitude'
        error={errorLatitude}
        label={t('Latitud')}
        size={size}
        disabled={disabled}
      />
      <ControllerInputText
        mandatory
        control={control}
        name='coordinate.longitude'
        error={errorLongitude}
        label={t('Longitud')}
        size={size}
        disabled={disabled}
      />
    </Box>
  )
}

export default ControllerCoordinates
