import * as yup from 'yup'
import { t } from 'i18next'

import { Metadata } from 'models/metadata'
import { PeriodDate, PeriodTime } from 'models/relations'
import { Coordinate, Location } from 'models/locations'
import { Keys } from 'models/keys'
import { ALPHANUMERIC_REGEX, METADATA_REGEX } from 'config/regex'

import {
  currentDateIsMinor,
  handleKeysValidation,
  isMinorHour,
  isMinorPeriodDate,
  isSimilarHour,
  locationChecker,
} from './utils'

export const relationSchema = yup
  .object({
    identityIds: yup.array().of(yup.number()).min(1),
    groupIds: yup.array().of(yup.number()).min(1),
    periodDate: yup.object({
      from: yup
        .string()
        .required()
        .nullable()
        .when('$periodDateFromDisabled', (periodDateFromDisabled, schema) =>
          periodDateFromDisabled ? schema : schema.test(currentDateIsMinor)
        ),
      to: yup.string().test(isMinorPeriodDate).nullable(true),
    }),
    periodTime: yup.array().of(
      yup.object({
        from: yup.string().required(),
        to: yup.string().required().test(isSimilarHour).test(isMinorHour),
        dayOfWeek: yup.array().of(yup.string()).min(1),
      })
    ),
    locationRestriction: yup.boolean(),
    locations: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          keyType: yup.string().required(),
          key: yup.string().required(),
          name: yup.string().required(),
          parent: yup.string().required(),
          coordinate: yup.object({ longitude: yup.string().required(), latitude: yup.string().required() }),
        })
      )
      .when('locationRestriction', locationChecker),
    keys: yup.array().of(
      yup.object({
        keyType: yup.string().required(),
        key: yup.string().when('keyType', handleKeysValidation),
      })
    ),
    domain: yup.string().required(),
    name: yup.string().required().max(255),
    metadata: yup.array().of(
      yup.object({
        key: yup.string().required().max(255).matches(ALPHANUMERIC_REGEX),
        value: yup.string().required().max(4000, t('Ha superado los 4000 caracteres')).matches(METADATA_REGEX),
      })
    ),
    active: yup.boolean().required(),
    version: yup.number(),
    createDate: yup.string(),
    updateDate: yup.string(),
  })
  .required()

export type RelationFormSchema = yup.InferType<typeof relationSchema>

export interface RelationFormFileds {
  id?: string
  identityIds: Array<number>
  groupIds: Array<number>
  periodDate: PeriodDate
  periodTime: PeriodTime[]
  locationRestriction: boolean
  locations: LocationsGroups[]
  keys: Keys[]
  domain: string
  name: string
  metadata: Metadata[]
  active: boolean
  version?: number
  username: string
  createDate?: string
  updateDate?: string
}

export interface LocationsGroups {
  id: string
  createDate?: string
  updateDate?: string
  deleteDate?: string
  keyType: string
  name?: string
  parent?: Location
  key: string
  coordinate: Coordinate
}

export const relationFilterSchema = yup.object({
  keyType: yup.string(),
  keyValue: yup.string(),
  name: yup.string(),
  domain: yup.string(),
  metadata: yup.string(),
  from: yup.string().nullable(),
  to: yup.string().nullable(),
  showActiveOnly: yup.boolean().default(true),
})

export type RelationFilterFormSchema = yup.InferType<typeof relationFilterSchema>

export interface RelationsFilterFormFields {
  keyType?: string
  keyValue?: string
  name?: string
  domain?: string
  metadata?: string
  from?: string | null
  to?: string | null
  showInactive?: boolean
  showActiveOnly?: boolean
  q?: string
}

export const relationsFilterDefaultValues: RelationsFilterFormFields = {
  keyType: '',
  keyValue: '',
  name: '',
  domain: '',
  metadata: '',
  from: null,
  to: null,
  showActiveOnly: true,
}
