import * as yup from 'yup'

import { Keys } from 'models/keys'

import { handleKeysValidation } from './utils'

export const eventsSchema = yup
  .object({
    username: yup.string(),
    email: yup.string(),
    action: yup.string(),
    resourceType: yup.string(),
    resourceId: yup.number(),
    createDate: yup.string(),
    keys: yup
      .array()
      .of(
        yup.object({
          keyType: yup.string().required(),
          key: yup.string().when('keyType', handleKeysValidation),
        })
      )
      .nullable(),
  })
  .required()

export type EventsFormSchema = yup.InferType<typeof eventsSchema>

export interface EventFormFields {
  id?: number
  username: string
  email: string
  action: string
  resourceType: string
  resourceId?: number
  createDate: string
  keys: Array<Keys>
}

export const eventsFilterSchema = yup.object({
  username: yup.string(),
  action: yup.string(),
  resourceType: yup.string(),
  from: yup.string().nullable(),
  to: yup.string().nullable(),
})

export type EventsFilterFormSchema = yup.InferType<typeof eventsFilterSchema>

export interface EventsFilterFormFields {
  username?: string
  action?: string
  resourceType?: string
  from?: string | null
  to?: string | null
  q?: string
}

export const eventsFilterDefaultValues: EventsFilterFormFields = {
  username: '',
  action: '',
  resourceType: '',
  from: null,
  to: null,
}
