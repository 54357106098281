import React, { useEffect } from 'react'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import routes from 'config/routes'
import useGetGroups from 'hooks/queries/group/useGetGroups'
import GroupsTable from 'components/organisms/groups/groups-table'
import CountChip from 'components/atoms/count-chip'
import NewResourceButton from 'components/atoms/new-resource-button'
import ResourceHeader from 'components/atoms/resource-header'
import ResourceFilter from 'components/molecules/resource-filter'
import { groupsFormDefaultValues } from 'validations/groups'
import GroupsFilter from 'components/organisms/groups/groups-filter'
import { ListProps } from 'models/list'
import usePagination from 'hooks/usePagination'
import useGroupsPermissions from 'hooks/permissions/useGroupsPermissions'
import useFilters from 'hooks/useFilters'

const GroupsList: React.FC<ListProps> = ({ type, ids, showInactives, ...props }) => {
  const { t } = useTranslation()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()
  const { canWrite } = useGroupsPermissions()
  const { sortModel, badge, openFilter, filters, setSortModel, onSearchQ, setBadge, onToggleFilter, onSearchFilter } =
    useFilters({ ...groupsFormDefaultValues, showActiveOnly: !showInactives })

  const { response, isLoading, count, queryKey } = useGetGroups({
    offset,
    limit: pageSize,
    filters: { ...filters, ids, showInactive: !filters.showActiveOnly, showActiveOnly: undefined },
    sort: sortModel || 'id',
  })

  useEffect(() => {
    if (!showInactives) return
    onSearchFilter({ ...filters, showActiveOnly: false })
    setBadge(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInactives])

  return (
    <Box width='100%'>
      <ResourceHeader>
        <CountChip label={t('Registros')} labelSingular={t('Registro')} count={count} isLoading={isLoading} />
        <ResourceFilter
          badge={badge}
          label={t`Identificador, nombre, dominio, metadatos ...`}
          openFilter={openFilter}
          onSearchQ={onSearchQ}
          onToggleFilter={onToggleFilter}
        />

        {!type && canWrite && <NewResourceButton label={t('Nuevo grupo')} to={routes.groupsCreate} />}
      </ResourceHeader>
      {openFilter && <GroupsFilter onSearch={onSearchFilter} showInactives={showInactives} />}
      <GroupsTable
        {...props}
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        queryKey={queryKey}
        type={type}
      />
    </Box>
  )
}

export default GroupsList
