import { useMemo } from 'react'

import { Roles } from 'models/auth.d'
import { useAuth } from 'providers/auth'

const useRelationsPermissions = () => {
  const { user } = useAuth()

  const canWrite = useMemo(() => {
    return !user?.role ||
      (user?.role !== Roles['SUPER-ADMIN'] &&
        user?.role !== Roles.ADMIN &&
        user?.role !== Roles.SEGMENTATION &&
        user?.role !== Roles.OPERATOR)
      ? false
      : true
  }, [user?.role])

  const canRead = true
  return { canWrite, canRead }
}

export default useRelationsPermissions
