import React from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'

import { userFilterDefaultValues, UserFilterFormData, UserFilterFormFields, userFilterSchema } from 'validations/user'
import BasicButton from 'components/atoms/basic-button'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import ControllerSwitch from 'components/molecules/controller-switch'
import { DateFormats, USER_ROLES } from 'config/constants'

import { FilterGridContainer } from './styled'

interface Props {
  onSearch: (search: UserFilterFormFields) => void
}

const UsersFilter: React.FC<Props> = ({ onSearch }) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UserFilterFormData>({
    defaultValues: userFilterDefaultValues,
    resolver: yupResolver(userFilterSchema),
  })

  const onSubmit = (payload: UserFilterFormFields) => {
    onSearch(payload)
  }

  const handleReset = () => {
    onSearch(userFilterDefaultValues)
    reset(userFilterDefaultValues)
  }

  return (
    <FilterGridContainer container pr={1}>
      <Grid item md={4} pr={1}>
        <ControllerInputText control={control} name='name' error={errors.name} label={t('Nombre')} size='full' />
      </Grid>
      <Grid item md={4} pr={1}>
        <ControllerInputText control={control} name='email' error={errors.email} label={t('Email')} size='full' />
      </Grid>
      <Grid item md={4} pr={1}>
        <ControllerInputSelect
          options={USER_ROLES}
          control={control}
          name='role'
          error={errors.role}
          label={t('Rol')}
          size='full'
        />
      </Grid>
      <Grid item md={6} pr={1}>
        <ControllerInputDateTimePicker
          control={control}
          name='from'
          error={errors.from}
          label={t('Desde')}
          size='full'
          pattern={DateFormats.iso8601}
        />
      </Grid>
      <Grid item md={6} pr={1}>
        <ControllerInputDateTimePicker
          control={control}
          name='to'
          error={errors.to}
          label={t('Hasta')}
          size='full'
          pattern={DateFormats.iso8601}
        />
      </Grid>

      <Grid item md={12} display='flex' justifyContent='flex-end' flexDirection='row' gap={3}>
        <ControllerSwitch label={t('Solo activos')} name='showActiveOnly' variant='full' control={control} autoHeight />
        <BasicButton handleClick={handleReset} text={t('Restablecer')} size='small' color='warning' />
        <BasicButton handleClick={handleSubmit(onSubmit)} text={t('Buscar')} size='small' />
      </Grid>
    </FilterGridContainer>
  )
}

export default UsersFilter
