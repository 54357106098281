import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'

interface Props {
  label: string
  to: string
}

const NewResourceButton: React.FC<Props> = ({ label, to }) => (
  <Box>
    <Button component={Link} variant='contained' color='primary' startIcon={<AddIcon />} to={to}>
      {label}
    </Button>
  </Box>
)

export default NewResourceButton
