import { useQuery } from 'react-query'

import { getGroupsVersions } from 'services/groups'
import { Group, UseGetGroupsVersions } from 'models/groups'
import { GET_GROUPS_VERSIONS } from 'config/queries'

interface Args {
  id?: string
  limit: number
  offset: number
  sort: string
}

const useGetGroupsVersions = ({ id, limit, offset, sort }: Args): UseGetGroupsVersions => {
  const queryKey = [GET_GROUPS_VERSIONS, id, limit, offset, sort]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getGroupsVersions({ id, limit, offset, sort }),
    refetchOnMount: true,
  })

  const result: Array<Group> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading }
}

export default useGetGroupsVersions
