import React, { useCallback } from 'react'

import { GridEnrichedColDef } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import routes from 'config/routes'
import BasicTable from 'components/molecules/basic-table'
import ToggleGridActionCellItem from 'components/atoms/toggle-grid-action-cell-item'
import ShowGridActionCellItem from 'components/atoms/show-grid-action-cell-item'
import HistoricGridActionCellItem from 'components/atoms/historic-grid-action-cell-item'
import EditGridActionCellItem from 'components/atoms/edit-grid-action-cell-item'
import { Relation, RelationsQueryKey } from 'models/relations'
import { RowHeight, TableColumnWidth } from 'config/constants'
import LocationGridActionCellItem from 'components/atoms/location-grid-action-cell-item'
import useUpdateRelation from 'hooks/queries/relation/useUpdateRelation'
import useRelationsPermissions from 'hooks/permissions/useRelationsPermissions'
import PeriodDateCell from 'components/atoms/period-date-cell'
import KeysCell from 'components/atoms/keys-cell'
import ChipCell from 'components/atoms/chip-cell'
import TextCell from 'components/atoms/text-cell'
import { periodDateHandler } from 'utils/periodDateHandler'

interface Props {
  rows: Relation[]
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageSizeChange: (pageSize: number) => void
  onPageChange: (page: number) => void
  queryKey?: RelationsQueryKey
}

const RelationsTable: React.FC<Props> = ({ queryKey, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { canWrite } = useRelationsPermissions()
  const updateRelation = useUpdateRelation(queryKey)

  const navigateToEdit = useCallback((id: string) => navigate(routes.relationsEdit.replace(':id', id)), [navigate])
  const navigateToShow = useCallback((id: string) => navigate(routes.relationsShow.replace(':id', id)), [navigate])
  const navigateToHistoric = useCallback(
    (id: string) => navigate(routes.relationsHistoric.replace(':id', id)),
    [navigate]
  )

  const handleToggleRelationActive = useCallback(
    ({ active, domain, periodDate, ...rows }: Relation) => {
      updateRelation.mutate(
        {
          ...rows,
          active: !active,
          domain: domain,
          periodDate: periodDateHandler(periodDate),
        },
        {
          onSuccess: () => {
            toast(t('Relación actualizada'), { type: 'success' })
          },
          onError: () => {
            toast(t('Ha ocurrido un error'), { type: 'error' })
          },
        }
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateRelation]
  )

  const getTableActions = useCallback(
    ({ row }: { row: Relation }) => {
      const actions = [
        <ShowGridActionCellItem key={`${row.id}-show`} onClick={() => navigateToShow(row.id)} />,
        <HistoricGridActionCellItem key={`${row.id}-historic`} onClick={() => navigateToHistoric(row.id)} />,
      ]

      if (canWrite) {
        actions.unshift(
          <ToggleGridActionCellItem
            checked={row.active}
            key={`${row.id}-toggle`}
            onClick={() => handleToggleRelationActive(row)}
          />,
          <EditGridActionCellItem key={`${row.id}-edit`} onClick={() => navigateToEdit(row.id)} />
        )
      }

      return actions
    },
    [canWrite, handleToggleRelationActive, navigateToShow, navigateToEdit, navigateToHistoric]
  )

  const columns: GridEnrichedColDef[] = [
    { field: 'id', headerName: '', flex: TableColumnWidth.xs, headerAlign: 'center', align: 'center' },
    {
      field: 'keys',
      headerName: `${t('Claves')}`,
      flex: TableColumnWidth.large,
      sortable: false,
      renderCell: ({ row }: { row: Relation }) => <KeysCell keys={row.keys} />,
    },
    {
      field: 'name',
      headerName: `${t('Nombre')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Relation }) => <TextCell text={row.name} />,
    },
    {
      field: 'domain',
      headerName: `${t('Dominio')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Relation }) => <ChipCell label={row.domain} />,
    },
    {
      field: 'date',
      headerName: `${t('Validez')}`,
      flex: TableColumnWidth.base,
      sortable: false,
      renderCell: ({ row }: { row: Relation }) => <PeriodDateCell periodDate={row.periodDate} />,
    },
    {
      field: 'locations',
      type: 'actions',
      flex: TableColumnWidth.xs,
      sortable: false,
      getActions: ({ row }: { row: Relation }) => [
        <LocationGridActionCellItem key={`${row.id}-locations`} locations={row.locations} />,
      ],
    },
    {
      field: 'actions',
      type: 'actions',
      flex: TableColumnWidth.large,
      getActions: getTableActions,
    },
  ]

  return (
    <BasicTable
      {...props}
      rowHeight={RowHeight.large}
      columns={columns}
      onRowClick={({ row }) => navigateToShow(row.id)}
    />
  )
}

export default RelationsTable
