import styled from '@emotion/styled'
import { Box } from '@mui/system'
import { IconButton } from '@mui/material'

import { INPUT_BASE_HEIGHT, INPUT_CONTAINER_HEIGHT } from 'config/constants'

interface Props {
  width: string
}

export const StyledBox = styled(Box)`
  position: relative;
  width: ${(props: Props) => props.width};
  height: ${INPUT_CONTAINER_HEIGHT};
  display: flex;
  align-items: flex-start;

  & .MuiFormControl-root {
    width: 100%;
    height: ${INPUT_BASE_HEIGHT};
  }
`

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 0.5rem;
  margin: auto;
  right: 2rem;
`
