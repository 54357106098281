import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

import { colors } from 'config/theme'

const BasicTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.white,
    border: '1px solid #dadde9',
    maxWidth: '40rem',
  },
}))

export default BasicTooltip
