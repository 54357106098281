import { useState } from 'react'

import _debounce from 'lodash.debounce'

import { UserFilterFormFields } from 'validations/user'
import { ApplicationsFilterFormFileds } from 'validations/applications'
import { EventsFilterFormFields } from 'validations/events'
import { GroupActiveIdentitiesFilterFormFileds, GroupFilterFormFileds } from 'validations/groups'
import { IdentityActiveGroupsFilterFormFields, IdentitiesFilterFormFileds } from 'validations/identities'
import { LocationsFilterFormFields } from 'validations/locations'
import { RelationsFilterFormFields } from 'validations/relations'

import useSort from './useSort'

type Filters = UserFilterFormFields &
  ApplicationsFilterFormFileds &
  EventsFilterFormFields &
  GroupFilterFormFileds &
  IdentitiesFilterFormFileds &
  LocationsFilterFormFields &
  RelationsFilterFormFields &
  GroupActiveIdentitiesFilterFormFileds &
  IdentityActiveGroupsFilterFormFields

interface State {
  sortMode: string
  bagde: number
  openFilter: boolean
  filters: Filters
}

const useFilters = (filterDefaultValues: Filters) => {
  const { sortModel, setSortModel } = useSort()

  const [badge, setBadge] = useState<State['bagde']>(0)
  const [openFilter, setOpenFilter] = useState<State['openFilter']>(false)
  const [filters, setFilters] = useState<State['filters']>(filterDefaultValues)

  const onSearchQ = _debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ q: event.target.value })
  }, 500)

  const onSearchFilter = ({ showActiveOnly, ...search }: Filters) => {
    if (showActiveOnly !== undefined) {
      setBadge(Object.values({ ...search, showInactive: !showActiveOnly }).filter(element => !!element).length)
      setFilters(({ q, ...prev }) => ({ ...prev, ...search, showActiveOnly }))
    } else {
      setBadge(Object.values(search).filter(element => !!element).length)
      setFilters(({ q, ...prev }) => ({ ...prev, ...search }))
    }
  }

  const onToggleFilter = () => {
    setOpenFilter(prev => !prev)
    onSearchFilter(filterDefaultValues)
  }

  return {
    sortModel,
    badge,
    openFilter,
    filters,
    setSortModel,
    setBadge,
    setOpenFilter,
    onSearchQ,
    onSearchFilter,
    onToggleFilter,
  }
}

export default useFilters
