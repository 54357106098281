import React from 'react'

import LocationOnIcon from '@mui/icons-material/LocationOn'
import LocationOffIcon from '@mui/icons-material/LocationOff'
import { Box, Tooltip } from '@mui/material'
import { t } from 'i18next'

import { Location } from 'models/locations'

import BasicTooltip from '../basic-tooltip'
import LocationChip from '../location-chip'

interface Args {
  locations: Location[]
}

const LocationsTooltip: React.FC<Args> = ({ locations }) => {
  return locations?.length ? (
    <BasicTooltip
      title={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          {locations.map(location => (
            <LocationChip key={`location-tooltip-${location.id}`} location={location} />
          ))}
        </Box>
      }
    >
      <LocationOnIcon color='primary' />
    </BasicTooltip>
  ) : (
    <Tooltip title={t('Sin localizaciones')}>
      <LocationOffIcon color='disabled' />
    </Tooltip>
  )
}

export default LocationsTooltip
