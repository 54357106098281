import { useMemo } from 'react'

import { useQueries } from 'react-query'

import { getOneRelationIdentities } from 'services/relations'
import { UseGetOneRelationIdentities } from 'models/relations'
import { GET_ONE_RELATION_IDENTITIES } from 'config/queries'
import { Identity } from 'models/identities'
import { RELATIONS_IDENTITIES_LIMIT } from 'config/constants'

interface Args {
  id?: string
  count?: number
}

const useGetOneRelationIdentities = ({ id, count = 0 }: Args): UseGetOneRelationIdentities => {
  const offestList = Array(Math.ceil(count / RELATIONS_IDENTITIES_LIMIT)).fill(true)

  const result = useQueries(
    offestList.map((_, index) => ({
      queryKey: [GET_ONE_RELATION_IDENTITIES, id, index],
      queryFn: () =>
        getOneRelationIdentities({ id, limit: RELATIONS_IDENTITIES_LIMIT, offset: index * RELATIONS_IDENTITIES_LIMIT }),
    }))
  )
    .map(({ data, isLoading, isSuccess, isError }) => ({
      isLoading,
      isSuccess,
      isError,
      data: data?.data as Identity[],
    }))
    .filter(query => query)

  const identityIds = result?.flatMap(query => query.data?.map(identity => parseFloat(identity.id ?? ''))) ?? []
  const isLoading: boolean = result.some(query => query.isLoading)
  const isError: boolean = result.some(query => query.isError)

  const values = useMemo(
    () => ({
      isLoading,
      identityIds,
      isError,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading]
  )
  return values
}

export default useGetOneRelationIdentities
