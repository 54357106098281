import Box from '@mui/material/Box/Box'
import { styled } from '@mui/material/styles'

import { INPUT_BASE_HEIGHT } from 'config/constants'

export const MetadataActionContainer = styled(Box)`
  min-width: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: ${INPUT_BASE_HEIGHT};
`
export const MetadataInputContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  justify-content: center;
  font-size: 0.5rem;
`
