import React from 'react'

import { GridEnrichedColDef } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import routes from 'config/routes'
import BasicTable from 'components/molecules/basic-table'
import { Identity } from 'models/identities'
import { DateFormats, RowHeight, TableColumnWidth } from 'config/constants'
import KeysCell from 'components/atoms/keys-cell'
import TextCell from 'components/atoms/text-cell'
import ChipCell from 'components/atoms/chip-cell'
import { dateFormatter } from 'utils/dateFormatter'

interface Props {
  rows: Identity[]
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageSizeChange: (pageSize: number) => void
  onPageChange: (page: number) => void
}

const GroupsActiveIdentitiesTable: React.FC<Props> = props => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToShow = (id: string) => navigate(routes.identitiesShow.replace(':id', id))

  const columns: GridEnrichedColDef[] = [
    { field: 'id', headerName: '', flex: TableColumnWidth.xs, headerAlign: 'center', align: 'center' },
    {
      field: 'keys',
      headerName: `${t('Claves')}`,
      flex: TableColumnWidth.large,
      sortable: false,
      renderCell: ({ row }: { row: Identity }) => <KeysCell keys={row.keys} />,
    },
    {
      field: 'name',
      headerName: `${t('Nombre')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: Identity }) => <TextCell text={row.name} />,
    },
    {
      field: 'domain',
      headerName: `${t('Dominio')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: Identity }) => <ChipCell label={row?.domain?.name} />,
    },
    {
      field: 'date',
      headerName: `${t('Creación')}`,
      flex: TableColumnWidth.base,
      sortable: false,
      renderCell: ({ row }: { row: Identity }) => (
        <TextCell text={dateFormatter({ date: row.createDate, pattern: DateFormats.day })} />
      ),
    },
  ]

  return (
    <BasicTable
      {...props}
      rowHeight={RowHeight.large}
      columns={columns}
      onRowClick={({ row }) => navigateToShow(row.id)}
    />
  )
}

export default GroupsActiveIdentitiesTable
