export const GET_ME = 'getMe'

export const GET_USERS = 'getUsers'
export const GET_ONE_USER = 'getOneUser'

export const GET_GROUPS = 'getGroups'
export const GET_ONE_GROUP = 'getOneGroup'
export const GET_GROUPS_VERSIONS = 'getGroupsVersions'
export const GET_ONE_GROUP_VERSION = 'getOneGroupVersion'
export const GET_ONE_GROUP_ACTIVE_IDENTITIES = 'getOneGroupActiveIdentities'
export const GET_ONE_GROUP_RELATIONS = 'getOneGroupRelations'

export const GET_DOMAINS = 'getDomains'

export const GET_LOCATIONS = 'getLocations'
export const GET_ONE_LOCATION = 'getOneLocation'
export const GET_CARPARKS = 'getCarparks'

export const GET_IDENTITIES = 'getIdentities'
export const GET_ONE_IDENTITY = 'getOneIdentity'
export const GET_IDENTITIES_VERSIONS = 'getIdentitiesVersions'
export const GET_ONE_IDENTITY_VERSION = 'getOneIdentityVersion'
export const GET_ONE_IDENTITY_ACTIVE_GROUPS = 'getOneIdentityActiveGroups'

export const GET_APPLICATIONS = 'getApplications'
export const GET_ONE_APPLICATION = 'getOneApplication'
export const GET_APPLICATION_TOKEN = 'getApplicationToken'

export const GET_RELATIONS = 'getRelations'
export const GET_ONE_RELATION = 'getOneRelation'
export const GET_RELATIONS_VERSIONS = 'getRelationsVersions'
export const GET_ONE_RELATION_VERSION = 'getOneRelationVersion'
export const GET_ONE_RELATION_IDENTITIES = 'getOneRelationIdentities'

export const GET_EVENTS = 'getEvents'
export const GET_ONE_EVENT = 'getOneEvent'
