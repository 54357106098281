import React from 'react'

import { Box } from '@mui/system'

interface Props {
  children?: React.ReactNode
  index: number
  value: number
}

const RelationTabConatiner: React.FC<Props> = ({ children, index, value }) => {
  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      width='100%'
    >
      {value === index && (
        <Box
          display='flex'
          flexDirection='column'
          paddingX={1}
          paddingY={5}
          gap={5}
          alignItems='center'
          justifyContent='center'
          width='100%'
        >
          {children}
        </Box>
      )}
    </Box>
  )
}

export default RelationTabConatiner
