import { useQuery } from 'react-query'

import { getOneRelationVersion } from 'services/relations'
import { Relation, UseGetOneRelationVersion } from 'models/relations'
import { GET_ONE_RELATION_VERSION } from 'config/queries'

interface Args {
  id?: string
  version?: string
}

const useGetOneRelationVersion = ({ id, version }: Args): UseGetOneRelationVersion => {
  const queryKey = [GET_ONE_RELATION_VERSION, id, version]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneRelationVersion(id, version),
    refetchOnMount: true,
    staleTime: 0,
  })

  const result: Relation = response?.data

  return { response: result, isLoading }
}

export default useGetOneRelationVersion
