import { useParams } from 'react-router-dom'

import PageContainer from 'components/atoms/page-container'
import ApplicationsEditForm from 'components/organisms/applications/applications-edit-form'
import useGetOneApplication from 'hooks/queries/application/useGetOneApplication'

const ApplicationsShow = (): JSX.Element => {
  const { id } = useParams()
  const { isLoading, response } = useGetOneApplication({ id })
  return (
    <PageContainer>
      <ApplicationsEditForm isLoading={isLoading} data={response} id={id} disabled />
    </PageContainer>
  )
}

export default ApplicationsShow
