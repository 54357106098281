import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/system'

import PageTitle from 'components/atoms/page-title'
import AdministrationTabs from 'components/molecules/administration-tabs'

const AdministrationLayout = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <PageTitle title={t('administración')} />
      <AdministrationTabs />
      <Box paddingTop='2.1em'>
        <Outlet />
      </Box>
    </>
  )
}

export default AdministrationLayout
