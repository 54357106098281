import { QueryKey, useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Group, UpdateGroups } from 'models/groups'
import { GET_GROUPS, GET_GROUPS_VERSIONS, GET_LOCATIONS } from 'config/queries'
import { updateOneGroup } from 'services/groups'

const useUpdateGroup = (queryKey?: QueryKey) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const updateGroup = useMutation({
    mutationFn: ({ id, ...payload }: UpdateGroups) => updateOneGroup(id, payload),
    onSuccess: () => {
      toast(t('Grupo actualizado'), { type: 'success' })
    },
    onMutate: async newValue => {
      await queryClient.cancelQueries({ queryKey: GET_GROUPS })
      const previous = queryClient.getQueryData<{ data: Group[] }>(queryKey ?? [GET_GROUPS])
      const group = previous?.data.find(o => o.id === newValue.id)
      if (group) {
        group.active = newValue.active
      }
      return { previous }
    },
    onError: (error, newValue, context) => {
      toast(t('Ha ocurrido un error'), { type: 'error' })
      queryClient.setQueryData([GET_GROUPS], context?.previous)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [GET_GROUPS] })
      queryClient.invalidateQueries({ queryKey: [GET_GROUPS_VERSIONS] })
      queryClient.invalidateQueries({ queryKey: [GET_LOCATIONS] })
    },
  })

  return updateGroup
}

export default useUpdateGroup
