import { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import InputAdornment from '@mui/material/InputAdornment'
import { toast } from 'react-toastify'

import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import SpinnerLoading from 'components/atoms/spinner-loading'
import SubmitButton from 'components/atoms/submit-button'
import routes from 'config/routes'
import { ApplicationFormFields, ApplicationsFormSchema, applicationsSchema } from 'validations/applications'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import { APPLICATIONS_ROLES } from 'config/constants'
import ControllerSwitch from 'components/molecules/controller-switch'
import CopyIconButton from 'components/atoms/copy-icon-button'
import { Application, UpdateApplication } from 'models/applications'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import useUpdateApplication from 'hooks/queries/application/useUpdateApplication'
import GenerateTokenButton from 'components/molecules/generate-token-button'
import useFormErrorScroll from 'hooks/useFormErrorScroll'

import { ApplicationGridItem, ApplicationGridContainer, StyledContainer } from './styled'

const defaultValues: ApplicationFormFields = {
  name: '',
  role: '',
  active: true,
  token: '',
}

interface Props {
  data: Application
  isLoading: boolean
  id?: string
  disabled?: boolean
}

const ApplicationsEditForm = ({ data, isLoading, id, disabled }: Props): JSX.Element => {
  const [showPasteButton, setShowPasteButton] = useState<boolean>(false)
  const { t } = useTranslation()
  const updateApplication = useUpdateApplication()
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm<ApplicationsFormSchema>({
    defaultValues,
    resolver: yupResolver(applicationsSchema),
  })

  useFormErrorScroll(errors)

  useEffect(() => {
    if (!data) return

    reset({
      name: data.name,
      token: data.enmaskedToken,
      active: data.active,
      role: data.role,
      lastLogin: data.lastLogin,
      createDate: data.createDate,
    })
  }, [data, reset])

  const onSubmit = ({ lastLogin, ...payload }: ApplicationFormFields): void => {
    const result: UpdateApplication = {
      id,
      renewToken: false,
      ...payload,
    }
    updateApplication.mutate(result)
    navigate(routes.applicationsList)
  }
  const handleCopyTokenButton = () => {
    const result = getValues('token')
    if (result) {
      navigator.clipboard.writeText(result)
      toast(t('Token copiado en el portapapeles'), { type: 'success' })
    }
  }

  const handleGenerateToken = (token: string) => {
    setValue('token', token)
    if (!showPasteButton) setShowPasteButton(true)
    handleCopyTokenButton()
  }

  const lastLoginDate = watch('lastLogin')

  if (isLoading) {
    return <SpinnerLoading minHeight={420} />
  }

  return (
    <Grid component='form' onSubmit={handleSubmit(onSubmit)} data-testid='applications-edit-form'>
      <ApplicationGridContainer container spacing={2}>
        <ApplicationGridItem item md={6} xs={12}>
          <ControllerInputSelect
            mandatory
            control={control}
            name='role'
            error={errors.role}
            label={t('Rol')}
            size='full'
            options={APPLICATIONS_ROLES}
            disabled={disabled}
          />
          <ControllerInputText
            mandatory
            control={control}
            name='name'
            error={errors.name}
            label={t('Nombre')}
            size='full'
            disabled={disabled}
          />
          <StyledContainer>
            <ControllerInputText
              mandatory
              control={control}
              name='token'
              error={errors.token}
              label={t('Token')}
              size='full'
              disabled
              inputProps={
                showPasteButton
                  ? {
                      endAdornment: (
                        <InputAdornment position='end'>
                          <CopyIconButton handleClick={handleCopyTokenButton} />
                        </InputAdornment>
                      ),
                    }
                  : undefined
              }
            />
            {!disabled && data && <GenerateTokenButton appData={data} onChangeToken={handleGenerateToken} edit />}
          </StyledContainer>
        </ApplicationGridItem>
        <ApplicationGridItem item md={6} xs={12}>
          <ControllerSwitch label={t('Activo')} name='active' variant='full' control={control} disabled={disabled} />
          <ControllerInputDateTimePicker
            label={t('Fecha Alta')}
            name='createDate'
            control={control}
            error={errors.createDate}
            size='full'
            disabled
          />
          <ControllerInputDateTimePicker
            label={lastLoginDate ? t('Fecha último login') : t('No se ha realizado ningún login')}
            name='lastLogin'
            control={control}
            error={errors.lastLogin}
            size='full'
            disabled
          />
        </ApplicationGridItem>
      </ApplicationGridContainer>
      <FormButtonsContainer>
        <CancelButton to={routes.applicationsList} />
        <SubmitButton />
      </FormButtonsContainer>
    </Grid>
  )
}

export default ApplicationsEditForm
