import React, { ReactNode } from 'react'

import { UseFormGetValues } from 'react-hook-form'
import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { RelationFormFileds } from 'validations/relations'
import { colors } from 'config/theme'
import { DAY_LIST } from 'config/constants'
import { DayList } from 'models/dates'

interface Props {
  getValues: UseFormGetValues<RelationFormFileds>
}

const RelationFormResume: React.FC<Props> = ({ getValues }) => {
  const { t } = useTranslation()
  const name = getValues('name')
  const groupsIds = getValues('groupIds')
  const identityIds = getValues('identityIds')
  const periodTime = getValues('periodTime')
  const metadata = getValues('metadata')

  const handleSelectRenderValue = (selection: string[] | string): ReactNode => {
    const ordererDays = DAY_LIST.filter(({ value }: DayList) => selection.includes(value))
    return ordererDays.map(day => day.iso).join(', ')
  }
  return (
    <Grid container sx={{ width: '60%' }}>
      <Grid item xs={6}>
        <Typography color={colors.orange}>{t('NOMBRE')}</Typography>
        <Typography color={colors.orange}>{t('Nº IDENTIDADES A ASOCIAR')}</Typography>
        <Typography color={colors.orange}>{t('Nº GRUPOS A ASOCIAR')}</Typography>
        <Typography color={colors.orange}>{t('METADATOS')}</Typography>
        <Typography color={colors.orange}>{t('PERIODO RELACIÓN')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography noWrap>{name || '-'}</Typography>
        <Typography noWrap>{identityIds?.length || '-'}</Typography>
        <Typography noWrap>{groupsIds?.length || '-'}</Typography>
        <Typography noWrap>{metadata?.length || '-'}</Typography>
        <Box display='flex' flexDirection='column'>
          {periodTime.length
            ? periodTime.map(({ from, to, dayOfWeek }) => (
                <Typography noWrap component={'span'} key={`relation-periodtime-${from} - ${to} - ${dayOfWeek}`}>
                  {`${from} - ${to} (${handleSelectRenderValue(dayOfWeek)})`}
                </Typography>
              ))
            : '-'}
        </Box>
      </Grid>
    </Grid>
  )
}

export default RelationFormResume
