import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import routes from 'config/routes'
import useGetUsers from 'hooks/queries/user/useGetUsers'
import UserTable from 'components/organisms/users/users-table'
import CountChip from 'components/atoms/count-chip'
import NewResourceButton from 'components/atoms/new-resource-button'
import ResourceHeader from 'components/atoms/resource-header'
import ResourceFilter from 'components/molecules/resource-filter'
import UsersFilter from 'components/organisms/users/users-filter'
import { userFilterDefaultValues } from 'validations/user'
import usePagination from 'hooks/usePagination'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/useAdministrationPermissions'
import useFilters from 'hooks/useFilters'

const UsersList = (): JSX.Element => {
  const { t } = useTranslation()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()
  const { canWrite } = useAdministrationPermissions(AdministrationSections.users)
  const { sortModel, badge, openFilter, filters, setSortModel, onSearchQ, onSearchFilter, onToggleFilter } =
    useFilters(userFilterDefaultValues)

  const { response, isLoading, count, queryKey } = useGetUsers({
    offset,
    limit: pageSize,
    filters: { ...filters, showInactive: !filters.showActiveOnly, showActiveOnly: undefined },
    sort: sortModel || 'id',
  })

  return (
    <Box>
      <ResourceHeader>
        <CountChip label={t('usuarios')} labelSingular={t('usuario')} count={count} isLoading={isLoading} />
        <ResourceFilter
          badge={badge}
          label={t`Nombre, email, rol, último acceso ...`}
          openFilter={openFilter}
          onSearchQ={onSearchQ}
          onToggleFilter={onToggleFilter}
        />
        {canWrite && <NewResourceButton label={t('Nuevo usuario')} to={routes.usersCreate} />}
      </ResourceHeader>
      {openFilter && <UsersFilter onSearch={onSearchFilter} />}
      <UserTable
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        queryKey={queryKey}
      />
    </Box>
  )
}

export default UsersList
