import * as React from 'react'

import dayjs, { Dayjs } from 'dayjs'
import TextField from '@mui/material/TextField'
import { TimePicker } from '@mui/x-date-pickers'

import { DateFormats, inputsWidth } from 'config/constants'
import { Sizes } from 'models/sizes'
import { dateFormatter } from 'utils/dateFormatter'
import { ControllerField } from 'models/form'

import { StyledBox } from './styled'

interface Props {
  disabled?: boolean
  label: string
  field: ControllerField
  size?: Sizes
  helperText?: string
  error?: boolean
  mandatory?: boolean
}

const BasicTimePicker: React.FC<Props> = ({
  disabled,
  label,
  field: { value, onChange, ...rest },
  size = 'medium',
  error,
  helperText,
  mandatory,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const handleOnTimeChange = (date: Dayjs | null) => {
    onChange(dateFormatter({ date, pattern: DateFormats.hour }))
  }

  return (
    <StyledBox width={inputsWidth[size]}>
      <TimePicker
        {...rest}
        value={dayjs(value as string, DateFormats.hour)}
        label={mandatory ? `${label} *` : label}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onChange={handleOnTimeChange}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            error={error}
            helperText={helperText}
            autoComplete={'off'}
            inputProps={{ ...params.inputProps, readOnly: true }}
            onClick={() => setIsOpen(true)}
          />
        )}
        disabled={disabled}
      />
    </StyledBox>
  )
}

export default BasicTimePicker
