import { useQuery } from 'react-query'

import { getRelationsVersions } from 'services/relations'
import { Relation, UseGetRelationsVersions } from 'models/relations'
import { GET_RELATIONS_VERSIONS } from 'config/queries'

interface Args {
  id?: string
  limit: number
  offset: number
  sort: string
}

const useGetRelationsVersions = ({ id, limit, offset, sort }: Args): UseGetRelationsVersions => {
  const queryKey = [GET_RELATIONS_VERSIONS, id, limit, offset, sort]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getRelationsVersions({ id, limit, offset, sort }),
    refetchOnMount: true,
  })

  const result: Array<Relation> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading }
}

export default useGetRelationsVersions
