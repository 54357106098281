import { useMutation, useQueryClient } from 'react-query'

import { EditOneRelationIdentities } from 'models/relations'
import {
  GET_RELATIONS,
  GET_RELATIONS_VERSIONS,
  GET_LOCATIONS,
  GET_ONE_RELATION_IDENTITIES,
  GET_ONE_RELATION,
  GET_IDENTITIES,
} from 'config/queries'
import { updateOneRelationIdentities } from 'services/relations'

interface Props {
  id?: string
}

const useUpdateOneRelationIdentities = ({ id: relationId }: Props) => {
  const queryClient = useQueryClient()
  const updateRelationIdentities = useMutation({
    mutationFn: ({ id, ...payload }: EditOneRelationIdentities) => updateOneRelationIdentities(id, payload),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [GET_RELATIONS] })
      queryClient.invalidateQueries({ queryKey: [GET_RELATIONS_VERSIONS] })
      queryClient.invalidateQueries({ queryKey: [GET_LOCATIONS] })
      queryClient.removeQueries(GET_IDENTITIES)
      queryClient.removeQueries([GET_ONE_RELATION_IDENTITIES, relationId])
      queryClient.removeQueries([GET_ONE_RELATION, relationId])
    },
  })

  return updateRelationIdentities
}

export default useUpdateOneRelationIdentities
