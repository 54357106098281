import { QueryKey, useMutation, useQueryClient } from 'react-query'

import { Relation, UpdateRelations } from 'models/relations'
import { GET_RELATIONS, GET_RELATIONS_VERSIONS, GET_ONE_RELATION } from 'config/queries'
import { updateOneRelation } from 'services/relations'

const useUpdateRelation = (queryKey?: QueryKey) => {
  const queryClient = useQueryClient()
  const updateRelation = useMutation({
    mutationFn: ({ id, ...payload }: UpdateRelations) => updateOneRelation(id, payload),
    onMutate: async newValue => {
      await queryClient.cancelQueries({ queryKey: GET_RELATIONS })
      const previous = queryClient.getQueryData<{ data: Relation[] }>(queryKey ?? [GET_RELATIONS])
      const relation = previous?.data.find(o => o.id === newValue.id)

      if (relation) {
        relation.active = newValue.active
      }
      return { previous }
    },
    onError: (error, newValue, context) => {
      queryClient.setQueryData([GET_RELATIONS], context?.previous)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [GET_RELATIONS] })
      queryClient.invalidateQueries({ queryKey: [GET_RELATIONS_VERSIONS] })
      queryClient.invalidateQueries({ queryKey: [GET_ONE_RELATION] })
    },
  })

  return updateRelation
}

export default useUpdateRelation
