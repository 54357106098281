import React from 'react'

import RefreshIcon from '@mui/icons-material/Refresh'
import IconButton from '@mui/material/IconButton'

interface Args {
  handleClick?: React.MouseEventHandler<HTMLButtonElement>
}

const RefreshIconButton: React.FC<Args> = ({ handleClick }) => (
  <IconButton sx={{ padding: '4px' }} onClick={handleClick}>
    <RefreshIcon />
  </IconButton>
)

export default RefreshIconButton
