import React, { useCallback } from 'react'

import { GridEnrichedColDef, GridSelectionModel } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import routes from 'config/routes'
import BasicTable from 'components/molecules/basic-table'
import ToggleGridActionCellItem from 'components/atoms/toggle-grid-action-cell-item'
import HistoricGridActionCellItem from 'components/atoms/historic-grid-action-cell-item'
import EditGridActionCellItem from 'components/atoms/edit-grid-action-cell-item'
import { IdentitiesQueryKey, Identity } from 'models/identities'
import { DateFormats, RowHeight, TableColumnWidth, TableTypes } from 'config/constants'
import useIdentitiesPermissions from 'hooks/permissions/useIdentitiesPermissions'
import useUpdateIdentity from 'hooks/queries/identity/useUpdateIdentity'
import KeysCell from 'components/atoms/keys-cell'
import { dateFormatter } from 'utils/dateFormatter'
import ShowGridActionCellItem from 'components/atoms/show-grid-action-cell-item'
import TextCell from 'components/atoms/text-cell'
import ChipCell from 'components/atoms/chip-cell'

interface Props {
  type?: TableTypes
  rows: Identity[]
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageSizeChange: (pageSize: number) => void
  onPageChange: (page: number) => void
  queryKey: IdentitiesQueryKey
  onSelectionModelChange?: (selectionModel: GridSelectionModel) => void
  selectionModel?: GridSelectionModel
  isSortable?: boolean
}

const IdentitiesTable: React.FC<Props> = ({ queryKey, type, isSortable = true, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { canWrite } = useIdentitiesPermissions()

  const updateIdentity = useUpdateIdentity(queryKey)

  const navigateToEdit = useCallback((id: string) => navigate(routes.identitiesEdit.replace(':id', id)), [navigate])
  const navigateToShow = useCallback(
    (id: string) => !type && navigate(routes.identitiesShow.replace(':id', id)),
    [navigate, type]
  )
  const navigateToHistoric = useCallback(
    (id: string) => navigate(routes.identitiesHistoric.replace(':id', id)),
    [navigate]
  )

  const handleToggleIdentityActive = useCallback(
    ({ active, domain, ...rows }: Identity) => {
      updateIdentity.mutate({
        ...rows,
        active: !active,
        domain: domain.name,
      })
    },
    [updateIdentity]
  )

  const getTableActions = useCallback(
    ({ row }: { row: Identity }) => {
      const actions = [
        <ShowGridActionCellItem key={`${row.id}-show`} onClick={() => navigateToShow(row.id)} />,
        <HistoricGridActionCellItem key={`${row.id}-historic`} onClick={() => navigateToHistoric(row.id)} />,
      ]

      if (canWrite) {
        actions.unshift(
          <ToggleGridActionCellItem
            checked={row.active}
            key={`${row.id}-toggle`}
            onClick={() => handleToggleIdentityActive(row)}
          />,
          <EditGridActionCellItem key={`${row.id}-edit`} onClick={() => navigateToEdit(row.id)} />
        )
      }

      return actions
    },
    [canWrite, handleToggleIdentityActive, navigateToEdit, navigateToHistoric, navigateToShow]
  )

  const columns: GridEnrichedColDef[] = [
    {
      field: 'id',
      headerName: '',
      flex: TableColumnWidth.xs,
      headerAlign: 'center',
      align: 'center',
      sortable: isSortable,
    },
    {
      field: 'keys',
      headerName: `${t('Claves')}`,
      flex: TableColumnWidth.large,
      sortable: false,
      renderCell: ({ row }: { row: Identity }) => <KeysCell keys={row.keys} />,
    },
    {
      field: 'name',
      headerName: `${t('Nombre')}`,
      flex: TableColumnWidth.large,
      sortable: isSortable,
      renderCell: ({ row }: { row: Identity }) => <TextCell text={row.name} />,
    },
    {
      field: 'domain',
      headerName: `${t('Dominio')}`,
      flex: TableColumnWidth.large,
      sortable: isSortable,
      renderCell: ({ row }: { row: Identity }) => <ChipCell label={row?.domain?.name} />,
    },
    {
      field: 'createDate',
      headerName: `${t('Creación')}`,
      flex: TableColumnWidth.small,
      sortable: isSortable,
      renderCell: ({ row }: { row: Identity }) => (
        <TextCell text={dateFormatter({ date: row.createDate, pattern: DateFormats.day })} />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      flex: TableColumnWidth.large,
      sortable: false,
      getActions: getTableActions,
    },
  ]

  const selectionActions: GridEnrichedColDef = {
    field: 'actions',
    type: 'actions',
    flex: TableColumnWidth.small,
    getActions: ({ row }: { row: Identity }) => [
      <ToggleGridActionCellItem checked={row.active} key={`${row.id}-toggle`} onClick={() => {}} />,
    ],
  }

  const selectionColums: GridEnrichedColDef[] = columns.map((column: GridEnrichedColDef) =>
    column.field !== 'actions' ? column : selectionActions
  )

  return (
    <BasicTable
      {...props}
      type={type}
      rowHeight={RowHeight.large}
      columns={type ? selectionColums : columns}
      onRowClick={({ row }) => navigateToShow(row.id)}
    />
  )
}

export default IdentitiesTable
