import { FC } from 'react'

import { Box, Button } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { useTranslation } from 'react-i18next'

import { buttonsWidth } from 'config/constants'

interface CsvTemplateButtonProps {
  template: string
  name: string
}

const CsvTemplateButton: FC<CsvTemplateButtonProps> = ({ template, name }) => {
  const { t } = useTranslation()

  return (
    <Box display='flex' justifyContent='flex-end' marginBottom='1em'>
      <a href={template} target='_blank' rel='noreferrer' download={name}>
        <Button variant='contained' startIcon={<FileDownloadIcon />} sx={{ width: buttonsWidth.large }}>
          {t`Descargar plantilla CSV`}
        </Button>
      </a>
    </Box>
  )
}

export default CsvTemplateButton
