import { useParams } from 'react-router-dom'

import PageContainer from 'components/atoms/page-container'
import IdentitiesEditForm from 'components/organisms/identities/identities-edit-form'
import useGetOneIdentity from 'hooks/queries/identity/useGetOneIdentity'

const IdentitiesShow = (): JSX.Element => {
  const { id } = useParams()
  const { isLoading, response } = useGetOneIdentity({ id })
  return (
    <PageContainer>
      <IdentitiesEditForm isLoading={isLoading} response={response} id={id} disabled />
    </PageContainer>
  )
}

export default IdentitiesShow
