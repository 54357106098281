import React, { useState } from 'react'

import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import useUploadRelationIdentitiesCsv from 'hooks/queries/relation/useUploadRelationIdentitiesCsv'
import { useAuth } from 'providers/auth'
import BasicButton from 'components/atoms/basic-button'

import UploadCsv from '../upload-csv'

import { StyledDialog } from './styled'

interface Props {
  open: boolean
  onClose: () => void
}

const ModalUploadCsv: React.FC<Props> = ({ open, onClose }) => {
  const [csvFile, setCsvFile] = useState<Blob | null>(null)
  const { t } = useTranslation()
  const { user } = useAuth()
  const uploadRelationIdentitiesCsv = useUploadRelationIdentitiesCsv()

  const handleOnChangeFile = (file: Blob | null) => {
    setCsvFile(file as Blob)
  }

  const handleCsvFormData = () => {
    const formData = new FormData()
    if (!csvFile || !user?.email) {
      toast(t('Ha ocurrido un error en la subida del CSV'), { type: 'error' })
      return formData
    }
    formData.append('file', csvFile as Blob)
    formData.append('email', user?.email as string)
    return formData
  }

  const handleOnClose = () => {
    setCsvFile(null)
    onClose()
  }

  const handleOnConfirm = async () => {
    try {
      await uploadRelationIdentitiesCsv.mutateAsync({ formData: handleCsvFormData() })
      toast(t('Estamos procesando tu fichero y recibirás un email cuando terminemos'), { type: 'success' })
    } catch (error) {
      toast(t('Ha ocurrido un error en la subida del CSV'), { type: 'error' })
    } finally {
      handleOnClose()
    }
  }
  return (
    <StyledDialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle id='alert-dialog-title'>{t`Subir fichero CSV`}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{t`Seleccione el fichero que desea subir`}</DialogContentText>
        <UploadCsv onChangeFile={handleOnChangeFile} file={csvFile as File} />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <BasicButton handleClick={handleOnClose} variant='text' text={t`Cancelar`} />
        <BasicButton
          handleClick={handleOnConfirm}
          variant='contained'
          disabled={!csvFile}
          text={t`Confirmar`}
          loading={uploadRelationIdentitiesCsv.isLoading}
        />
      </DialogActions>
    </StyledDialog>
  )
}

export default ModalUploadCsv
