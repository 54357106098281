import React from 'react'

import { Control, Controller, FieldError } from 'react-hook-form'
import { Dayjs } from 'dayjs'

import BasicDateTimePicker from 'components/atoms/basic-datetime-picker'
import { Sizes } from 'models/sizes'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  error?: FieldError
  disabled?: boolean
  name: string
  label: string
  size?: Sizes
  mandatory?: boolean
  minDate?: Dayjs
  pattern?: string
}

const ControllerInputDateTimePicker: React.FC<Props> = ({
  control,
  disabled,
  name,
  label,
  error,
  mandatory = false,
  size = 'medium',
  minDate,
  pattern,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <BasicDateTimePicker
          mandatory={mandatory}
          error={!!error}
          helperText={error && error.message}
          field={field}
          label={label}
          disabled={disabled}
          size={size}
          minDate={minDate}
          pattern={pattern}
        />
      )}
    />
  )
}

export default ControllerInputDateTimePicker
