import React from 'react'

import Button from '@mui/material/Button/Button'
import CircularProgress from '@mui/material/CircularProgress'

import { buttonsWidth } from 'config/constants'
import { colors } from 'config/theme'
import { Sizes } from 'models/sizes'

interface Props {
  type?: 'button' | 'submit' | 'reset'
  variant?: 'text' | 'contained' | 'outlined'
  size?: Sizes
  handleClick?: React.MouseEventHandler<HTMLButtonElement>
  text: string
  color?: 'primary' | 'error' | 'secondary' | 'success' | 'info' | 'warning' | 'inherit'
  disabled?: boolean
  loading?: boolean
}

const BasicButton: React.FC<Props> = ({
  type = 'submit',
  variant = 'contained',
  size = 'medium',
  text,
  color = 'primary',
  handleClick,
  disabled,
  loading,
}) => {
  return (
    <Button
      onClick={handleClick}
      color={color}
      type={type}
      variant={variant}
      sx={{ width: buttonsWidth[size] }}
      disabled={disabled}
    >
      {text}
      {loading && <CircularProgress size={20} sx={{ marginLeft: 1, color: colors.darkGrey }} />}
    </Button>
  )
}

export default BasicButton
