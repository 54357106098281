import React from 'react'

import { Typography } from '@mui/material'

import { FontFamily, FontSizes } from 'config/constants'

interface Args {
  text: string
  fontSize?: FontSizes
}
const FormSubtitle: React.FC<Args> = ({ text, fontSize = FontSizes.base }) => {
  return (
    <Typography variant='h4' fontSize={fontSize} fontFamily={FontFamily.light}>
      {text}
    </Typography>
  )
}

export default FormSubtitle
