import {
  CreateGroups,
  GetGroups,
  UpdateGroups,
  GetGroupsVersions,
  GetOneGroupActiveIdentities,
  GetOneGroupRelations,
} from 'models/groups'
import axios from 'services'
import { metadataToArray } from 'utils/metadataToArray'
import { removeEmpty } from 'utils/removeEmpty'

export const getGroups = ({ filters, ...payload }: GetGroups) => {
  return axios.get('/v1/groups', {
    params: removeEmpty({ ...payload, ...filters, metadata: metadataToArray(filters?.metadata) }),
  })
}

export const getGroupsVersions = ({ id, ...params }: GetGroupsVersions) => {
  return axios.get(`/v1/groups/${id}/versions`, { params })
}

export const createGroup = (payload: CreateGroups) => {
  return axios.post('/v1/groups', payload)
}

export const getOneGroup = (id?: string) => {
  return axios.get(`/v1/groups/${id}`)
}

export const getOneGroupVersion = (id?: string, version?: string) => {
  return axios.get(`/v1/groups/${id}/versions/${version}`)
}

export const updateOneGroup = (id: string | undefined, payload: UpdateGroups) => {
  return axios.put(`/v1/groups/${id}`, payload)
}

export const getOneGroupActiveIdentities = ({ id, filters, ...payload }: GetOneGroupActiveIdentities) => {
  return axios.get(`/v1/groups/${id}/active-identities`, {
    params: removeEmpty({ ...payload, ...filters, compact: false }),
  })
}

export const getOneGroupRelations = ({ id, ...params }: GetOneGroupRelations) => {
  return axios.get(`/v1/groups/${id}/relations`, { params })
}
