import React from 'react'

import { Chip, Typography } from '@mui/material'

import { Location } from 'models/locations'
import { FontFamily, FontSizes } from 'config/constants'

import { LocationChipContainer } from './styled'

interface Props {
  location: Location
  disabled?: boolean
  onDelete?: (index: number) => void
}

const LocationChip: React.FC<Props> = ({ location: { key, name }, disabled, onDelete }) => {
  return (
    <Chip
      variant='outlined'
      disabled={disabled}
      onDelete={onDelete && !disabled ? onDelete : undefined}
      label={
        <LocationChipContainer>
          <Typography noWrap fontWeight='700' fontSize={FontSizes.base}>
            {key}
          </Typography>
          <Typography noWrap fontFamily={FontFamily.light} fontSize={FontSizes.base}>
            {name}
          </Typography>
        </LocationChipContainer>
      }
    />
  )
}

export default LocationChip
