import { Outlet } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import AppBar from 'components/organisms/app-bar'
import Footer from 'components/organisms/footer'

const MainLayout = (): JSX.Element => (
  <div>
    <AppBar />
    <Grid container>
      <Grid item width='64em' margin='0 auto'>
        <Outlet />
      </Grid>
    </Grid>
    <Footer />
  </div>
)

export default MainLayout
