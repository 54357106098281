import { useQuery } from 'react-query'

import { getGroups } from 'services/groups'
import { Group, UseGetGroups } from 'models/groups'
import { GET_GROUPS } from 'config/queries'
import { GroupFilterFormFileds } from 'validations/groups'

interface Args {
  limit: number
  offset: number
  filters: GroupFilterFormFileds
  sort: string
}

const useGetGroups = ({ limit, offset, filters, sort }: Args): UseGetGroups => {
  const queryKey = [GET_GROUPS, limit, offset, filters, sort]
  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getGroups({ limit, offset, filters, sort }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Array<Group> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetGroups
