import { useEffect } from 'react'

import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import SpinnerLoading from 'components/atoms/spinner-loading'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import routes from 'config/routes'
import { EventFormFields, EventsFormSchema, eventsSchema } from 'validations/events'
import { Event } from 'models/events'
import ControllerCustomInputKeys from 'components/molecules/controller-custominput-keys'
import { eventsActionFormatter, eventsTypeFormatter } from 'utils/eventFormatter'
import { LOCATION_RESOURCETYPE } from 'config/constants'

import { EventGridItem, EventGridContainer } from './styled'

const defaultValues: EventFormFields = {
  username: '',
  email: '',
  action: '',
  resourceType: '',
  resourceId: undefined,
  createDate: '',
  keys: [{ key: '', keyType: '' }],
}

interface Props {
  data: Event
  isLoading: boolean
  id?: string
  disabled?: boolean
}

const EventsEditForm = ({ data, isLoading, disabled }: Props): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<EventsFormSchema>({
    defaultValues,
    resolver: yupResolver(eventsSchema),
  })

  useEffect(() => {
    if (!data) return

    reset({
      username: data.username,
      action: eventsActionFormatter(data.action),
      resourceType: eventsTypeFormatter(data.resourceType),
      createDate: data.createDate,
      email: data.email,
      keys: data.keys,
    })
  }, [data, reset])

  const onSubmit = (): void => {
    navigate(routes.eventsList)
  }

  const keys = watch('keys')

  if (isLoading) {
    return <SpinnerLoading minHeight={600} />
  }

  return (
    <Grid component='form' onSubmit={handleSubmit(onSubmit)} data-testid='events-edit-form'>
      <EventGridContainer container spacing={2}>
        <EventGridItem item md={6} xs={12}>
          <ControllerInputText
            mandatory
            control={control}
            name='username'
            error={errors.username}
            label={t('Nombre')}
            size='full'
            disabled={disabled}
          />
          <ControllerInputText
            mandatory
            control={control}
            name='email'
            error={errors.email}
            label={t('Email')}
            size='full'
            disabled={disabled}
          />
          <ControllerInputText
            mandatory
            control={control}
            name='action'
            error={errors.action}
            label={t('Acción')}
            size='full'
            disabled={disabled}
          />
        </EventGridItem>
        <EventGridItem item md={6} xs={12}>
          <ControllerInputText
            mandatory
            control={control}
            name='resourceType'
            error={errors.resourceType}
            label={t('Tipo')}
            size='full'
            disabled={disabled}
          />
          {!!keys?.length && (
            <ControllerCustomInputKeys
              control={control}
              error={errors}
              disabled={disabled}
              locationKey={data?.resourceType === LOCATION_RESOURCETYPE}
            />
          )}
          <ControllerInputDateTimePicker
            mandatory
            control={control}
            name='createDate'
            error={errors.createDate}
            label={t('Fecha')}
            size='full'
            disabled={disabled}
          />
        </EventGridItem>
      </EventGridContainer>
      <FormButtonsContainer>
        <CancelButton to={routes.eventsList} />
      </FormButtonsContainer>
    </Grid>
  )
}

export default EventsEditForm
