import { GetEvents } from 'models/events'
import axios from 'services'
import { removeEmpty } from 'utils/removeEmpty'

export const getEvents = ({ filters, ...payload }: GetEvents) => {
  return axios.get('/v1/events', { params: removeEmpty({ ...payload, ...filters }) })
}

export const getOneEvent = (id?: string) => {
  return axios.get(`/v1/events/${id}`)
}
