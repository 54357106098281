import React, { useCallback, useMemo, useState } from 'react'

import { Control, FieldErrors, useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Location } from 'models/locations'
import { GroupFormFields } from 'validations/groups'
import LocationSearcher from 'components/molecules/locations-searcher'
import LocationChip from 'components/atoms/location-chip'
import ModalConfirm from 'components/molecules/modal-confirm'

import { LocationsInputContainer, LocationsMainContainer } from './styled'
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  error?: FieldErrors<GroupFormFields>
  disabled?: boolean
}
const ControllerCustomInputLocations: React.FC<Props> = ({ control, disabled, error }) => {
  const inputName = 'locations'
  const { fields, append, remove } = useFieldArray({ control, name: inputName })

  const { t } = useTranslation()

  const fieldsWatch = useWatch({
    control,
    name: inputName,
  })

  const [removeIndex, setRemoveIndex] = useState<number>(-1)
  const removeIndexMessage = useMemo(() => {
    const location = fieldsWatch?.[removeIndex] as unknown as Location
    const locationKey = location?.key || ''
    const locationName = location?.name || ''

    return t('¿Eliminar localización {{locationKey}} - {{locationName}}?', {
      locationKey,
      locationName,
      interpolation: { escapeValue: false },
    })
  }, [fieldsWatch, removeIndex, t])

  const handleLocationOnChange = (location: Location | null) => {
    if (!location) return

    const selectLocations: Location[] = control._formValues?.locations
    const result = selectLocations.some(selectLocation => selectLocation.id == location.id)

    if (result) {
      toast(t('Localización ya añadida'), { type: 'warning' })
      return
    }
    append({
      id: location?.id,
      keyType: location?.type,
      key: location?.key,
      name: location?.name,
      parent: location?.parent,
      coordinate: { longitude: location?.coordinate?.longitude, latitude: location?.coordinate?.latitude },
    })
  }

  const onRemoveConfirm = useCallback(() => {
    remove(removeIndex)
    setRemoveIndex(-1)
  }, [remove, removeIndex])

  const onRemove = useCallback(
    (index: number) => {
      const location = fieldsWatch[index] as unknown as Location

      if (location?.name !== '') {
        setRemoveIndex(index)
      } else {
        remove(index)
      }
    },
    [fieldsWatch, remove]
  )

  return (
    <LocationsMainContainer>
      {!disabled && <LocationSearcher onChange={handleLocationOnChange} error={error} name={inputName} />}
      <LocationsInputContainer>
        {fields.map((field, index) => {
          const locationsData: Location = control._formValues?.locations[index]
          return (
            <LocationChip
              location={locationsData}
              key={`location-chip-${field.id}`}
              onDelete={() => onRemove(index)}
              disabled={disabled}
            />
          )
        })}
      </LocationsInputContainer>
      <ModalConfirm
        open={removeIndex > -1}
        onCancel={() => setRemoveIndex(-1)}
        onConfirm={onRemoveConfirm}
        title={t('Eliminar')}
        message={removeIndexMessage}
        confirmLabel={t('confirmar')}
        cancelLabel={t('cancelar')}
      />
    </LocationsMainContainer>
  )
}

export default ControllerCustomInputLocations
