/* eslint-disable camelcase */
import axios from 'services'
import { ResetPassword, User } from 'models/auth'
import routes from 'config/routes'

import { COGNITO_REDIRECT_URL, COGNITO_URL, COGNITO_CLIENT_ID } from '../config/constants'

import storage from './storage'

export const cognitoUrl =
  `${COGNITO_URL}/login?response_type=code&client_id=${COGNITO_CLIENT_ID}` +
  `&redirect_uri=${COGNITO_REDIRECT_URL}&scope=openid+profile+aws.cognito.signin.user.admin`

export const signIn = (body: User) => {
  return axios.post('/v1/auth/login', body)
}

export const getCognitoToken = (code: string) => {
  return fetch(`${COGNITO_URL}/oauth2/token`, {
    method: 'POST',
    headers: new Headers({ 'content-type': 'application/x-www-form-urlencoded' }),
    body: Object.entries({
      grant_type: 'authorization_code',
      client_id: COGNITO_CLIENT_ID,
      redirect_uri: COGNITO_REDIRECT_URL,
      code,
    })
      .map(([k, v]) => `${k}=${v}`)
      .join('&'),
  })
}

export const refreshAccessToken = (refreshToken: string) => {
  return fetch(`${COGNITO_URL}/oauth2/token`, {
    method: 'POST',
    headers: new Headers({ 'content-type': 'application/x-www-form-urlencoded' }),
    body: Object.entries({
      grant_type: 'refresh_token',
      client_id: COGNITO_CLIENT_ID,
      redirect_uri: COGNITO_REDIRECT_URL,
      refresh_token: refreshToken,
    })
      .map(([k, v]) => `${k}=${v}`)
      .join('&'),
  })
}

export const resetPasswordRequest = (email: string) => {
  return axios.post('/v1/password-reset/request', { email })
}

export const resetPassword = (body: ResetPassword) => {
  return axios.post('/v1/password-reset/reset', body)
}

export const setAccessTokenHeader = (accessToken: string | null): void => {
  if (accessToken) {
    axios.defaults.headers.common['X-CLIENT-TOKEN'] = accessToken
  } else {
    delete axios.defaults.headers.common['X-CLIENT-TOKEN']
  }
}

export const getSessionUser = () => {
  try {
    const sessionUser = JSON.parse(storage.getItem('user') ?? '')
    return sessionUser
  } catch (error) {
    return null
  }
}

export const setSessionUser = (user: string) => storage.setItem('user', user)

export const setSessionToken = (token: string) => storage.setItem('token', token)

const getSessionToken = () => storage.getItem('token') || ''

export const setSessionRefreshToken = (token: string) => storage.setItem('refreshToken', token)

const getSessionRefreshToken = () => storage.getItem('refreshToken') || ''

const logout = async () => {
  storage.clear()
  window.location.replace(routes.login)
}

axios.interceptors.request.use(
  config => {
    config.headers['X-CLIENT-TOKEN'] = getSessionToken()
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => response,
  async function (error) {
    const originalRequest = error.config

    if (error?.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true

      const refreshToken = getSessionRefreshToken()

      if (!refreshToken) {
        return logout()
      }

      let res = null
      try {
        res = await refreshAccessToken(refreshToken)
      } catch {
        return Promise.reject(error)
      }

      if (!res?.ok) {
        return logout()
      }

      const newToken = await res.json()

      setAccessTokenHeader(newToken.id_token)
      originalRequest.headers['X-CLIENT-TOKEN'] = newToken.id_token

      setSessionToken(newToken.id_token)
      setSessionRefreshToken(newToken.refreshToken)

      return axios(originalRequest)
    }
    return Promise.reject(error)
  }
)

export const getMe = () => {
  return axios.get('/v1/me')
}
