import { useQuery } from 'react-query'

import { getIdentitiesVersions } from 'services/identities'
import { Identity, UseGetIdentitiesVersions } from 'models/identities'
import { GET_IDENTITIES_VERSIONS } from 'config/queries'

interface Args {
  id?: string
  limit: number
  offset: number
  sort: string
}

const useGetIdentitiesVersions = ({ id, limit, offset, sort }: Args): UseGetIdentitiesVersions => {
  const queryKey = [GET_IDENTITIES_VERSIONS, id, limit, offset, sort]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getIdentitiesVersions({ id, limit, offset, sort }),
    refetchOnMount: true,
  })

  const result: Array<Identity> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading }
}

export default useGetIdentitiesVersions
