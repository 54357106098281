import React, { useEffect, useState } from 'react'

import { t } from 'i18next'

import BasicButton from 'components/atoms/basic-button'
import useGetApplicationToken from 'hooks/queries/application/useGetApplicationToken'
import ModalConfirm from 'components/molecules/modal-confirm'
import { Application } from 'models/applications'

interface Props {
  appData: Application
  onChangeToken: (token: string) => void
  edit?: boolean
}

const GenerateTokenButton: React.FC<Props> = ({ appData, onChangeToken, edit }) => {
  const [open, setOpen] = useState<boolean>(false)
  const { response, isFetching, refetch, remove } = useGetApplicationToken(appData)

  useEffect(() => {
    if (!response) return
    onChangeToken(`${response.uniqueTokenId}:${response.token}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  useEffect(() => {
    return () => {
      remove?.()
    }
  }, [remove])

  const handleGenerateToken = () => {
    refetch()
    setOpen(false)
  }
  const handleButtonClick = () => {
    if (edit) return setOpen(true)
    handleGenerateToken()
  }

  return (
    <>
      <BasicButton text={t('Generar')} type='button' handleClick={handleButtonClick} loading={isFetching} />
      <ModalConfirm
        open={!!edit && open}
        onCancel={() => setOpen(false)}
        onConfirm={handleGenerateToken}
        title={t('Regenerar Token')}
        message={t(
          'Va a generar un nuevo token, invalidando el anterior. No se volverá a mostrar, asegurese de copiarlo'
        )}
        confirmLabel={t('confirmar')}
        cancelLabel={t('cancelar')}
      />
    </>
  )
}

export default GenerateTokenButton
