import React from 'react'

import { Chip, Typography } from '@mui/material'

import { Keys } from 'models/keys'
import { keysFormatter } from 'utils/keysFormatter'
import { FontFamily, FontSizes } from 'config/constants'

import { KeysChipContainer } from './styled'

interface Props {
  keys: Keys
}

const KeysChip: React.FC<Props> = ({ keys }) => {
  return (
    <Chip
      variant='outlined'
      label={
        <KeysChipContainer>
          <Typography fontWeight='700' fontSize={FontSizes.base}>
            {keysFormatter(keys.keyType)}
          </Typography>
          <Typography noWrap fontFamily={FontFamily.light} fontSize={FontSizes.base}>
            {keys.key}
          </Typography>
        </KeysChipContainer>
      }
    />
  )
}

export default KeysChip
