import React, { ReactNode } from 'react'

import { Control, Controller, FieldError } from 'react-hook-form'

import BasicInputSelect from 'components/atoms/basic-input-select'
import { Sizes } from 'models/sizes'

interface Option {
  value: string | number
  text: string
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  error?: FieldError
  disabled?: boolean
  name: string
  label: string
  size?: Sizes
  mandatory?: boolean
  multiple?: boolean
  renderValue?: (selection: string | string[]) => ReactNode
  options: Array<Option>
}

const ControllerInputSelect: React.FC<Props> = ({
  control,
  disabled,
  name,
  label,
  options,
  error,
  multiple,
  renderValue,
  mandatory = false,
  size = 'medium',
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <BasicInputSelect
          mandatory={mandatory}
          field={field}
          label={label}
          options={options}
          size={size}
          error={!!error}
          helperText={error && (error.message as string)}
          disabled={disabled}
          multiple={multiple}
          renderValue={renderValue}
        />
      )}
    />
  )
}

export default ControllerInputSelect
