import { useParams } from 'react-router-dom'

import PageContainer from 'components/atoms/page-container'
import EventsEditForm from 'components/organisms/events/events-edit-form'
import useGetOneEvent from 'hooks/queries/event/useGetOneEvent'

const EventsShow = (): JSX.Element => {
  const { id } = useParams()
  const { isLoading, response } = useGetOneEvent({ id })
  return (
    <PageContainer>
      <EventsEditForm isLoading={isLoading} data={response} id={id} disabled />
    </PageContainer>
  )
}

export default EventsShow
