import React from 'react'

import { Box, Typography } from '@mui/material'
import { t } from 'i18next'

import { Coordinate } from 'models/locations'
import { FontSizes } from 'config/constants'

interface Props {
  coordinate: Coordinate
}

const CoordinateCell: React.FC<Props> = ({ coordinate }) => {
  return (
    <Box width='100%' display='flex' flexDirection='column'>
      <Typography noWrap fontSize={FontSizes.base}>
        <strong>{t('Latitud')}:</strong> {coordinate?.latitude}
      </Typography>
      <Typography noWrap fontSize={FontSizes.base}>
        <strong>{t('Longitud')}:</strong> {coordinate?.longitude}
      </Typography>
    </Box>
  )
}

export default CoordinateCell
