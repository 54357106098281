import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import SubmitButton from 'components/atoms/submit-button'
import routes from 'config/routes'
import { LocationFormData, LocationFormFields, locationSchema } from 'validations/locations'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerCoordinates from 'components/molecules/controller-coordinates'
import GridItem from 'components/atoms/grid-item'
import { CreateLocation } from 'models/locations'
import { createLocation } from 'services/locations'
import { GET_LOCATIONS } from 'config/queries'
import { LOCATION_TYPES } from 'config/constants'
import useFormErrorScroll from 'hooks/useFormErrorScroll'
import ControllerSelectLocation from 'components/molecules/controller-select-location'

import { GridContainer } from './styled'

const defaultValues: LocationFormFields = {
  type: '',
  key: '',
  name: '',
  parent: '',
  coordinate: {
    latitude: '',
    longitude: '',
  },
}

const LocationsCreateForm = (): JSX.Element => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LocationFormData>({
    defaultValues,
    resolver: yupResolver(locationSchema),
  })

  useFormErrorScroll(errors)

  const updateLocation = useMutation({
    mutationFn: (payload: CreateLocation) => createLocation(payload),
    onSuccess: (): void => {
      queryClient.invalidateQueries(GET_LOCATIONS)
      toast(t('Localización creada'), { type: 'success' })
    },
    onError: () => {
      toast(t('Ha ocurrido un error'), { type: 'error' })
      queryClient.invalidateQueries({ queryKey: [GET_LOCATIONS] })
    },
  })

  const onSubmit = ({ type, key, name, parent, coordinate }: LocationFormData): void => {
    const transformParent = parent ? parseInt(parent, 10) : null

    updateLocation.mutate({ type, key, name, parent: transformParent, coordinate })
    navigate(routes.locationsList)
  }

  return (
    <Grid component='form' onSubmit={handleSubmit(onSubmit)} data-testid='locations-create-form'>
      <GridContainer container>
        <GridItem item md={6} xs={12}>
          <ControllerInputSelect
            mandatory
            control={control}
            name='type'
            error={errors.type}
            label={t('Tipo')}
            size='large'
            options={LOCATION_TYPES}
          />
          <ControllerInputText
            mandatory
            control={control}
            name='key'
            error={errors.key}
            label={t('Clave')}
            size='large'
          />
          <ControllerInputText
            mandatory
            control={control}
            name='name'
            error={errors.name}
            label={t('Nombre')}
            size='large'
          />
        </GridItem>
        <GridItem item md={6} xs={12}>
          <ControllerCoordinates
            control={control}
            errorLongitude={errors.coordinate?.longitude}
            errorLatitude={errors.coordinate?.latitude}
            size='large'
          />
          <ControllerSelectLocation
            control={control}
            name='parent'
            error={errors.parent}
            label={t('Padre / Dependencia')}
            size='large'
          />
        </GridItem>
      </GridContainer>
      <FormButtonsContainer>
        <CancelButton to={routes.locationsList} />
        <SubmitButton />
      </FormButtonsContainer>
    </Grid>
  )
}

export default LocationsCreateForm
