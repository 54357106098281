import { CreateApplication, GetApplications, PutApplications } from 'models/applications'
import axios from 'services'
import { removeEmpty } from 'utils/removeEmpty'

export const getApplications = ({ filters, ...payload }: GetApplications) => {
  return axios.get('/v1/applications', { params: removeEmpty({ ...payload, ...filters }) })
}

export const updateOneApplication = async ({ id, payload }: PutApplications) => {
  return axios.put(`/v1/applications/${id}`, payload)
}

export const createApplication = (payload: CreateApplication) => {
  return axios.post('/v1/applications', payload)
}

export const getOneApplication = (id?: string) => {
  return axios.get(`/v1/applications/${id}`)
}
