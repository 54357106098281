import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

const NoRows = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Box display='flex' justifyContent='center' alignItems='center' margin='3em'>
      <Typography>{t('Sin datos')}</Typography>
    </Box>
  )
}

export default NoRows
