import { FC, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'

import BasicButton from 'components/atoms/basic-button'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerSwitch from 'components/molecules/controller-switch'
import { DateFormats, KEY_TYPES } from 'config/constants'
import useGetDomains from 'hooks/queries/domain/useGetDomains'
import { Domains } from 'models/domains'
import {
  relationsFilterDefaultValues,
  RelationsFilterFormFields,
  RelationFilterFormSchema,
  relationFilterSchema,
} from 'validations/relations'
import { metadataFormatter } from 'utils/metadataFormatter'

import { FilterGridContainer } from './styled'

interface RelationsFilterProps {
  onSearch: (search: RelationsFilterFormFields) => void
}

const RelationsFilter: FC<RelationsFilterProps> = ({ onSearch }) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm<RelationFilterFormSchema>({
    defaultValues: relationsFilterDefaultValues,
    resolver: yupResolver(relationFilterSchema),
  })

  const { response: domainsResponse } = useGetDomains()

  const domains = useMemo(
    () => domainsResponse?.map?.(({ name: domain }: Domains) => ({ value: domain, text: t(domain) })),
    [domainsResponse, t]
  )

  const handleReset = () => {
    reset(relationsFilterDefaultValues)
    onSearch(relationsFilterDefaultValues)
  }

  const onSubmit = (payload: RelationsFilterFormFields) => {
    try {
      const metadata = metadataFormatter(payload.metadata)
      onSearch({ ...payload, metadata })
    } catch (error) {
      setError('metadata', { type: 'pattern', message: 'Formato incorrecto' })
    }
  }

  return (
    <FilterGridContainer container pr={1}>
      <Grid item md={3} pr={1}>
        <ControllerInputSelect
          options={KEY_TYPES}
          control={control}
          name='keyType'
          error={errors.keyType}
          label={t('Clave')}
          size='full'
        />
      </Grid>
      <Grid item md={3} pr={1}>
        <ControllerInputText control={control} name='keyValue' error={errors.keyValue} label={t('Valor')} size='full' />
      </Grid>
      <Grid item md={3} pr={1}>
        <ControllerInputText control={control} name='name' error={errors.name} label={t('Nombre')} size='full' />
      </Grid>
      <Grid item md={3} pr={1}>
        <ControllerInputSelect
          options={domains}
          control={control}
          name='domain'
          error={errors.domain}
          label={t('Dominio')}
          size='full'
        />
      </Grid>
      <Grid item md={6} pr={1}>
        <ControllerInputText
          control={control}
          name='metadata'
          error={errors.metadata}
          label={t('Metadatos (key:value,key:value)')}
          size='full'
        />
      </Grid>
      <Grid item md={3} pr={1}>
        <ControllerInputDateTimePicker
          control={control}
          name='from'
          error={errors.from}
          label={t('Vigencia desde')}
          size='full'
          pattern={DateFormats.iso8601}
        />
      </Grid>
      <Grid item md={3} pr={1}>
        <ControllerInputDateTimePicker
          control={control}
          name='to'
          error={errors.to}
          label={t('Vigencia hasta')}
          size='full'
          pattern={DateFormats.iso8601}
        />
      </Grid>

      <Grid item md={12} display='flex' justifyContent='flex-end' flexDirection='row' gap={3}>
        <ControllerSwitch label={t('Solo activos')} name='showActiveOnly' variant='full' control={control} autoHeight />
        <BasicButton handleClick={handleReset} text={t('Restablecer')} size='small' color='warning' />
        <BasicButton handleClick={handleSubmit(onSubmit)} text={t('Buscar')} size='small' />
      </Grid>
    </FilterGridContainer>
  )
}

export default RelationsFilter
