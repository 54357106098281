import { useQuery } from 'react-query'

import { getOneRelation } from 'services/relations'
import { Relation, UseGetOneRelation } from 'models/relations'
import { GET_ONE_RELATION } from 'config/queries'

interface Args {
  id?: string
}

const useGetOneRelation = ({ id }: Args): UseGetOneRelation => {
  const queryKey = [GET_ONE_RELATION, id]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneRelation(id),
    refetchOnWindowFocus: false,
  })

  const result: Relation = response?.data

  return { response: result, isLoading }
}

export default useGetOneRelation
