import dayjs from 'dayjs'
import * as yup from 'yup'

import { DateFormats } from 'config/constants'
import i18n from 'config/i18n'
import { Metadata } from 'models/metadata'
import { PeriodDate, PeriodTime } from 'models/groups'
import { Coordinate, Location } from 'models/locations'
import { Keys } from 'models/keys'
import { ALPHANUMERIC_REGEX, METADATA_REGEX } from 'config/regex'
import { dateFormatter } from 'utils/dateFormatter'

import {
  currentDateIsMinor,
  handleKeysValidation,
  isMinorHour,
  isMinorPeriodDate,
  isSimilarHour,
  locationChecker,
} from './utils'

export const groupSchema = yup
  .object({
    version: yup.number(),
    keys: yup.array().of(
      yup.object({
        keyType: yup.string().required(),
        key: yup.string().when('keyType', handleKeysValidation),
      })
    ),
    name: yup.string().required().max(255).matches(ALPHANUMERIC_REGEX),
    domain: yup.string().required(),
    active: yup.boolean().required(),
    metadata: yup.array().of(
      yup.object({
        key: yup.string().required().max(255).matches(ALPHANUMERIC_REGEX),
        value: yup.string().required().max(4000, i18n.t('Ha superado los 4000 caracteres')).matches(METADATA_REGEX),
      })
    ),
    periodDate: yup.object({
      from: yup
        .string()
        .required()
        .nullable()
        .when('$periodDateFromDisabled', (periodDateFromDisabled, schema) =>
          periodDateFromDisabled ? schema : schema.test(currentDateIsMinor)
        ),
      to: yup.string().test(isMinorPeriodDate).nullable(true),
    }),
    periodTime: yup.array().of(
      yup.object({
        from: yup.string().required(),
        to: yup.string().required().test(isSimilarHour).test(isMinorHour),
        dayOfWeek: yup.array().of(yup.string()).min(1),
      })
    ),
    locationRestriction: yup.boolean(),
    locations: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          keyType: yup.string().required(),
          key: yup.string().required(),
          name: yup.string().required(),
          parent: yup.string().required(),
          coordinate: yup.object({ longitude: yup.string().required(), latitude: yup.string().required() }),
        })
      )
      .when('locationRestriction', locationChecker),
    createDate: yup.string(),
    updateDate: yup.string(),
  })
  .required()

export type GroupFormSchema = yup.InferType<typeof groupSchema>

export interface GroupFormFields {
  id?: string
  version?: number
  keys: Keys[]
  name: string
  domain: string
  username: string
  active: boolean
  locationRestriction: boolean
  periodDate: PeriodDate
  periodTime: PeriodTime[]
  locations: LocationsGroups[]
  metadata: Metadata[]
  createDate?: string
  updateDate?: string
}

export interface LocationsGroups {
  id: string
  createDate?: string
  updateDate?: string
  deleteDate?: string
  keyType: string
  name?: string
  parent?: Location
  key: string
  coordinate: Coordinate
}

export const groupFilterSchema = yup.object({
  keyType: yup.string(),
  keyValue: yup.string(),
  name: yup.string(),
  domain: yup.string(),
  metadata: yup.string(),
  from: yup.string().nullable(),
  to: yup.string().nullable(),
  showActiveOnly: yup.boolean().default(true),
})

export type GroupFilterFormSchema = yup.InferType<typeof groupFilterSchema>

export interface GroupFilterFormFileds {
  keyType?: string
  keyValue?: string
  name?: string
  domain?: string
  metadata?: string
  from?: string | null
  to?: string | null
  showInactive?: boolean
  showActiveOnly?: boolean
  ids?: Array<number>
  q?: string
}

export const groupsFormDefaultValues: GroupFilterFormFileds = {
  keyType: '',
  keyValue: '',
  name: '',
  domain: '',
  metadata: '',
  from: null,
  to: null,
  showActiveOnly: true,
}

export const groupActiveIdentitiesFilterSchema = yup.object({
  locationType: yup.string(),
  date: yup.string(),
})

export type GroupActiveIdentitiesFilterFormSchema = yup.InferType<typeof groupActiveIdentitiesFilterSchema>

export interface GroupActiveIdentitiesFilterFormFileds {
  locationType?: string
  date?: string
}

export const groupActiveIdentitiesFilterDefaultValues: GroupActiveIdentitiesFilterFormFileds = {
  locationType: '',
  date: dateFormatter({ date: dayjs(), pattern: DateFormats.iso8601 }),
}
