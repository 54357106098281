import React, { useEffect, useMemo } from 'react'

import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'

import useGetDomains from 'hooks/queries/domain/useGetDomains'
import { Domains } from 'models/domains'
import { GroupFormFields, groupSchema, LocationsGroups } from 'validations/groups'
import routes from 'config/routes'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerCustomInputKeys from 'components/molecules/controller-custominput-keys'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import ControllerCustomInputLocations from 'components/molecules/controller-custominput-locations'
import ControllerCustomInputMetadata from 'components/molecules/controller-custominput-metadata'
import ControllerCustomInputPeriodTime from 'components/molecules/controller-custominput-periodtime'
import SubmitButton from 'components/atoms/submit-button'
import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import PapperGridContainer from 'components/atoms/papper-grid-container-form'
import GridItem from 'components/atoms/grid-item'
import { Group, PeriodTime, UpdateGroups } from 'models/groups'
import { Location } from 'models/locations'
import { DAY_LIST, MIN_DATE_ADD } from 'config/constants'
import { DayList } from 'models/dates'
import useUpdateGroup from 'hooks/queries/group/useUpdateGroup'
import ControllerSwitch from 'components/molecules/controller-switch'
import BasicButton from 'components/atoms/basic-button'
import GroupsActiveIdentities from 'components/organisms/groups/groups-active-identities'
import GroupsRelations from 'components/organisms/groups/groups-relations'
import FormTitle from 'components/atoms/form-title'
import FormSubtitle from 'components/atoms/form-subtitle'
import SpinnerLoading from 'components/atoms/spinner-loading'
import { periodTimeHandler } from 'utils/periodTimeHandler'
import useFormErrorScroll from 'hooks/useFormErrorScroll'
import { metadataDuplicatesDetector } from 'utils/metadataDuplicatesDetector'
import { periodDateHandler } from 'utils/periodDateHandler'
import { isBeforeNow } from 'utils/dateUtils'

import { StyledContainer } from './styled'

interface Props {
  response: Group
  isLoading: boolean
  groupId?: string
  disabled?: boolean
  historic?: boolean
}

const defaultValues: GroupFormFields = {
  domain: '',
  name: '',
  version: 0,
  keys: [{ keyType: '', key: '' }],
  username: '',
  active: true,
  periodDate: { from: '', to: '' },
  periodTime: [],
  locations: [],
  locationRestriction: true,
  metadata: [],
}

const GroupsEditForm: React.FC<Props> = ({ isLoading, response, disabled, historic, groupId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const updateGroup = useUpdateGroup()

  const { response: domainsResponse } = useGetDomains()

  const domains = useMemo(
    () => domainsResponse?.map?.(({ name: domain }: Domains) => ({ value: domain, text: t(domain) })),
    [domainsResponse, t]
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<GroupFormFields>({
    defaultValues,
    resolver: yupResolver(groupSchema),
    context: { periodDateFromDisabled: disabled || !isBeforeNow(response?.periodDate?.from) },
  })

  useFormErrorScroll(errors)

  const [isLocationsActive, periodDateFrom] = watch(['locationRestriction', 'periodDate.from'])

  useEffect(() => {
    if (!response) return

    reset({
      domain: response.domain?.name || '',
      version: response.version,
      createDate: response.createDate,
      updateDate: response.updateDate,
      keys: response.keys ?? [],
      name: response.name || '',
      username: response.username,
      active: response.active,
      periodDate: response.periodDate,
      locationRestriction: response.locationRestriction,
      locations: response?.locations?.map(({ type, id, ...rest }) => ({ ...rest, keyType: type, id: `${id}` })),
      metadata: response.metadata,
      periodTime: response.periodTime.map(({ dayOfWeek, ...rest }: PeriodTime) => ({
        ...rest,
        dayOfWeek: dayOfWeek.length === 0 ? DAY_LIST.map((day: DayList) => day.value) : dayOfWeek,
      })),
    })
  }, [response, reset])

  const navigateToShow = () => groupId && navigate(routes.groupsHistoric.replace(':id', groupId))

  const onSubmit = ({ locations, locationRestriction, periodTime, periodDate, ...group }: GroupFormFields) => {
    const { duplicates, key } = metadataDuplicatesDetector(group.metadata)

    if (duplicates) {
      toast(t(t('Clave de metadato duplicada {{key}}', { key })), { type: 'error' })
    } else {
      const locationsResult = locations.map(
        ({ keyType, id, ...data }: LocationsGroups) => ({ ...data, type: keyType, id: parseInt(id) } as Location)
      )

      const payload: UpdateGroups = {
        ...group,
        id: groupId,
        locationRestriction,
        locations: locationsResult,
        periodTime: periodTimeHandler(periodTime),
        periodDate: periodDateHandler(periodDate),
      }
      updateGroup.mutate(payload)
      navigate(routes.groupsList)
    }
  }

  if (isLoading) {
    return <SpinnerLoading fullHeight />
  }

  return (
    <Grid container component='form' onSubmit={handleSubmit(onSubmit)} data-testid='groups-edit-form'>
      <PapperGridContainer spacing={2}>
        <GridItem item xs={12}>
          <FormTitle text={t('Información')} />
        </GridItem>
        <GridItem item md={6} xs={12}>
          <ControllerInputSelect
            mandatory
            control={control}
            name='domain'
            error={errors.domain}
            label={t('Dominio')}
            size='full'
            options={domains}
            disabled={disabled}
          />
          <ControllerCustomInputKeys control={control} error={errors} disabled={disabled} />
          <ControllerInputText
            mandatory
            control={control}
            name='name'
            error={errors.name}
            label={t('Nombre/Descripción')}
            size='full'
            disabled={disabled}
          />
        </GridItem>

        <GridItem item md={6} xs={12}>
          {!historic && (
            <ControllerSwitch label={t('Activo')} name='active' variant='full' control={control} disabled={disabled} />
          )}
          <StyledContainer direction='row' gap='2rem'>
            <ControllerInputText
              mandatory
              control={control}
              name='version'
              error={errors.version}
              label={t('Versión')}
              size={!historic ? 'medium' : 'full'}
              disabled
            />
            {!historic && (
              <BasicButton type='button' text={t('Histórico')} size='medium' handleClick={navigateToShow} />
            )}
          </StyledContainer>
          <ControllerInputDateTimePicker
            mandatory
            label={t('Fecha de creación')}
            name='createDate'
            control={control}
            error={errors.createDate}
            size='full'
            disabled
          />
          <ControllerInputDateTimePicker
            mandatory
            label={t('Fecha de modificación')}
            name='updateDate'
            control={control}
            error={errors.updateDate}
            size='full'
            disabled
          />
        </GridItem>
        <GridItem item xs={12}>
          <FormTitle text={t('Fechas de vigencia')} />
          <StyledContainer direction='row'>
            <ControllerInputDateTimePicker
              mandatory
              label={t('Desde')}
              name='periodDate.from'
              control={control}
              error={errors.periodDate?.from}
              size='full'
              disabled={disabled || !isBeforeNow(response?.periodDate?.from)}
              minDate={dayjs().add(MIN_DATE_ADD[0], MIN_DATE_ADD[1])}
            />

            <ControllerInputDateTimePicker
              label={t('Hasta')}
              name='periodDate.to'
              control={control}
              error={errors.periodDate?.to}
              size='full'
              disabled={disabled || !isBeforeNow(response?.periodDate?.to)}
              minDate={dayjs(periodDateFrom).add(1, 'minute')}
            />
          </StyledContainer>
        </GridItem>
        <GridItem container item xs={12}>
          <FormTitle text={t('Localizaciones')} />
          <ControllerSwitch
            label={t('Activar localizaciones')}
            name='locationRestriction'
            variant='full'
            control={control}
            disabled={disabled}
            autoHeight
          />
          {isLocationsActive && <ControllerCustomInputLocations control={control} error={errors} disabled={disabled} />}
        </GridItem>
        <GridItem container item xs={12}>
          <FormTitle text={t('Metadatos')} />
          <ControllerCustomInputMetadata control={control} error={errors} disabled={disabled} />
        </GridItem>
        <GridItem container item xs={12}>
          <FormTitle text={t('Periodos de tiempo')} />
          <FormSubtitle text={t('Si no especifica valor alguno aplica las 24h')} />
          <ControllerCustomInputPeriodTime control={control} error={errors} disabled={disabled} />
        </GridItem>
        <>
          {!historic && (
            <>
              <GridItem container item xs={12}>
                <FormTitle text={t('Relaciones del grupo')} />
                <GroupsRelations id={response?.id} />
              </GridItem>
              <GridItem container item xs={12}>
                <FormTitle text={t('Identidades activas')} />
                <GroupsActiveIdentities id={response?.id} />
              </GridItem>
            </>
          )}
        </>
      </PapperGridContainer>
      <FormButtonsContainer>
        <CancelButton />
        {!disabled && <SubmitButton />}
      </FormButtonsContainer>
    </Grid>
  )
}

export default GroupsEditForm
