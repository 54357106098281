import React from 'react'

import { GridEnrichedColDef } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import routes from 'config/routes'
import BasicTable from 'components/molecules/basic-table'
import { Relation } from 'models/relations'
import { RowHeight, TableColumnWidth } from 'config/constants'
import LocationGridActionCellItem from 'components/atoms/location-grid-action-cell-item'
import PeriodDateCell from 'components/atoms/period-date-cell'
import KeysCell from 'components/atoms/keys-cell'
import TextCell from 'components/atoms/text-cell'
import ChipCell from 'components/atoms/chip-cell'

interface Props {
  rows: Relation[]
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageSizeChange: (pageSize: number) => void
  onPageChange: (page: number) => void
}

const GroupRelationsTable: React.FC<Props> = props => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToShow = (id: string) => navigate(routes.relationsShow.replace(':id', id))

  const columns: GridEnrichedColDef[] = [
    { field: 'id', headerName: '', flex: TableColumnWidth.xs, headerAlign: 'center', align: 'center' },
    {
      field: 'keys',
      headerName: `${t('Claves')}`,
      flex: TableColumnWidth.large,
      sortable: false,
      renderCell: ({ row }: { row: Relation }) => <KeysCell keys={row.keys} />,
    },
    {
      field: 'name',
      headerName: `${t('Nombre')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Relation }) => <TextCell text={row.name} />,
    },
    {
      field: 'domain',
      headerName: `${t('Dominio')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Relation }) => <ChipCell label={row?.domain} />,
    },
    {
      field: 'date',
      headerName: `${t('Validez')}`,
      flex: TableColumnWidth.base,
      sortable: false,
      renderCell: ({ row }: { row: Relation }) => <PeriodDateCell periodDate={row.periodDate} />,
    },
    {
      field: 'locations',
      type: 'actions',
      headerName: '',
      flex: TableColumnWidth.small,
      sortable: false,
      getActions: ({ row }: { row: Relation }) => [
        <LocationGridActionCellItem key={`${row.id}-locations`} locations={row.locations} />,
      ],
    },
  ]

  return (
    <BasicTable
      {...props}
      rowHeight={RowHeight.large}
      columns={columns}
      onRowClick={({ row }) => navigateToShow(row.id)}
    />
  )
}

export default GroupRelationsTable
