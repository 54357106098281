import { useQuery } from 'react-query'

import { getOneGroupRelations } from 'services/groups'
import { UseGetOneGroupRelations } from 'models/groups'
import { Relation } from 'models/relations'
import { GET_ONE_GROUP_RELATIONS } from 'config/queries'

interface Args {
  id: string
  limit: number
  offset: number
  sort: string
}

const useGetOneGroupRelations = ({ id, limit, offset, sort }: Args): UseGetOneGroupRelations => {
  const queryKey = [GET_ONE_GROUP_RELATIONS, id, limit, offset, sort]
  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getOneGroupRelations({ id, limit, offset, sort }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Array<Relation> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData }
}

export default useGetOneGroupRelations
