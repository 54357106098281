import Box from '@mui/material/Box/Box'
import Container from '@mui/material/Container/Container'
import { styled } from '@mui/material/styles'

export const MainContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
` as typeof Container

export const MainBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
` as typeof Box

export const DescriptionText = styled(Box)`
  text-align: center;
` as typeof Box

export const LoginLoadingBox = styled(Box)`
  margin-top: 16px;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
` as typeof Box
