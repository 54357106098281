import styled from 'styled-components'

interface Props {
  direction?: 'row' | 'column'
  gap?: string
}

export const StyledContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: ${(props: Props) => props?.direction || 'row'};
  gap: ${(props: Props) => props?.gap || '0.5rem'};
  justify-content: center;
  align-items: flex-start;
`
