import React from 'react'

import { Box, Typography } from '@mui/material'
import { t } from 'i18next'

import { PeriodDate } from 'models/groups'
import { dateFormatter } from 'utils/dateFormatter'
import { DateFormats, FontSizes, INVALID_DATE } from 'config/constants'

interface Props {
  periodDate: PeriodDate
}

const PeriodDateCell: React.FC<Props> = ({ periodDate }) => {
  return (
    <Box width='100%' display='flex' flexDirection='column'>
      <Typography noWrap fontSize={FontSizes.base}>
        <strong>{t('Desde')}:</strong> {dateFormatter({ date: periodDate?.from, pattern: DateFormats.day })}
      </Typography>
      {periodDate.to && periodDate.to !== INVALID_DATE && (
        <Typography noWrap fontSize={FontSizes.base}>
          <strong>{t('Hasta')}:</strong> {dateFormatter({ date: periodDate?.to, pattern: DateFormats.day })}
        </Typography>
      )}
    </Box>
  )
}

export default PeriodDateCell
