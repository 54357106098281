import { QueryClient, QueryKey, useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Location, UpdateLocation } from 'models/locations'
import { updateOneLocation } from 'services/locations'
import { GET_LOCATIONS } from 'config/queries'

const useUpdateLocation = () => {
  const queryKey: QueryKey = [GET_LOCATIONS]
  const queryClient: QueryClient = useQueryClient()
  const { t } = useTranslation()
  const updateLocation = useMutation({
    mutationFn: ({ id, ...payload }: UpdateLocation) => updateOneLocation({ id, payload }),
    onSuccess: () => {
      toast(t('Localización actualizada'), { type: 'success' })
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey })
      const previous = queryClient.getQueryData<{ data: Array<Location> }>(queryKey)
      return { previous }
    },
    onError: (error, newValue, context) => {
      queryClient.setQueryData([GET_LOCATIONS], context?.previous)
      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey })
    },
  })

  return {
    updateLocation,
  }
}

export default useUpdateLocation
