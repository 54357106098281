export interface User {
  email: string
  password: string
}

export interface LogIn {
  user: {
    id: string
    name: string
    email: string
  }
  token: {
    expiresIn: number
    accessToken: string
    refreshToken: string
  }
}

export interface LoginUser {
  id: string
  name: string
  email: string
  role: Roles
}

export interface RefreshToken {
  userId: string | null
  refresh: string | null
}

export interface ResetPassword {
  resetCode: string
  password: string
}

export interface CognitoToken {
  access_token: string
  expires_in: number
  id_token: string
  refresh_token: string
}

export interface CognitoDecodedToken {
  aud: string
  'cognito:username': string
  email: string
}

export interface Me {
  id: number
  name: string
  email: string
  role: Roles
}

export interface GetMe {
  data: Me
}

export enum Roles {
  'SUPER-ADMIN' = 'SUPER-ADMIN',
  ADMIN = 'ADMIN',
  'ADMIN-LOCATION' = 'ADMIN-LOCATION',
  OPERATOR = 'OPERATOR',
  SEGMENTATION = 'SEGMENTATION',
  READER = 'READER',
}
