import styled from 'styled-components'

import ResourceHeader from 'components/atoms/resource-header'

export const SResourceHeader = styled(ResourceHeader)`
  position: relative;
`

export const FiltersOverlay = styled.div`
  position: absolute;
  z-index: 9;
  height: calc(100% + 20px);
  width: calc(100% - 140px);
  left: 140px;
  padding: 12px;
  background-color: rgba(60, 60, 60, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`
