import * as yup from 'yup'

import { Coordinate } from 'models/locations'

import { isCoordinateNumber } from './utils'

export const locationSchema = yup
  .object({
    type: yup.string().required(),
    key: yup.string().required(),
    name: yup.string().required(),
    parent: yup.string().nullable().notRequired(),
    coordinate: yup.object({
      latitude: yup.string().test(isCoordinateNumber),
      longitude: yup.string().test(isCoordinateNumber),
    }),
  })
  .required()

export type LocationFormData = yup.InferType<typeof locationSchema>

export interface LocationFormFields {
  type: string
  key: string
  name: string
  parent?: string | null
  coordinate: Coordinate
}

export const locationFilterSchema = yup.object({
  keyType: yup.string(),
  keys: yup.string(),
  name: yup.string(),
})

export type LocationFilterFormData = yup.InferType<typeof locationFilterSchema>

export interface LocationsFilterFormFields {
  keyType?: string
  keys?: string
  name?: string
  q?: string
}
