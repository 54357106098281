export const metadataFormatter = (metadataString?: string): string | undefined => {
  if (!metadataString) return
  const splittedMetadata = metadataString.split(',')

  const trimmedMetadata = splittedMetadata?.map(metadata => {
    const metadataKeyValueString = metadata?.split(':')
    if (metadataKeyValueString?.length !== 2) {
      throw new Error()
    }
    return metadataKeyValueString.map(split => split.trim()).join(':')
  })

  return trimmedMetadata.join(',')
}
