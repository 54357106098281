import TransportationIcon from '@mui/icons-material/EmojiTransportation'
import SettingsIcon from '@mui/icons-material/Settings'
import GroupIcon from '@mui/icons-material/Group'
import NetworkCellIcon from '@mui/icons-material/NetworkCell'
import { useTranslation } from 'react-i18next'

import routes from 'config/routes'
import { colors } from 'config/theme'
import { ReactComponent as HeaderEnd } from 'assets/images/svg/header-end.svg'
import { ReactComponent as HeaderStart } from 'assets/images/svg/header-start.svg'
import HeaderLink from 'components/atoms/header-link'
import useIdentitiesPermissions from 'hooks/permissions/useIdentitiesPermissions'
import useGroupsPermissions from 'hooks/permissions/useGroupsPermissions'
import useRelationsPermissions from 'hooks/permissions/useRelationsPermissions'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/useAdministrationPermissions'

import * as S from './styled'

const AppBarNavigation = (): JSX.Element => {
  const { t } = useTranslation()

  const { identitiesList, groupsList, relationsList, administration } = routes

  const { canRead: identitiesCanRead } = useIdentitiesPermissions()
  const { canRead: groupsCanRead } = useGroupsPermissions()
  const { canRead: relationsCanRead } = useRelationsPermissions()

  const { canRead: usersCanRead } = useAdministrationPermissions(AdministrationSections.users)
  const { canRead: applicationsCanRead } = useAdministrationPermissions(AdministrationSections.applications)
  const { canRead: eventsCanRead } = useAdministrationPermissions(AdministrationSections.events)
  const { canRead: locationsCanRead } = useAdministrationPermissions(AdministrationSections.locations)
  const administrationCanRead = usersCanRead || applicationsCanRead || eventsCanRead || locationsCanRead

  return (
    <S.AppBarNavigationContainer>
      <HeaderStart fill={colors.whiteShadow} />
      <S.AppBarNavigationLinks>
        {identitiesCanRead && (
          <HeaderLink title={t('identidades')} href={identitiesList} icon={<TransportationIcon color='primary' />} />
        )}
        {groupsCanRead && <HeaderLink title={t('grupos')} href={groupsList} icon={<GroupIcon color='primary' />} />}
        {relationsCanRead && (
          <HeaderLink title={t('relaciones')} href={relationsList} icon={<NetworkCellIcon color='primary' />} />
        )}
        {administrationCanRead && (
          <HeaderLink title={t('administración')} href={administration} icon={<SettingsIcon color='primary' />} />
        )}
      </S.AppBarNavigationLinks>
      <HeaderEnd fill={colors.whiteShadow} />
    </S.AppBarNavigationContainer>
  )
}

export default AppBarNavigation
