import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import Grid from '@mui/material/Grid'

import { LocationFormData, LocationFormFields, locationSchema } from 'validations/locations'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerCoordinates from 'components/molecules/controller-coordinates'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import routes from 'config/routes'
import CancelButton from 'components/atoms/cancel-button'
import SubmitButton from 'components/atoms/submit-button'
import { LOCATION_TYPES } from 'config/constants'
import useGetOneLocation from 'hooks/queries/locations/useGetOneLocation'
import useUpdateLocation from 'hooks/queries/locations/useUpdateLocation'
import SpinnerLoading from 'components/atoms/spinner-loading'
import ControllerSelectLocation from 'components/molecules/controller-select-location'
import useFormErrorScroll from 'hooks/useFormErrorScroll'

import { GridContainer, GridItem } from './styled'

const defaultValues: LocationFormFields = {
  type: '',
  key: '',
  name: '',
  parent: '',
  coordinate: {
    latitude: '',
    longitude: '',
  },
}

interface Props {
  editDisabled?: boolean
}

const LocationsEditForm: React.FC<Props> = ({ editDisabled = false }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<LocationFormData>({
    defaultValues,
    resolver: yupResolver(locationSchema),
  })

  useFormErrorScroll(errors)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { isLoading, response } = useGetOneLocation({ id: id! })

  const { updateLocation } = useUpdateLocation()

  const isLocationDisabled = !response?.editable || editDisabled

  useEffect(() => {
    if (!response) return
    reset({
      name: response.name,
      key: response.key,
      parent: response.parent?.id?.toString() ?? '',
      type: response.type,
      coordinate: {
        latitude: response?.coordinate?.latitude,
        longitude: response?.coordinate?.longitude,
      },
    })
  }, [response, reset])

  const onSubmit = ({ type, key, name, parent, coordinate }: LocationFormData): void => {
    const transformParent = parent ? parseInt(parent, 10) : null

    updateLocation.mutate({
      id,
      type,
      key,
      name,
      parent: transformParent,
      coordinate,
    })
    navigate(routes.locationsList)
  }

  return isLoading ? (
    <SpinnerLoading minHeight={440} />
  ) : (
    <Grid component='form' onSubmit={handleSubmit(onSubmit)} data-testid='locations-edit-form'>
      <GridContainer container>
        <GridItem item md={6} xs={12}>
          <ControllerInputSelect
            mandatory
            control={control}
            name='type'
            error={errors.type}
            label={t('Tipo')}
            size='large'
            options={LOCATION_TYPES}
            disabled={isLocationDisabled}
          />
          <ControllerInputText
            mandatory
            control={control}
            name='key'
            error={errors.key}
            label={t('Clave')}
            size='large'
            disabled={isLocationDisabled}
          />
          <ControllerInputText
            mandatory
            control={control}
            name='name'
            error={errors.name}
            label={t('Nombre')}
            size='large'
            disabled={isLocationDisabled}
          />
        </GridItem>
        <GridItem item md={6} xs={12}>
          <ControllerCoordinates
            control={control}
            errorLongitude={errors.coordinate?.longitude}
            errorLatitude={errors.coordinate?.latitude}
            size='large'
            disabled={isLocationDisabled}
          />
          <ControllerSelectLocation
            control={control}
            name='parent'
            error={errors.parent}
            label={t('Padre / Dependencia')}
            size='large'
            defaultValue={response?.parent}
            disabled={isLocationDisabled}
          />
        </GridItem>
      </GridContainer>
      <FormButtonsContainer>
        <CancelButton to={routes.locationsList} />
        {!isLocationDisabled && <SubmitButton />}
      </FormButtonsContainer>
    </Grid>
  )
}

export default LocationsEditForm
