import React from 'react'

import { Box, Button } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useTranslation } from 'react-i18next'

import { buttonsWidth } from 'config/constants'

interface Props {
  onClick: () => void
}
const CsvButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <Box display='flex' justifyContent='flex-end' marginBottom='1em'>
      <Button onClick={onClick} variant='contained' startIcon={<CloudUploadIcon />} sx={{ width: buttonsWidth.large }}>
        {t`Subir fichero CSV`}
      </Button>
    </Box>
  )
}

export default CsvButton
